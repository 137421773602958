<template>
    <div>
        <b-modal v-if="data!== null" v-model="show" :title="'Prenotazione - ' + data.reservation.id" size="lg">
            <b-row>
                <b-col lg="3">
                    <b-form-group label="Da">
                        <b-form-input type="time" v-model="data.reservation.fromtime"/>
                    </b-form-group>
                </b-col>
                <b-col lg="3">
                    <b-form-group label="A">
                        <b-form-input type="time" v-model="data.reservation.totime"/>
                    </b-form-group>
                </b-col>
                <b-col lg="6">
                    <b-form-group label="Tipologia">
                        <b-form-select v-model="data.reservation.auditstypes_id" :options="data.auditstypes" value-field="id" text-field="val" @change="suggestionData()"/>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col lg="10">
                    <b-form-group label="Sede">
                        <b-form-select v-model="data.reservation.auditssites_id" :options="data.auditssites.map(function(element) { return { id: element.id, val: element.val + (element.main ? ' Principale' : '') + (element.registered ? ' Legale' : '') + (element.yard ? ' Temporanea' : '')} })" value-field="id" text-field="val"  @change="suggestionData()"/>
                    </b-form-group>
                </b-col>
                <b-col lg="2">
                    <b-form-group label="Tipo">
                        <b-form-select v-model="data.reservation.remote" :options="remoteCombo" value-field="id" text-field="val"/>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-form-group label="Team">
                <b-form-checkbox-group v-model="data.reservation.usersstandardsphasesrolesdates">
                    <div v-for="(item, index) of data.usersstandardsphasesrolesdates" :key="index">
                        <strong>{{ item.role + " " + item.user }}</strong>
                        <div v-for="(item2, index2) of item.phasesstandards" :key="index2">
                            {{ item2.phase }}
                            <b-form-checkbox v-for="(item3, index3) of item2.standards" :key="index3" :value="item3.id"  @change="suggestionData()">{{ item3.standard }}</b-form-checkbox>
                        </div>
                    </div>
                </b-form-checkbox-group>
            </b-form-group>
            <b-form-group label="Punti" v-show="suggestions.length > 0">
                <b-form-checkbox-group v-model="data.reservation.standardspoints">
                    <div v-for="(item, index) of data.points" :key="index">
                        <strong>{{ item.standard }}</strong>
                        <br>
                        <b-form-checkbox v-for="(item2, index2) of item.points.filter( x => suggestions.includes(x.id))" :key="index2" :value="item2.id">{{ item2.val }}</b-form-checkbox>
                    </div>
                </b-form-checkbox-group>
            </b-form-group>
            <template v-slot:modal-footer>
                <div class="w-100">
                    <b-btn variant="success" class="mr-2" @click="saveData">Salva</b-btn>
                    <b-btn variant="danger" class="mr-2" v-show="data.reservation.id > 0" @click="deleteData">Elimina</b-btn>
                    <b-btn variant="info" v-show="data.reservation.usersstandardsphasesrolesdates.length === 0" @click="selectAllAuditors">Seleziona tutto</b-btn>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
export default {
    name: "reservation",
    data() {
        return {
            data: null,
            show: true,
            suggestions: [],
            remoteCombo: [{
                id: false,
                val: "Presenza"
            },{
                id: true,
                val: "Remoto"
            }]
        }
    },
    methods: {
        selectAllAuditors(){
            let toBeSelected = [];
            this.data.usersstandardsphasesrolesdates.forEach(function(entry) {
                entry.phasesstandards.forEach(function(entry) {
                    entry.standards.forEach(function(entry) {
                        toBeSelected.push(entry.id);               
                    });
                });
            });
            this.data.reservation.usersstandardsphasesrolesdates = toBeSelected;
        },
        loadData(auditsId, date, id){
            this.openHttp("audits/reservation", {audits_id: auditsId, id: id, date: date}).then((result) => {
                this.data = result;
                this.show = true;
                this.suggestionData();
            })
        },
        suggestionData(){
            this.openHttp("audits/reservation/standardspoints", {id: this.data.reservation.id, audits_id: this.data.reservation.audits_id, types_id: this.data.reservation.auditstypes_id, sites_id: this.data.reservation.auditssites_id, usersstandardsphasesrolesdates: this.data.reservation.usersstandardsphasesrolesdates}).then((result) => {
                this.suggestions = result;
                this.data.reservation.standardspoints = this.data.reservation.standardspoints.filter(value => this.suggestions.includes(value));
            })
        },
        saveData(){
            this.saveHttp("audits/reservation/save", this.data.reservation).then((result) => {
                if ( result !== null ) this.closeForm();
            })
        },
        deleteData(){
            this.deleteHttp("audits/reservation/delete", {id: this.data.reservation.id}).then((result) => {
                if ( result !== null ) this.closeForm();
            })
        },
        closeForm(){
            this.show = false;
            this.data = null;
            this.$emit("update");
        },
        halfData(auditsId, date){
            this.saveHttp("audits/reservation/half", {audits_id: auditsId, date: date}).then((result) => {
                if ( result !== null ) this.closeForm();
            })
        },
        fullData(auditsId, date){
            this.saveHttp("audits/reservation/full", {audits_id: auditsId, date: date}).then((result) => {
                if ( result !== null ) this.closeForm();
            })
        }
    },
}
</script>