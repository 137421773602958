<template>
  <div>
    <contract ref="contract" @update="loadData()"/>
    <contractquote ref="contractquote" @update="loadData()"/>
    <b-modal v-if="showFilter" v-model="showFilter" title="Filtra">
      <b-form-group label="Id">
        <b-form-input type="number" v-model="tempFilter.id"/>
      </b-form-group>
      <b-form-group label="Azienda">
        <b-form-select v-model="tempFilter.companies_id" :options="addNullCombo(filtersMenu.companies)" value-field="id" text-field="val"/>
      </b-form-group>
      <b-form-group label="Segnalatore">
        <b-form-select v-model="tempFilter.suppliers_id" :options="addNullCombo(filtersMenu.suppliers)" value-field="id" text-field="val"/>
      </b-form-group>
      <b-form-group label="Norma">
        <b-form-select v-model="tempFilter.standards_id" :options="addNullCombo(filtersMenu.standards)" value-field="id" text-field="val"/>
      </b-form-group>
      <b-form-group label="Ente">
        <b-form-select v-model="tempFilter.certificationbodies_id" :options="addNullCombo(filtersMenu.certificationbodies)" value-field="id" text-field="val"/>
      </b-form-group>
      <b-form-group label="IAF">
        <b-form-select v-model="tempFilter.nacesiafcodes_id" :options="addNullCombo(filtersMenu.nacesiafcodes)" value-field="id" text-field="val"/>
      </b-form-group>
      <b-form-group label="Errore">
        <b-form-select v-model="tempFilter.errorstypes_id" :options="addNullCombo(filtersMenu.errorstypes)" value-field="id" text-field="val"/>
      </b-form-group>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="primary" class="mr-2" @click="filterSet">Filtra</b-btn>
          <b-btn variant="warning" @click="filterReset">Reset</b-btn>
        </div>
      </template>
    </b-modal>
    <b-btn class="mr-2" variant="primary" @click="loadData">Aggiorna</b-btn>
    <b-btn class="mr-2" variant="secondary" @click="filterOpen">Filtra</b-btn>
    <b-btn variant="success" @click="$refs.contract.loadData(0)">Nuovo</b-btn>
    <b-card no-body class="mt-2">
      <b-tabs card>
        <b-tab>
          <template #title>
            Preventivi {{ quotes.items.total }}
          </template>
          <b-table no-border-collapse :sticky-header="(winHeight - 305) + 'px'" responsive show-empty mode="remote" :items="quotes.items.data" :fields="quotes.fields" :busy="quotes.isBusy" :no-local-sorting="true" :sort-by.sync="quotes.search.sortby" :sort-desc.sync="contracts1.search.sortdesc" @sort-changed="sortingChangeQuotes" @row-clicked="$refs.contractquote.loadData($event.id)" >
            <template v-slot:table-busy>
              <div class="text-center text-primary">
                <b-spinner class="align-middle"/>
              </div>
            </template>
            <template v-slot:empty>Non ci sono risultati</template>
            <template v-slot:cell(steps)="row">
              <div v-for="(item, index) of row.value" :key="index">
                {{ item.year }}: {{ item.title }}
              </div>
            </template>
          </b-table>
          <tablebottom :busy="quotes.isBusy" :currentpage="quotes.search.page" :total="quotes.items.total" @updateCurrentPage="quotes.search.page = $event" @loadData="loadDataQuotes"/>
        </b-tab>
        <b-tab>
          <template #title>
            Application {{ contracts1.items.total }}
          </template>
          <b-table no-border-collapse :sticky-header="(winHeight - 305) + 'px'" responsive show-empty mode="remote" :items="contracts1.items.data" :fields="contracts1.fields" :busy="contracts1.isBusy" :no-local-sorting="true" :sort-by.sync="contracts1.search.sortby" :sort-desc.sync="contracts1.search.sortdesc" @sort-changed="sortingChange1" @row-clicked="$refs.contract.loadData($event.id)">
            <template v-slot:table-busy>
              <div class="text-center text-primary">
                <b-spinner class="align-middle"/>
              </div>
            </template>
            <template v-slot:empty>Non ci sono risultati</template>
            <template v-slot:cell(standards)="row">
              <div v-for="(item, index) of row.item.standards" :key="index">
                {{ item }}
              </div>
            </template>
          </b-table>
          <tablebottom :busy="contracts1.isBusy" :currentpage="contracts1.search.page" :total="contracts1.items.total" @updateCurrentPage="contracts1.search.page = $event" @loadData="loadData1"/>
        </b-tab>
        <b-tab active>
          <template #title>
            Contratti aperti {{ contracts2.items.total }}
          </template>
          <b-table no-border-collapse :sticky-header="(winHeight - 305) + 'px'" responsive show-empty mode="remote" :items="contracts2.items.data" :fields="contracts2.fields" :busy="contracts2.isBusy" :no-local-sorting="true" :sort-by.sync="contracts2.search.sortby" :sort-desc.sync="contracts2.search.sortdesc" @sort-changed="sortingChange2" @row-clicked="$refs.contract.loadData($event.id)">
            <template v-slot:table-busy>
              <div class="text-center text-primary">
                <b-spinner class="align-middle"/>
              </div>
            </template>
            <template v-slot:empty>Non ci sono risultati</template>
            <template v-slot:cell(standards)="row">
              <div v-for="(item, index) of row.item.standards" :key="index">
                {{ item }}
              </div>
            </template>
          </b-table>
          <tablebottom :busy="contracts2.isBusy" :currentpage="contracts2.search.page" :total="contracts2.items.total" @updateCurrentPage="contracts2.search.page = $event" @loadData="loadData2"/>
        </b-tab>
        <b-tab>
          <template #title>
            Contratti chiusi {{ contracts3.items.total }}
          </template>
          <b-table no-border-collapse :sticky-header="(winHeight - 305) + 'px'" responsive show-empty mode="remote" :items="contracts3.items.data" :fields="contracts3.fields" :busy="contracts3.isBusy" :no-local-sorting="true" :sort-by.sync="contracts3.search.sortby" :sort-desc.sync="contracts3.search.sortdesc" @sort-changed="sortingChange3" @row-clicked="$refs.contract.loadData($event.id)">
            <template v-slot:table-busy>
              <div class="text-center text-primary">
                <b-spinner class="align-middle"/>
              </div>
            </template>
            <template v-slot:empty>Non ci sono risultati</template>
            <template v-slot:cell(standards)="row">
              <div v-for="(item, index) of row.item.standards" :key="index">
                {{ item }}
              </div>
            </template>
          </b-table>
          <tablebottom :busy="contracts3.isBusy" :currentpage="contracts3.search.page" :total="contracts3.items.total" @updateCurrentPage="contracts3.search.page = $event" @loadData="loadData3"/>
        </b-tab>
        <b-tab>
          <template #title>
            Application falliti {{ contracts4.items.total }}
          </template>
          <b-table no-border-collapse :sticky-header="(winHeight - 305) + 'px'" responsive show-empty mode="remote" :items="contracts4.items.data" :fields="contracts4.fields" :busy="contracts4.isBusy" :no-local-sorting="true" :sort-by.sync="contracts4.search.sortby" :sort-desc.sync="contracts4.search.sortdesc" @sort-changed="sortingChange4" @row-clicked="$refs.contract.loadData($event.id)">
            <template v-slot:table-busy>
              <div class="text-center text-primary">
                <b-spinner class="align-middle"/>
              </div>
            </template>
            <template v-slot:empty>Non ci sono risultati</template>
            <template v-slot:cell(standards)="row">
              <div v-for="(item, index) of row.item.standards" :key="index">
                {{ item }}
              </div>
            </template>
          </b-table>
          <tablebottom :busy="contracts4.isBusy" :currentpage="contracts4.search.page" :total="contracts4.items.total" @updateCurrentPage="contracts4.search.page = $event" @loadData="loadData4"/>
        </b-tab>
        <b-tab>
          <template #title>
            Contratti falliti {{ contracts5.items.total }}
          </template>
          <b-table no-border-collapse :sticky-header="(winHeight - 305) + 'px'" responsive show-empty mode="remote" :items="contracts5.items.data" :fields="contracts5.fields" :busy="contracts5.isBusy" :no-local-sorting="true" :sort-by.sync="contracts5.search.sortby" :sort-desc.sync="contracts5.search.sortdesc" @sort-changed="sortingChange5" @row-clicked="$refs.contract.loadData($event.id)">
            <template v-slot:table-busy>
              <div class="text-center text-primary">
                <b-spinner class="align-middle"/>
              </div>
            </template>
            <template v-slot:empty>Non ci sono risultati</template>
            <template v-slot:cell(standards)="row">
              <div v-for="(item, index) of row.item.standards" :key="index">
                {{ item }}
              </div>
            </template>
          </b-table>
          <tablebottom :busy="contracts5.isBusy" :currentpage="contracts5.search.page" :total="contracts5.items.total" @updateCurrentPage="contracts5.search.page = $event" @loadData="loadData5"/>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import contractquote from './contractscomponents/contractquote.vue';

export default {
  name: "contracts",
  components: { contractquote },
  data() {
    return {
      showFilter: false,
      tempFilter: {
        id: null,
        companies_id: null,
        suppliers_id: null,
        standards_id: null,
        certificationbodies_id: null,
        nacesiafcodes_id: null,
        errorstypes_id: null,
      },
      filtersMenu: {
        companies:[],
        suppliers:[],
        standards:[],
        certificationbodies: [],
        nacesiafcodes: [],
        errorstypes: []
      },
      quotes: {
        isBusy: true,
        search: {
          page: 1,
          filters: {},
          sortby: 'quotedate',
          sortdesc: false
        },
        items: {
          total: 0,
          data: [],
        },
        fields: [{
          key: 'quotedate',
          label: 'Data',
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          },
        },{
          key: 'id',
          label: 'Preventivo',
        },{
          key: 'company',
          label: 'Azienda',
          sortable: true,
        },{
          key: 'certificationbody',
          label: 'Ente',
          sortable: true,
        },{
          key: 'steps',
          label: "Servizi",
        }]
      },
      contracts1: {
        isBusy: true,
        search: {
          page: 1,
          filters: {
            id: null,
            contractsstatus_id: 1,
            companies_id: null,
            suppliers_id: null,
            standards_id: null,
            certificationbodies_id: null,
            nacesiafcodes_id: null,
            errorstypes_id: null
          },
          sortby: 'expiracydate',
          sortdesc: false,
        },
        items: {
          total: 0,
          data: [],
        },
        fields: [{
          key: 'expiracydate',
          label: 'Scadenza',
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          },
        },{
          key: 'company',
          label: 'Azienda',
          sortable: true,
        },{
          key: 'contract',
          label: 'Contratto',
          sortable: true,
        },{
          key: 'standards',
          label: 'Norme',
        },{
          key: 'certificationbody',
          label: 'Ente',
          sortable: true,
        },{
          key: 'contracttype',
          label: 'Commesse',
        },{
          key: 'issuedate',
          label: 'Emissione',
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          }
        },{
          key: "errors",
          label: "Errori",
          sortable: true
        }]
      },
      contracts2: {
        isBusy: true,
        search: {
          page: 1,
          filters: {
            id: null,
            contractsstatus_id: 2,
            companies_id: null,
            suppliers_id: null,
            standards_id: null,
            certificationbodies_id: null,
            nacesiafcodes_id: null,
            errorstypes_id: null
          },
          sortby: 'expiracydate',
          sortdesc: false,
        },
        items: {
          total: 0,
          data: [],
        },
        fields: [{
          key: 'expiracydate',
          label: 'Scadenza',
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          },
        },{
          key: 'company',
          label: 'Azienda',
          sortable: true,
        },{
          key: 'contract',
          label: 'Contratto',
          sortable: true,
        },{
          key: 'standards',
          label: 'Norme',
        },{
          key: 'certificationbody',
          label: 'Ente',
          sortable: true,
        },{
          key: 'contracttype',
          label: 'Commesse',
        },{
          key: 'issuedate',
          label: 'Emissione',
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          }
        },{
          key: "errors",
          label: "Errori",
          sortable: true
        }]
      },
      contracts3: {
        isBusy: true,
        search: {
          page: 1,
          filters: {
            id: null,
            contractsstatus_id: 3,
            companies_id: null,
            suppliers_id: null,
            standards_id: null,
            certificationbodies_id: null,
            nacesiafcodes_id: null,
            errorstypes_id: null
          },
          sortby: 'expiracydate',
          sortdesc: false,
        },
        items: {
          total: 0,
          data: [],
        },
        fields: [{
          key: 'expiracydate',
          label: 'Scadenza',
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          },
        },{
          key: 'company',
          label: 'Azienda',
          sortable: true,
        },{
          key: 'contract',
          label: 'Contratto',
          sortable: true,
        },{
          key: 'standards',
          label: 'Norme',
        },{
          key: 'certificationbody',
          label: 'Ente',
          sortable: true,
        },{
          key: 'contracttype',
          label: 'Commesse',
        },{
          key: 'issuedate',
          label: 'Emissione',
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          }
        },{
          key: "errors",
          label: "Errori",
          sortable: true
        }]
      },
      contracts4: {
        isBusy: true,
        search: {
          page: 1,
          filters: {
            id: null,
            contractsstatus_id: 4,
            companies_id: null,
            suppliers_id: null,
            standards_id: null,
            certificationbodies_id: null,
            nacesiafcodes_id: null,
            errorstypes_id: null
          },
          sortby: 'expiracydate',
          sortdesc: false,
        },
        items: {
          total: 0,
          data: [],
        },
        fields: [{
          key: 'expiracydate',
          label: 'Scadenza',
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          },
        },{
          key: 'company',
          label: 'Azienda',
          sortable: true,
        },{
          key: 'contract',
          label: 'Contratto',
          sortable: true,
        },{
          key: 'standards',
          label: 'Norme',
        },{
          key: 'certificationbody',
          label: 'Ente',
          sortable: true,
        },{
          key: 'contracttype',
          label: 'Commesse',
        },{
          key: 'issuedate',
          label: 'Emissione',
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          }
        },{
          key: "errors",
          label: "Errori",
          sortable: true
        }]
      },
      contracts5: {
        isBusy: true,
        search: {
          page: 1,
          filters: {
            id: null,
            contractsstatus_id: 5,
            companies_id: null,
            suppliers_id: null,
            standards_id: null,
            certificationbodies_id: null,
            nacesiafcodes_id: null,
            errorstypes_id: null
          },
          sortby: 'expiracydate',
          sortdesc: false,
        },
        items: {
          total: 0,
          data: [],
        },
        fields: [{
          key: 'expiracydate',
          label: 'Scadenza',
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          },
        },{
          key: 'company',
          label: 'Azienda',
          sortable: true,
        },{
          key: 'contract',
          label: 'Contratto',
          sortable: true,
        },{
          key: 'standards',
          label: 'Norme',
        },{
          key: 'certificationbody',
          label: 'Ente',
          sortable: true,
        },{
          key: 'contracttype',
          label: 'Commesse',
        },{
          key: 'issuedate',
          label: 'Emissione',
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          }
        },{
          key: "errors",
          label: "Errori",
          sortable: true
        }]
      }
    }
  },
  computed: {
    winHeight (){
      return this.$store.getters.getWinHeight;
    },
    computedData(){
      return this.items.data.map(function( item ) {
        let today = new Date();
        let expiracyDate = new Date(item.expiracydate);
        item._cellVariants = {
          expiracydate: item.active === true && today.getTime() >= expiracyDate.getTime() ? 'danger' : (item.active === false ? 'warning' : 'success'),
        };
        return item;
      });
    },
  },
  created(){
    this.loadData();
  },
  methods: {
    loadData(){
      this.loadDataQuotes();
      this.loadData1();
      this.loadData2();
      this.loadData3();
      this.loadData4();
      this.loadData5();
    },
    loadDataQuotes(){
      this.quotes.isBusy = true;
      this.openHttp2("contracts/quotes", this.quotes.search).then((result) => {
        this.quotes.isBusy = false;
        this.quotes.items = result;
      })
    },
    loadData1(){
      this.contracts1.isBusy = true;
      this.openHttp2("contracts/", this.contracts1.search).then((result) => {
        this.contracts1.isBusy = false;
        this.contracts1.items = result;
      })
    },
    loadData2(){
      this.contracts2.isBusy = true;
      this.openHttp2("contracts/", this.contracts2.search).then((result) => {
        this.contracts2.isBusy = false;
        this.contracts2.items = result;
      })
    },
    loadData3(){
      this.contracts3.isBusy = true;
      this.openHttp2("contracts/", this.contracts3.search).then((result) => {
        this.contracts3.isBusy = false;
        this.contracts3.items = result;
      })
    },
    loadData4(){
      this.contracts4.isBusy = true;
      this.openHttp2("contracts/", this.contracts4.search).then((result) => {
        this.contracts4.isBusy = false;
        this.contracts4.items = result;
      })
    },
    loadData5(){
      this.contracts5.isBusy = true;
      this.openHttp2("contracts/", this.contracts5.search).then((result) => {
        this.contracts5.isBusy = false;
        this.contracts5.items = result;
      })
    },
    filterOpen(){
      this.openHttp2("contracts/filters", null).then((result) => {
        this.filtersMenu = result;
        this.showFilter = true;
      })
    },
    filterSet(){
      this.showFilter = false;
      this.contracts1.search.page = 1;
      this.contracts1.search.filters = JSON.parse(JSON.stringify(this.tempFilter));
      this.contracts1.search.filters.contractsstatus_id = 1;

      this.contracts2.search.page = 1;
      this.contracts2.search.filters = JSON.parse(JSON.stringify(this.tempFilter));
      this.contracts2.search.filters.contractsstatus_id = 2;

      this.contracts3.search.page = 1;
      this.contracts3.search.filters = JSON.parse(JSON.stringify(this.tempFilter));
      this.contracts3.search.filters.contractsstatus_id = 3;

      this.contracts4.search.page = 1;
      this.contracts4.search.filters = JSON.parse(JSON.stringify(this.tempFilter));
      this.contracts4.search.filters.contractsstatus_id = 4;

      this.contracts5.search.page = 1;
      this.contracts5.search.filters = JSON.parse(JSON.stringify(this.tempFilter));
      this.contracts5.search.filters.contractsstatus_id = 5;

      this.loadData();
    },
    filterReset(){
      this.tempFilter = {
        id: null,
        companies_id: null,
        suppliers_id: null,
        standards_id: null,
        certificationbodies_id: null,
        nacesiafcodes_id: null,
        errorstypes_id: null
      };
      this.filterSet();
    },
    sortingChangeQuotes(ctx) {
      this.quotes.search.sortby = ctx.sortBy;
      this.quotes.search.sortdesc = ctx.sortDesc;
      this.loadDataQuotes();
    },
    sortingChange1(ctx) {
      this.contracts1.search.sortby = ctx.sortBy;
      this.contracts1.search.sortdesc = ctx.sortDesc;
      this.loadData1();
    },
    sortingChange2(ctx) {
      this.contracts2.search.sortby = ctx.sortBy;
      this.contracts2.search.sortdesc = ctx.sortDesc;
      this.loadData2();
    },
    sortingChange3(ctx) {
      this.contracts3.search.sortby = ctx.sortBy;
      this.contracts3.search.sortdesc = ctx.sortDesc;
      this.loadData3();
    },
    sortingChange4(ctx) {
      this.contracts4.search.sortby = ctx.sortBy;
      this.contracts4.search.sortdesc = ctx.sortDesc;
      this.loadData4();
    },
    sortingChange5(ctx) {
      this.contracts5.search.sortby = ctx.sortBy;
      this.contracts5.search.sortdesc = ctx.sortDesc;
      this.loadData5();
    }
  }
}
</script>