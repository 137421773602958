<template>
  <div>
    <b-modal v-model="show" v-if="data !== null" title="Unisci">
      <b-form-group label="Sposta tutti i dati dell'azienda ID">
        <b-form-input type="number" v-model="data.old_id"/>
      </b-form-group>
      <b-form-group label="Nell'azienda ID">
        <b-form-input type="number" v-model="data.new_id"/>
      </b-form-group>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="success" @click="saveData">Procedi</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "companyduplicate",
  data() {
    return {
      show: false,
      data: null,
    }
  },
  methods: {
    loadData( id ){
      this.show = false;
      this.data = {
        old_id: id,
        new_id: null
      };
      this.show = true;
    },
    saveData(){
      this.saveHttp("companies/company/duplicatemerge", this.data).then((result) => {
        if ( result !== null ){
          this.show = false;
          this.$emit('update', this.data.new_id);
          this.data = null;
        }
      })
    }
  }
}
</script>