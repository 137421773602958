<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" size="lg" :title="'Comunicazione - ' + data.communication.id" scrollable @hidden="closeForm" @close="closeForm">
      <b-row v-if="data.communication.id > 0">
        <b-col lg="8" v-if="data.communication.mine === false">
          <b-form-group label="Mittente">
            <b-form-select v-model="data.communication.users_id" :options="data.users" value-field="id" text-field="val"/>
          </b-form-group>
        </b-col>
        <b-col lg="4">
          <b-form-group label="Data">
            <b-form-input type="date" v-model="data.communication.date"/>
          </b-form-group>
        </b-col>
      </b-row>
      <b-form-group label="Oggetto">
        <b-form-input v-model="data.communication.subject"/>
      </b-form-group>
      <b-form-group label="Destinatari" v-if="data.communication.id === 0">
        <multiselect  v-model="data.communication.usersSupport" :options="data.users" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="data.communication.users = updateCombo(data.communication.usersSupport)"/>
      </b-form-group>
      <b-form-group label="Comunicazione">
        <b-form-textarea v-model="data.communication.communication" rows="10"/>
      </b-form-group>
      <b-form-group v-if="data.communication.id > 0">
        <b-table :items="data.communication.users" :fields="fields"/>
      </b-form-group>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="success" class="mr-2" @click="readData" v-if="data.communication.id > 0 && data.communication.mine === false">Dai letta</b-btn>
          <b-btn variant="success" class="mr-2" @click="saveData" v-if="data.communication.id === 0" :disabled="data.communication.subject === null || data.communication.users.length === 0">Salva</b-btn>
          <b-btn variant="danger" v-show="data.communication.id > 0 && data.communication.mine === true" @click="deleteData">Elimina</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "communication",
  data() {
    return {
      show: true,
      data: null,
      fields: [{
        key: 'user',
        label: "Utente",
      },{
        key: 'read',
        label: 'Lettura',
        formatter: value => {
          if (value == null) return null;
          var d = new Date(value);
          return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
        },
      }],
    }
  },
  methods: {
    loadData( id ){
      this.openHttp("communications/communication", { id: id }).then((result) => {
        this.data = result;
        this.show = true;
      })
    },
    readData(){
      this.saveHttp("communications/communication/read", { id: this.data.communication.id }).then((result) => {
        if ( result !== null ) this.closeForm();
      })
    },
    saveData(){
      this.saveHttp("communications/communication/save", this.data.communication).then((result) => {
        if ( result !== null ) this.closeForm();
      })
    },
    deleteData(){
      this.deleteHttp("communications/communication/delete", { id: this.data.communication.id }).then((result) => {
        if ( result !== null ) this.closeForm();
      })
    },
    closeForm(){
      this.show = false;
      this.data = null;
      this.$emit('update');
    },
  },
}
</script>