<template>
  <div>
    <b-modal v-if="phone!= null" v-model="show" title="Telefono" @hidden="closeForm" @close="closeForm">
      <b-form-input v-model="phone"/>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn v-for="(item, index) of phones" :key="index" variant="info" class="mr-2" @click="makePhoneCall(item.id, item.val)">{{ item.val }}</b-btn>
          <b-btn variant="info" @click="makePhoneCall(-1)">Cellulare</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "phone",
  data() {
    return {
      phone: null,
      show: false,
      phones: [{
        id: 0,
        val: "Euro IT"
      },{
        id: 1,
        val: "Certificarsi"
      },{
        id: 2,
        val: "Bul Cert"
      }]
    }
  },
  methods: {
    loadData(phone){
      this.show = true;
      this.phone = phone;
    },
    makePhoneCall (prefix, caller){
      if (prefix == -1){
        window.open("tel:" + this.phone, "_blank");
        this.closeForm();
      } else {
        this.openHttp("companies/contact/phone", { prefix: prefix, phone: this.phone, caller: caller }).then(() => {
          this.closeForm();
        })
      }
    },
    closeForm(){
      this.phone = null;
      this.show = false;
    },
  },
}
</script>