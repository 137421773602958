<template>
  <div>
    <b-card no-body>
      <b-tabs card>
        <b-tab title="Autorizzazioni" active>
          <usersform ref="form" @update="loadDataForms()"/>
          <b-btn variant="primary" @click="loadDataForms()">Aggiorna</b-btn>
          <b-table no-border-collapse :sticky-header="(winHeight - 305) + 'px'" hover responsive show-empty mode="remote" class="mt-3" :items="forms.items.data" :fields="forms.fields" :busy="forms.isBusy" :no-local-sorting="true" :sort-by.sync="forms.search.sortby" :sort-desc.sync="forms.search.sortdesc" @sort-changed="sortingChangeForms()" @row-clicked="$refs.form.loadData($event.id)">
            <template v-slot:table-busy>
              <div class="text-center text-primary">
                <b-spinner class="align-middle"/>
              </div>
            </template>
            <template v-slot:empty>Non ci sono risultati</template>
            <template v-slot:cell(users)="row">
              <div v-for="(item, index) in row.value" :key="index"><b>Livello {{ item.level }}: </b> {{ item.users.join(", ") }}</div>
            </template>
          </b-table>
          <tablebottom :busy="forms.isBusy" :currentpage="forms.search.page" :total="forms.items.total" @updateCurrentPage="forms.search.page = $event" @loadData="loadDataForms"/>
        </b-tab>
        <b-tab title="Utenti">
          <usersuser ref="user" @update="loadDataUsers()"/>
          <b-modal v-model="users.showFilter" title="Filtra">
            <b-form-group label="Utente">
              <b-form-input v-model="users.tempFilter.name"/>
            </b-form-group>
            <template v-slot:modal-footer>
              <div class="w-100">
                <b-btn variant="primary" class="mr-2" @click="filterSetUsers">Filtra</b-btn>
                <b-btn variant="warning" @click="filterResetUsers">Reset</b-btn>
              </div>
            </template>
          </b-modal>
          <b-btn class="mr-2" variant="primary" @click="loadDataUsers">Aggiorna</b-btn>
          <b-btn class="mr-2" variant="secondary" @click="users.showFilter = true">Filtra</b-btn>
          <b-btn variant="success" @click="$refs.user.loadData(0)">Nuovo</b-btn>
          <b-table no-border-collapse :sticky-header="(winHeight - 305) + 'px'" hover responsive show-empty mode="remote" class="mt-3" :items="users.items.data" :fields="users.fields" :busy="users.isBusy" :no-local-sorting="true" :sort-by.sync="users.search.sortby" :sort-desc.sync="users.search.sortdesc" @sort-changed="sortingChangeUsers" @row-clicked="$refs.user.loadData($event.id)">
            <template v-slot:table-busy>
              <div class="text-center text-primary">
                <b-spinner class="align-middle"/>
              </div>
            </template>
            <template v-slot:empty>Non ci sono risultati</template>
          </b-table>
          <tablebottom :busy="users.isBusy" :currentpage="users.search.page" :total="users.items.total" @updateCurrentPage="users.search.page = $event" @loadData="loadDataUsers"/>
        </b-tab>
        <b-tab title="Grafici">
          <userschart ref="chart" @update="loadDataCharts"/>
          <b-btn class="mr-2" variant="primary" @click="loadDataCharts">Aggiorna</b-btn>
          <b-btn variant="success" @click="$refs.chart.loadData(0)">Nuovo</b-btn>
          <b-table no-border-collapse :sticky-header="(winHeight - 305) + 'px'" hover responsive show-empty mode="remote" class="mt-3" :items="charts.items.data" :fields="charts.fields" :busy="charts.isBusy" :no-local-sorting="true" :sort-by.sync="charts.search.sortby" :sort-desc.sync="charts.search.sortdesc" @sort-changed="sortingChangeCharts" @row-clicked="$refs.chart.loadData($event.id)">
            <template v-slot:table-busy>
              <div class="text-center text-primary">
                <b-spinner class="align-middle"/>
              </div>
            </template>
            <template v-slot:empty>Non ci sono risultati</template>
          </b-table>
          <tablebottom :busy="charts.isBusy" :currentpage="charts.search.page" :total="charts.items.total" @updateCurrentPage="charts.search.page = $event" @loadData="loadDataCharts"/>
        </b-tab>
        <b-tab title="Kpi">
          <userskpi ref="kpi" @update="loadDataKpis()"/>
          <b-btn class="mr-2" variant="primary" @click="loadDataKpis">Aggiorna</b-btn>
          <b-btn variant="success" @click="$refs.kpi.loadData(0)">Nuovo</b-btn>
          <b-table no-border-collapse :sticky-header="(winHeight - 305) + 'px'" hover responsive show-empty mode="remote" class="mt-3" :items="kpis.items.data" :fields="kpis.fields" :busy="kpis.isBusy" :no-local-sorting="true" :sort-by.sync="kpis.search.sortby" :sort-desc.sync="kpis.search.sortdesc" @sort-changed="sortingChangeKpis" @row-clicked="$refs.kpi.loadData($event.id)">
            <template v-slot:table-busy>
              <div class="text-center text-primary">
                <b-spinner class="align-middle"/>
              </div>
            </template>
            <template v-slot:empty>Non ci sono risultati</template>
          </b-table>
          <tablebottom :busy="kpis.isBusy" :currentpage="kpis.search.page" :total="kpis.items.total" @updateCurrentPage="kpis.search.page = $event" @loadData="loadDataKpis"/>
        </b-tab>
        <b-tab title="Mail">
          <mailbox ref="mailbox" @update="loadDataMailboxes"/>
          <b-modal v-model="mailboxes.showFilter" title="Filtra">
            <b-form-group label="Mail">
              <b-form-input v-model="mailboxes.tempFilter.mail"/>
            </b-form-group>
            <b-form-group label="Utente">
              <b-form-input v-model="mailboxes.tempFilter.user"/>
            </b-form-group>
            <template v-slot:modal-footer>
              <div class="w-100">
                <b-btn variant="primary" class="mr-2" @click="filterSetMailboxes">Filtra</b-btn>
                <b-btn variant="warning" @click="filterResetMailboxes">Reset</b-btn>
              </div>
            </template>
          </b-modal>
          <b-btn class="mr-2" variant="primary" @click="loadDataMailboxes">Aggiorna</b-btn>
          <b-btn class="mr-2" variant="secondary" @click="mailboxes.showFilter = true">Filtra</b-btn>
          <b-btn class="mr-2" variant="success" @click="$refs.mailbox.loadData(0)">Nuova</b-btn>
          <b-table no-border-collapse :sticky-header="(winHeight - 305) + 'px'" hover responsive show-empty mode="remote" class="mt-3" :items="mailboxes.items.data" :fields="mailboxes.fields" :busy="mailboxes.isBusy" :no-local-sorting="true" :sort-by.sync="mailboxes.search.sortby" :sort-desc.sync="mailboxes.search.sortdesc" @sort-changed="sortingChangeMailboxes" @row-clicked="$refs.mailbox.loadData($event.id)">
            <template v-slot:table-busy>
              <div class="text-center text-primary">
                <b-spinner class="align-middle"/>
              </div>
            </template>
            <template v-slot:empty>Non ci sono risultati</template>
          </b-table>
          <tablebottom :busy="mailboxes.isBusy" :currentpage="mailboxes.search.page" :total="mailboxes.items.total" @updateCurrentPage="mailboxes.search.page = $event" @loadData="loadDataMailboxes"/>
        </b-tab>
        <b-tab title="Mail aliases">
          <mailboxalias ref="mailboxalias" @update="loadDataMailboxesaliases()"/>
          <b-modal v-model="mailboxesaliases.showFilter" title="Filtra">
            <b-form-group label="Mail">
              <b-form-input v-model="mailboxesaliases.tempFilter.mail"/>
            </b-form-group>
            <template v-slot:modal-footer>
              <div class="w-100">
                <b-btn variant="primary" class="mr-2" @click="filterSetMailboxesaliases">Filtra</b-btn>
                <b-btn variant="warning" @click="filterResetMailboxesaliases">Reset</b-btn>
              </div>
            </template>
          </b-modal>
          <b-btn class="mr-2" variant="primary" @click="loadDataMailboxesaliases()">Aggiorna</b-btn>
          <b-btn class="mr-2" variant="secondary" @click="mailboxesaliases.showFilter = true">Filtra</b-btn>
          <b-btn class="mr-2" variant="success" @click="$refs.mailboxalias.loadData(0)">Nuova</b-btn>
          <b-table no-border-collapse :sticky-header="(winHeight - 305) + 'px'" hover responsive show-empty mode="remote" class="mt-3" :items="mailboxesaliases.items.data" :fields="mailboxesaliases.fields" :busy="mailboxesaliases.isBusy" :no-local-sorting="true" :sort-by.sync="mailboxesaliases.search.sortby" :sort-desc.sync="mailboxesaliases.search.sortdesc" @sort-changed="sortingChangeMailboxesaliases" @row-clicked="$refs.mailboxalias.loadData($event.id)">
            <template v-slot:table-busy>
              <div class="text-center text-primary">
                <b-spinner class="align-middle"/>
              </div>
            </template>
            <template v-slot:empty>Non ci sono risultati</template>
          </b-table>
          <tablebottom :busy="mailboxesaliases.isBusy" :currentpage="mailboxesaliases.search.page" :total="mailboxesaliases.items.total" @updateCurrentPage="mailboxesaliases.search.page = $event" @loadData="loadDataMailboxesaliases"/>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import usersform from './usersform.vue'
import usersuser from './usersuser.vue'
import userschart from './userschart.vue'
import userskpi from './userskpi.vue'
import mailbox from './mailbox.vue'
import mailboxalias from './mailboxalias.vue'

export default {
  name: "users",
  components : {
    usersform, usersuser, userschart, userskpi, mailbox, mailboxalias
  },
  data() {
    return {
      forms: {
        isBusy: true,
        search: {
          page: 1,
          sortby: 'form',
          sortdesc: false,
        },
        fields: [{
          key: 'form',
          label: 'Maschere',
          sortable: true,
        },{
          key: 'users',
          label: "Autorizzati",
        }],
        items: {
          total: 0,
          data: [],
        }
      },
      users: {
        isBusy: true,
        showFilter: false,
        search: {
          page: 1,
          filters: {
            name: null,
          },
          sortby: 'active',
          sortdesc: true,
        },
        tempFilter: {
          name: null,
        },
        fields: [{
          key: 'firstname',
          label: 'Nome',
          sortable: true
        },{
          key: 'lastname',
          label: 'Cognome',
          sortable: true
        },{
          key: 'active',
          label: "Stato",
          sortable: true,
          formatter: value => {
            return value === true ? 'Attivo' : 'Non Attivo'; 
          }
        },{
          key: 'internal',
          label: "Tipologia",
          sortable: true,
          formatter: value => {
            return value === true ? 'Interna' : 'Esterna'; 
          }
        },{
          key: 'supplier',
          label: "Segnalatore",
          sortable: true
        },{
          key: 'languageen',
          label: "Lingua",
          sortable: true
        }],
        items: {
          total: 0,
          data: [],
        }
      },
      charts: {
        isBusy: true,
        search: {
          page: 1,
          sortby: 'chartname',
          sortdesc: false,
        },
        fields: [{
          key: 'chartname',
          label: 'Grafico',
          sortable: true,
        },{
          key: 'users',
          label: "Autorizzati",
        },{
          key: 'sqlgo',
          label: "Codice",
        }],
        items: {
          total: 0,
          data: [],
        }
      },
      kpis: {
        isBusy: true,
        search: {
          page: 1,
          sortby: "kpi",
          sortdesc: false
        },
        fields: [{
          key: "kpi",
          label: "Indicatore",
          sortable: true,
        },{
          key: "mindate",
          label: "Minimo",
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          }
        },{
          key: "maxdate",
          label: "Massimo",
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          }
        }],
        items: {
          total: 0,
          data: [],
        }
      },
      mailboxes: {
        isBusy: true,
        showFilter: false,
        search: {
          page: 1,
          filters: {
            user: null,
            mail: null,
          },
          sortby: "domain",
          sortdesc: false,
        },
        tempFilter: {
          user: null,
          mail: null
        },
        fields: [{
          key: 'domain',
          label: 'Dominio',
          sortable: true,
        },{
          key: 'email',
          label: 'Mail',
          sortable: true,
        },{
          key: 'users',
          label: 'Utenti',
        }],
        items: {
          total: 0,
          data: [],
        }
      },
      mailboxesaliases: {
        isBusy: true,
        showFilter: false,
        search: {
          page: 1,
          filters: {
            mail: null,
          },
          sortby: "source",
          sortdesc: false,
        },
        tempFilter: {
          mail: null,
        },
        fields: [{
          key: 'source',
          label: 'Origine',
          sortable: true,
        },{
          key: 'destination',
          label: 'Destinazione',
        }],
        items: {
          total: 0,
          data: [],
        }
      }      
    }
  },
  computed: {
    winHeight (){
      return this.$store.getters.getWinHeight;
    },
  },
  created(){
    this.loadDataForms();
    this.loadDataUsers();
    this.loadDataCharts();
    this.loadDataKpis();
    this.loadDataMailboxes();
    this.loadDataMailboxesaliases();
  },
  methods: {
    loadDataForms(){
      this.forms.isBusy = true;
      this.openHttp( "users/forms", this.forms.search ).then((result) => {
        this.forms.items = result;
        this.forms.isBusy = false;
      })
    },
    sortingChangeForms(ctx) {
      this.forms.search.sortby = ctx.sortBy;
      this.forms.search.sortdesc = ctx.sortDesc;
      this.loadDataForms();
    },
    loadDataUsers(){
      this.users.isBusy = true;
      this.openHttp( "users/users", this.users.search ).then((result) => {
        this.users.items = result;
        this.users.isBusy = false;
      })
    },
    filterSetUsers(){
      this.users.showFilter = false;
      this.users.search.page = 1;
      this.users.search.filters = this.users.tempFilter;
      this.loadDataUsers();
    },
    filterResetUsers(){
      this.users.tempFilter = {
        name: null,
      }
      this.filterSetUsers();
    },
    sortingChangeUsers(ctx) {
      this.users.search.sortby = ctx.sortBy;
      this.users.search.sortdesc = ctx.sortDesc;
      this.loadDataUsers();
    },
    loadDataCharts(){
      this.charts.isBusy = true;
      this.openHttp( "users/charts", this.charts.search ).then((result) => {
        this.charts.isBusy = false;
        this.charts.items = result;
      })
    },
    sortingChangeCharts(ctx) {
      this.charts.search.sortby = ctx.sortBy;
      this.charts.search.sortdesc = ctx.sortDesc;
      this.loadDataCharts();
    },
    loadDataKpis(){
      this.kpis.isBusy = true;
      this.openHttp( "users/kpis", this.kpis.search ).then((result) => {
        this.kpis.isBusy = false;
        this.kpis.items = result;
      })
    },
    sortingChangeKpis(ctx) {
      this.kpis.search.sortby = ctx.sortBy;
      this.kpis.search.sortdesc = ctx.sortDesc;
      this.loadDataKpis();
    },
    loadDataMailboxes(){
      this.mailboxes.isBusy = true;
      this.openHttp("users/mailboxes", this.mailboxes.search ).then((result) => {
        this.mailboxes.isBusy = false;
        this.mailboxes.items = result;
      })
    },
    filterSetMailboxes(){
      this.mailboxes.showFilter = false;
      this.mailboxes.page = 1;
      this.mailboxes.search.filters = this.mailboxes.tempFilter;
      this.loadDataMailboxes();
    },
    filterResetMailboxes(){
      this.mailboxes.tempFilter = {
        mail: null,
        user: null,
      }
      this.filterSetMailboxes();
    },
    sortingChangeMailboxes(ctx) {
      this.mailboxes.search.sortby = ctx.sortBy;
      this.mailboxes.search.sortdesc = ctx.sortDesc;
      this.loadDataMailboxes();
    },

    loadDataMailboxesaliases(){
      this.mailboxesaliases.isBusy = true;
      this.openHttp("users/mailboxesaliases", this.mailboxesaliases.search ).then((result) => {
        this.mailboxesaliases.isBusy = false;
        this.mailboxesaliases.items = result;
      })
    },
    filterSetMailboxesaliases(){
      this.mailboxesaliases.showFilter = false;
      this.mailboxesaliases.page = 1;
      this.mailboxesaliases.search.filters = this.mailboxesaliases.tempFilter;
      this.loadDataMailboxesaliases();
    },
    filterResetMailboxesaliases(){
      this.mailboxesaliases.tempFilter = {
        mail: null,
      }
      this.filterSetMailboxesaliases();
    },
    sortingChangeMailboxesaliases(ctx) {
      this.mailboxesaliases.search.sortby = ctx.sortBy;
      this.mailboxesaliases.search.sortdesc = ctx.sortDesc;
      this.loadDataMailboxesaliases();
    }
  }
}
</script>