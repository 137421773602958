<template>
    <div>
        <b-modal v-if="data !== null" v-model="show" size="lg" title="Area tecnica" @hidden="closeForm" @close="closeForm">
            <b-row>
                <b-col lg="4">
                    <b-form-group label="Area">
                        <b-form-input v-model="data.technicalarea.technicalarea"/>
                    </b-form-group>
                </b-col>
                <b-col lg="4">
                    <b-form-group label="Ente">
                        <b-form-select v-model="data.technicalarea.certificationbodies_id" :options="data.certificationbodies" value-field="id" text-field="val"/>
                    </b-form-group>
                </b-col>
                <b-col lg="4">
                    <b-form-group label="Norma">
                        <b-form-select v-model="data.technicalarea.standards_id" :options="data.standards" value-field="id" text-field="val"/>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col lg="12">
                    <b-form-checkbox-group v-model="data.technicalarea.naces">
                        <b-form-group label="NACEs">
                            <b-table :sticky-header="(winHeight - 395) + 'px'" :items="data.naces" :fields="fields">
                                <template v-slot:cell(naces)="row">
                                    <b-form-checkbox v-for="(item, index) of row.value" :key="index" :value="item.id">{{ item.val }} {{ item.description }}</b-form-checkbox>
                                </template>
                            </b-table>
                        </b-form-group>
                    </b-form-checkbox-group>
                </b-col>
            </b-row>
            <template v-slot:modal-footer>
                <div class="w-100">
                    <b-btn variant="success" class="mr-2" @click="saveData()">Salva</b-btn>
                    <b-btn variant="warning" class="mr-2" v-show="data.technicalarea.id> 0" @click="data.technicalarea.id = 0">Duplica</b-btn>
                    <b-btn variant="danger" v-show="data.technicalarea.id> 0" @click="deleteData()">Elimina</b-btn>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
export default {
    name: "technicalarea",
    data() {
        return {
            show: false,
            data: null,
            fields: [{
                key: "iafcode",
                label: "Punto",
                thStyle: {
                    width: '10%'
                }
            },{
                key: 'naces',
                label: "NACE",
                thStyle: {
                    width: '90%'
                },
            }],
        }
    },
    computed: {
        winHeight (){
            return this.$store.getters.getWinHeight;
        },
    },
    methods: {
        loadData(id){
            this.openHttp("standards/technicalarea", { id: id }).then((result) => {
                this.data = result;
                this.show = true;
            })
        },
        saveData(){
            this.saveHttp("standards/technicalarea/save", this.data.technicalarea).then((result) => {
                if ( result !== null ) this.closeForm();
            })
        },
        deleteData(){
            this.deleteHttp("standards/technicalarea/delete", { id: this.data.technicalarea.id }).then((result) => {
                if ( result !== null ) this.closeForm();
            })
        },
        closeForm(){
            this.show = false;
            this.data = null;
            this.$emit('update');
        },
    },
}
</script>