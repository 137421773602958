<template>
  <div>
    <b-modal v-model="show" title="Set Password" no-close-on-esc no-close-on-backdrop hide-header-close hide-footer>
      <b-form>
        <b-form-group label="Password">
          <b-form-input type="password" v-model="psw"/>
        </b-form-group>
        <b-form-group label="Confirm password">
          <b-form-input type="password" v-model="psw2"/>
        </b-form-group>
        <b-btn variant="primary" @click="resetData()">Reset</b-btn>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "resetpassword",
  props: ['token'],
  data() {
    return {
      show: true,
      psw: null,
      psw2: null
    }
  },
  methods: {
    resetData(){
      if (this.psw.length >= 8){
        if (this.psw === this.psw2){
          this.openHttp("reset/password", { token: this.token, psw: this.psw } ).then((result) => {
            if (result !== null) {
              this.$bvToast.toast("Password reset", { title: "ERP", variant: "primary", appendToast: true });
              this.show = false;
              var self = this;
              setTimeout(function() {
                self.$router.push("/login");
              }, 3000);
            }
          })
        } else {
          this.$bvToast.toast("Passwords don't match", { title: "ERP", variant: "danger", appendToast: true });
        }
      } else {
        this.$bvToast.toast("Passwords too short", { title: "ERP", variant: "danger", appendToast: true });
      }
    }
  }
}
</script>