<template>
    <div>
        <b-modal v-if="data !== null" v-model="show" :title="'Errore - ' + data.error.id" size="lg" @hidden="closeForm()" @close="closeForm()">
            <b-form-group label="Nome">
                <b-form-input v-model="data.error.errorname"/>
            </b-form-group>
            <b-form-group label="SQL">
                <b-form-textarea v-model="data.error.errsql" rows="6"/>
            </b-form-group>
            <template v-slot:modal-footer>
                <div class="w-100">
                    <b-btn variant="success" class="mr-2" @click="saveData()">Salva</b-btn>
                    <b-btn variant="danger" v-show="data.error.id > 0" @click="deleteData()">Elimina</b-btn>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
export default {
    name: "checklisterror",
    data() {
        return {
            show: false,
            data: null
        }
    },
    methods: {
        loadData(id){
            this.openHttp("standards/checklisterror", { id: id }).then((result) => {
                this.data = result;
                this.show = true;
            })
        },
        saveData(){
            this.saveHttp("standards/checklisterror/save", this.data.error).then((result) => {
                if (result !== null) this.closeForm();
            })
        },
        deleteData(){
            this.deleteHttp("standards/checklisterror/delete", { id: this.data.error.id }).then((result) => {
                if (result !== null) this.closeForm();
            })
        },
        closeForm(){
            this.show = false;
            this.data = null;
            this.$emit('update');
        }
    }
}
</script>