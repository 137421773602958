<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" :title="'Appuntamento - ' + data.id" scrollable @hidden="closeForm" @close="closeForm">
      <ordercost ref="cost" @update="loadData(data.id)"/>
      <orderhour ref="hour" @update="loadData(data.id)"/>
      <b-table-simple responsive>
        <b-tbody>
          <b-tr>
            <b-td style="width: 25%">Tipologia</b-td>
            <b-td>{{ data.type }}</b-td>
          </b-tr>
          <b-tr v-if="data.company !== null">
            <b-td>Azienda</b-td>
            <b-td>{{ data.company }}</b-td>
          </b-tr>
          <b-tr v-if="data.note !== null">
            <b-td>Note</b-td>
            <b-td>{{ data.note }}</b-td>
          </b-tr>
          <b-tr>
            <b-td>Durata</b-td>
            <b-td>{{ (data.morning === true ? 'Mattina' : '') + ' ' + (data.afternoon === true ? 'Pomeriggio' : '') }}</b-td>
          </b-tr>
          <b-tr v-if="data.orders_id !== null">
            <b-td>Commessa</b-td>
            <b-td>{{ data.orders_id }}</b-td>
          </b-tr>
          <b-tr v-if="data.services.length > 0">
            <b-td>Servizi</b-td>
            <b-td>
              <div v-for="(service, index) in data.services" :key="index">
                {{ service }}
              </div>
            </b-td>
          </b-tr>
          <b-tr v-if="data.sites.length > 0">
            <b-td>Sito</b-td>
            <b-td>
              <div v-for="(item, index) of data.sites" :key="index">
                <a :href="'https://www.google.com/maps/search/?api=1&query=' + item.site" target="_blanl">{{ item.site + (item.main === true ? ' - Principale' : '') }}</a>
              </div>
            </b-td>
          </b-tr>
          <b-tr v-if="data.purephones.length > 0">
            <b-td>Telefoni</b-td>
            <b-td>
              <a v-for="(phone, index) in data.purephones" :key="index" :href="'tel:' + phone">{{ phone }}</a>
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="primary" v-show="data.allowhours === true && data.orders_id > 0" class="mr-2" @click="$refs.hour.loadData([data.orders_id], 0)">Segna ore</b-btn>
          <b-btn variant="primary" v-show="data.allowcosts === true && data.orders_id > 0" @click="$refs.cost.loadData([data.orders_id], 0)">Segna spese</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>

export default {
  name: "homepageorderday",
  data() {
    return {
      show: true,
      data: null,
    }
  },
  methods: {
    loadData( id ){
      this.openHttp("homepage/ordersday", { id: id }).then((result) => {
        this.data = result;
        this.show = true;
      })
    },
    closeForm(){
      this.show = false;
      this.data = null;
      this.$emit('update');
    }
  }
}
</script>