<template>
    <div>
        <b-modal v-if="data!== null" v-model="show" title="'Fusione Contratti" size="lg">
            <b-form-group label="Da">
                <b-form-input size="sm" type="number" v-model="data.from" min="0"/>
            </b-form-group>
            <b-form-group label="A">
                <b-form-input size="sm" type="number" v-model="data.to" min="0"/>
            </b-form-group>
            <template v-slot:modal-footer>
                <div class="w-100">
                    <b-btn variant="success" @click="saveData">Salva</b-btn>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
export default {
    name: "migratecontract",
    data() {
        return {
            show: false,
            data: {
                from: null,
                to: null
            }
        }
    },
    methods: {
        loadData(id){
            this.data.from = id;
            this.show = true;
        },
        saveData(){
            this.saveHttp("zzz/audit/save", this.data).then((result) => {
                if ( result !== null ){
                    this.$emit("update");
                    this.show = false;
                }
            })
        },
    },
}
</script>