<template>
  <div>
    <zzzcalendardate ref="audit" @update="loadData()"/>
    <b-btn class="mr-2 mb-2" style="float: left" size="sm" @click="loadData()">{{ month + '-' + year}}</b-btn>
    <b-btn variant="primary" class="mr-2 mb-2" style="float: left" size="sm" @click="calendarPreviousMonth">Prima</b-btn>
    <b-btn variant="primary" class="mr-2 mb-2" style="float: left" size="sm" @click="calendarNextMonth">Dopo</b-btn>
    <b-table-simple hover responsive bordered :sticky-header="(winHeight - 100) + 'px'">
      <b-thead head-variant="light">
        <b-th>Persona</b-th>
        <b-th v-for="(item, index) in data.dates" :key="index">{{ item.day + ' ' + dowWeek[item.dow] }}</b-th>
      </b-thead>
      <b-tbody>
        <b-tr v-for="(item, index) in data.users" :key="index">
          <b-td>{{ item.val }}</b-td>
          <b-td v-for="(item2, index2) in data.dates" :key="index2">
            <b-btn v-for="(item3, index3) in getData(item.id, item2.dt)" :key="index3" :variant="getData(item.id, item2.dt).length > 1 ? 'danger' : 'success'" @click="$refs.audit.loadData(item3.id)">{{ item3.company }}</b-btn>
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
  </div>
</template>

<script>
import zzzcalendardate from './zzzcalendardate.vue';

export default {
  name: "zzz",
  components: {
    zzzcalendardate
  },
  data() {
    return {
      month: 10,
      year: 2006,
      data: {
        audits: [],
        users: [],
        dates: [],
      },
      dowWeek: ["Dom", "Lun", "Mar", "Mer", "Gio", "Ven", "Sab"]
    }
  },
  computed: {
    winHeight (){
      return this.$store.getters.getWinHeight;
    },
  },
  created(){
    this.loadData();
  },
  methods: {
    loadData(){
      this.openHttp("zzz/", { month: this.month, year: this.year }).then((result) => {
        this.data = result;
      })
    },
    calendarPreviousMonth(){
      let date = new Date(this.year, this.month - 1, 1);
      this.year = date.getMonth() === 0 ? date.getFullYear() - 1 : date.getFullYear();
      this.month = date.getMonth() === 0 ? 12 : date.getMonth();
      this.loadData();
    },
    calendarNextMonth(){
      let date = new Date(this.year, this.month, 1);
      this.year = date.getFullYear();
      this.month = date.getMonth() + 1;
      this.loadData();
    },
    getData(usersId, date){
      if (this.data.audits.findIndex(x => x.date == date && x.users_id == usersId) > -1){
        return this.data.audits.find(x => x.date == date && x.users_id == usersId).mandays;
      } else {
        return [];
      }
    }
  }
}
</script>
