<template>
  <div>
    <web ref="web" @update="loadWebs()"/>
    <b-modal v-model="webs.showFilter" title="Filtra">
      <b-form-group label="Data">
        <b-form-input type="date" v-model="webs.tempFilter.fromdate"/>
      </b-form-group>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="primary" class="mr-2" @click="filterSetWebs">Filtra</b-btn>
          <b-btn variant="warning" @click="filterResetWebs">Reset</b-btn>
        </div>
      </template>
    </b-modal>
    <b-btn class="mr-2" variant="primary" @click="loadWebs">Aggiorna</b-btn>
    <b-btn class="mr-2" variant="secondary" @click="webs.showFilter = true">Filtra</b-btn>
    <b-table no-border-collapse :sticky-header="(winHeight - 305) + 'px'" responsive show-empty mode="remote" class="mt-3" :items="webs.items.data" :fields="webs.fields" :busy="webs.isBusy" :no-local-sorting="true" :sort-by.sync="webs.search.sortby" :sort-desc.sync="webs.search.sortdesc" @sort-changed="sortingChangeWebs" @row-clicked="$refs.web.loadData($event.id, $event.date)">
      <template v-slot:table-busy>
        <div class="text-center text-primary">
          <b-spinner class="align-middle"/>
        </div>
      </template>
      <template v-slot:empty>Non ci sono risultati</template>
    </b-table>
    <tablebottom :busy="webs.isBusy" :currentpage="webs.search.page" :total="webs.items.total" @updateCurrentPage="webs.search.page = $event" @loadData="loadWebs"/>
  </div>
</template>

<script>
import web from "./web.vue"

export default {
  name: "webstats",
  components: { web },
  data() {
    return {
      webs: {
        isBusy: true,
        showFilter: false,
        search: {
          page: 1,
          filters: {
            date: null,
            domains_id: null,
          },
          sortby: "date",
          sortdesc: true,
        },
        tempFilter: {
          date: null,
          domains_id: null,
        },
        fields: [{
          key: "domain",
          label: "Dominio",
          sortable: true,
        },{
          key: 'date',
          label: 'Data',
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          },
        },{
          key: "cookiescount",
          label: "Cookies",
        },{
          key: "ipscount",
          label: "IPs",
        },{
          key: "countries",
          label: "Paesi",
        }],
        items: {
          total: 0,
          data: [],
        }
      }
    }
  },
  computed: {
    winHeight (){
      return this.$store.getters.getWinHeight;
    },
  },
  created(){
    this.loadData();
  },
  methods: {
    loadData(){
      this.loadWebs();
    },
    loadWebs(){
      this.webs.isBusy = true;
      this.openHttp("webstats/", this.webs.search ).then((result) => {
        this.webs.items = result;
        this.webs.isBusy = false;
      })
    },
    filterSetWebs(){
      this.webs.showFilter = false;
      this.webs.search.filters = this.webs.tempFilter;
      this.loadWebs();
    },
    filterResetWebs(){
      this.webs.tempFilter = {
        date: null,
        fromdate: null,
        todate: null,
      };
      this.filterSetWebs();
    },
    sortingChangeWebs(ctx) {
      this.webs.search.sortby = ctx.sortBy;
      this.webs.search.sortdesc = ctx.sortDesc;
      this.loadWebs();
    }
  }
}
</script>