<template>
  <div>
    <b-modal v-model="show" v-if="data !== null" :title="'Scadenza - ' + data.deadline.id" @hidden="closeForm()" @close="closeForm()">
      <b-form-group label="Data">
        <b-form-input type="date" v-model="data.deadline.date"/>
      </b-form-group>
      <b-form-group label="Collaboratore">
        <b-form-select v-model="data.deadline.users_id" :options="data.users" value-field="id" text-field="val"/>
      </b-form-group>
      <b-form-group label="Nota">
        <b-form-textarea v-model="data.deadline.note" rows="4"/>
      </b-form-group>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="success" class="mr-2" @click="saveData">Salva</b-btn>
          <b-btn variant="danger" v-if="data.deadline.id > 0" @click="deleteData">Elimina</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "companydeadline",
  data() {
    return {
      show: false,
      data: null,
    }
  },
  methods: {
    loadData( id, companies_id ){
      this.openHttp("companies/deadline", { id: id, companies_id: companies_id }).then((result) => {
        this.data = result;
        this.show = true;
      })
    },
    saveData(){
      this.saveHttp("companies/deadline/save", this.data.deadline).then((result) => {
        if ( result !== null ) this.closeForm();
      })
    },
    deleteData(){
      this.deleteHttp("companies/deadline/delete", { id: this.data.deadline.id }).then((result) => {
        if ( result !== null ) this.closeForm();
      })
    },
    closeForm(){
      this.show = false;
      this.data = null;
      this.$emit('update');
    }
  },
}
</script>