<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" :title="'Appuntamento - ' + data.ordersday.id" scrollable @hidden="closeForm()" @close="closeForm()">
      <b-form-group label="Incaricato">
        <b-form-select v-model="data.ordersday.users_id" :options="data.users" value-field="id" text-field="val"/>
      </b-form-group>
      <b-form-group label="Tipologia">
        <b-form-select v-model="data.ordersday.ordersdaystypes_id" :options="data.ordersdaystypes" value-field="id" text-field="val"/>
      </b-form-group>
      <b-row>
        <b-col lg="6">
          <b-form-group label="Da">
            <b-form-input type="date" v-model="data.ordersday.fromdate"/>
          </b-form-group>
        </b-col>
        <b-col lg="6">
          <b-form-group label="A">
            <b-form-input type="date" v-model="data.ordersday.todate"/>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="6">
          <b-form-group label="Mattina">
            <b-form-checkbox switch v-model="data.ordersday.morning">Mattina</b-form-checkbox>
          </b-form-group>
        </b-col>
        <b-col lg="6">
          <b-form-group label="Pomeriggio">
            <b-form-checkbox switch v-model="data.ordersday.afternoon">Pomeriggio</b-form-checkbox>
          </b-form-group>
        </b-col>
      </b-row>
      <b-form-group>
        <order ref="order" @update="loadData(data.ordersday.id)"/>
        <label><b-link v-if="data.ordersday.id > 0" @click="$refs.order.loadData(data.ordersday.orders_id)">Commessa</b-link><span v-else>Commessa</span></label>
        <multiselect v-model="orders_idSupport" :options="data.orders" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="data.ordersday.orders_id = updateComboSimple(orders_idSupport)" @search-change="asyncOrders"/>
      </b-form-group>
      <b-form-group label="Note">
        <b-form-textarea v-model="data.ordersday.note" rows="4"/>
      </b-form-group>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="success" class="mr-2" @click="saveData">Salva</b-btn>
          <b-btn variant="danger" v-show="data.ordersday.id > 0" @click="deleteData">Elimina</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "ordersday",
  data() {
    return {
      show: true,
      data: null,
      orders_idSupport: []
    }
  },
  methods: {
    loadData( id ){
      this.openHttp("ordersdays/orderday", { id: id }).then((result) => {
        this.orders_idSupport = this.convertComboSimple( result.orders, result.ordersday.orders_id );
        this.data = result;
        this.show = true;
      })
    },
    asyncOrders ( query ) {
      if ( query.length > 3 ){
        this.openHttp("ordersdays/orderday/orders", { search: query }).then((result) => {
          for ( let item of result ) if ( this.data.orders.findIndex( x => x.id === item.id ) === -1 ) this.data.orders.push(item);
          this.data.orders = this.data.orders.sort((a,b) => ( a.val > b.val ) ? 1 : 0 );
        })
      }
    },
    saveData(){
      this.saveHttp("ordersdays/orderday/save", this.data.ordersday).then((result) => {
        if ( result !== null ) this.closeForm(true);
      })
    },
    deleteData(){
      this.deleteHttp("ordersdays/orderday/delete", { id: this.data.ordersday.id }).then((result) => {
        if ( result !== null ) this.closeForm(true);
      })
    },
    closeForm( reload = false ){
      this.show = false;
      this.data = null;
      if ( reload === true ) this.$emit('update');
    },
  },
}
</script>