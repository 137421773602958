<template>
    <div>
        <b-modal v-if="data !== null" v-model="show" :title="'Anno prezzo - ' + data.costyear.id" size="lg" @hidden="closeForm()" @close="closeForm()">
            <b-row>
                <b-col lg="1">
                    <b-form-group label="Anno">
                        <b-form-input v-model="data.costyear.year" type="number" min="0" step="1"/>
                    </b-form-group>
                </b-col>
                <b-col lg="2">
                    <b-form-group label="Fase">
                        <b-form-select v-model="data.costyear.contractsphases_id" :options="data.contractsphases" value-field="id" text-field="val"/>
                    </b-form-group>
                </b-col>
                <b-col lg="1">
                    <b-form-group label="Prezzo">
                        <b-form-input v-model="data.costyear.cost" type="number" min="0" step="0.01"/>
                    </b-form-group>
                </b-col>
                <b-col lg="2">
                    <b-form-group label="Giorni uomo">
                        <b-form-input :value="data.costyear.standards.reduce((partialSum, a) => partialSum + a.mandaysreduced, 0)" type="number" min="0" step="0.0001"/>
                    </b-form-group>
                </b-col>
                <b-col lg="2">
                    <b-form-group label="Giorni uomo Vecchio">
                        <b-form-input :value="data.costyear.mandays" type="number" min="0" step="0.0001" disabled v-if="data.costyear.mandays !== null"/>
                    </b-form-group>
                </b-col>
                <b-col lg="4">
                    <b-form-group label="Audit">
                        <b-form-select v-model="data.costyear.audits_id" :options="addNullCombo(data.audits, 'Vuoto')" value-field="id" text-field="val"/>
                    </b-form-group>
                </b-col>
            </b-row>
            <hr>
            <b-row>
                <b-col lg="12">
                    <b-form-group label="Norme">
                        <b-btn variant="primary" size="sm" v-if="data.contractsstandards.length != data.costyear.standards.length" @click="data.costyear.standards.push({ id: 0, contractsstandards_id: data.contractsstandards.filter(x => !data.costyear.standards.map(y => y.contractsstandards_id).includes(x.id))[0].id, accreditated: null, riskslevels_id: null, mandaystable: null, mandaysreduced: null, mandaysonsite: null, note: null, locked: false })">Nuovo</b-btn>
                        <b-table no-border-collapse class="mt-2" :items="data.costyear.standards" :fields="fields.standards">
                            <template v-slot:cell(contractsstandards_id)="row">
                                <b-form-select v-model="row.item.contractsstandards_id" :options="data.contractsstandards.filter(x => row.item.id > 0 ? (x.id === row.item.contractsstandards_id) : true)" value-field="id" text-field="val" :disabled="row.item.locked"/>
                            </template>
                            <template v-slot:cell(contractsstandardsreleases_id)="row">
                                <b-form-select v-model="row.item.contractsstandardsreleases_id" :options="data.contractsstandards.findIndex(x => x.id === row.item.contractsstandards_id) >-1 ? data.contractsstandards.find(x => x.id === row.item.contractsstandards_id).releases : []" value-field="id" text-field="val" /> <!--:disabled="row.item.locked && row.item.contractsstandardsreleases_id !== null"-->
                            </template>

                            <template v-slot:cell(accreditated)="row">
                                <b-form-select v-model="row.item.accreditated" :options="accreditatedCombo" value-field="id" text-field="val" :disabled="row.item.locked"/>
                            </template>
                            <template v-slot:cell(riskslevels_id)="row">
                                <b-form-select v-model="row.item.riskslevels_id" :options="data.riskslevels.filter(x => x.contractsstandards.includes(row.item.contractsstandards_id))" value-field="id" text-field="val" :disabled="row.item.locked"/>
                            </template>
                            <template v-slot:cell(mandays)="row">
                                <b-input-group prepend="Tabella">
                                    <b-form-input v-model="row.item.mandaystable" type="number" min="0" step="0.0001" :disabled="row.item.locked"/>
                                </b-input-group>
                                <b-input-group prepend="Ridotti">
                                    <b-form-input v-model="row.item.mandaysreduced" type="number" min="0" step="0.0001" :disabled="row.item.locked"/>
                                </b-input-group>
                                <b-input-group prepend="On site">
                                    <b-form-input v-model="row.item.mandaysonsite" type="number" min="0" step="0.0001" :disabled="row.item.locked"/>
                                </b-input-group>
                            </template>
                            <template v-slot:cell(note)="row">
                                <b-form-textarea v-model="row.item.note" rows="2" max-rows="10" size="sm" :disabled="row.item.locked"/>
                            </template>
                            <template v-slot:cell(id)="row">
                                <b-btn variant="danger" size="sm" v-if="row.item.locked === false" @click="data.costyear.standards.splice(data.costyear.standards.findIndex(x => x.contractsstandards_id === row.item.contractsstandards_id), 1)">Elimina</b-btn>
                            </template>
                        </b-table>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col lg="12">
                    <b-form-group label="Siti">
                        <b-form-checkbox-group v-model="data.costyear.sites">
                            <b-table no-border-collapse :items="data.sites.filter(x => x.releases.filter(y => data.costyear.standards.map(x => x.contractsstandardsreleases_id).includes(y)).length > 0 )" :fields="fields.sites">
                                <template v-slot:cell(sampled)="row">
                                    <b-form-checkbox :value="row.item.id"/>
                                </template>
                            </b-table>
                        </b-form-checkbox-group>

                    </b-form-group>
                </b-col>
            </b-row>
            <template v-slot:modal-footer>
                <div class="w-100">
                    <b-btn variant="success" class="mr-2" @click="saveData()">Salva</b-btn>
                    <b-btn variant="danger" v-show="data.costyear.id > 0" @click="deleteData()">Elimina</b-btn>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
export default {
    name: "contractcost",
    data() {
        return {
            show: false,
            data: null,
            accreditatedCombo: [{
                id: true,
                val: "Si",
            },{
                id: false,
                val: "No",
            },{
                id: null,
                val: "???",
            }],
            fields: {
                standards: [{
                    key: "contractsstandards_id",
                    label: "Norma",
                    thStyle: { width: "15%" },
                },{
                    key: "contractsstandardsreleases_id",
                    label: "Rev",
                    thStyle: { width: "5%" },
                },{
                    key: "accreditated",
                    label: "Accreditato",
                    formatter: value => {
                        return value === true ? "Si" : (value === false ? "No" : "?");
                    },
                    thStyle: { width: "5%" }
                },{
                    key: "riskslevels_id",
                    label: "Rischio",
                    thStyle: { width: "5%" }
                },{
                    key: "mandays",
                    label: "GG",
                    thStyle: { width: "20%" }
                },{
                    key: "note",
                    label: "Note",
                    thStyle: { width: "40%" }
                },{
                    key: "locked",
                    label: "Bloccato",
                    formatter: value => {
                        return value === true ? "Si" : "No";
                    },
                    thStyle: { width: "5%" }
                },{
                    key: "id",
                    label: "",
                    thStyle: { width: "5%" }
                }],
                sites: [{
                    key: "city",
                    label: "Città"
                },{
                    key: "address",
                    label: "Indirizzo"
                },{
                    key: "sampled",
                    label: "Campionato"
                }]
            },
        }
    },
    methods: {
        loadData( contractsId, id ){
            this.openHttp("contracts/costyear", { contracts_id: contractsId, id: id }).then((result) => {
                this.data = result;
                this.show = true;
            })
        },
        siteAddDelete(id){
            let index = this.data.costyear.sites.findIndex(x => x.contractssites_id === id);
            if (index > -1){
                this.data.costyear.sites.splice(index, 1);
            } else {
                this.data.costyear.sites.push({contractssites_id: id, sampled: true});
            }
        },
        saveData(){
            this.saveHttp("contracts/costyear/save", this.data.costyear).then((result) => {
                if ( result !== null ) this.closeForm();
            })
        },
        deleteData(){
            this.deleteHttp("contracts/costyear/delete", { id: this.data.costyear.id }).then((result) => {
                if ( result !== null ) this.closeForm();
            })
        },
        closeForm(){
            this.show = false;
            this.data = null;
            this.$emit('update');
        },
    },
}
</script>