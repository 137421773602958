<template>
  <div>
    <b-card no-body>
      <b-tabs card>
        <b-tab title="Mail" active>
          <div v-if="mailfake.data != null">
            <b-row>
              <b-col lg="3">
                <b-form-group label="Casella">
                  <b-form-select v-model="mailfake.data.mailboxes_id" :options="mailboxes" value-field="id" text-field="val" @change="saveFieldData('mailboxes_id', $event)" />
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Da">
                  <b-form-input v-model="mailfake.data.fromaddress" @change="saveFieldData('fromaddress', $event)" />
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Cartella">
                  <b-form-select v-model="mailfake.data.mailboxesfolders_id" :options="folders" value-field="id" text-field="val" @change="saveFieldData('mailboxesfolders_id', $event)" />
                </b-form-group>
              </b-col>
              <b-col lg="3">
                <b-form-group label="Archivia">
                  <b-form-select v-model="mailfake.data.archivefolder" :options="archivefolders" value-field="id" text-field="val" @change="saveFieldData('archivefolder', $event)" />
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Archivia ID">
                  <b-form-input type="number" v-model="mailfake.data.archiveid" :disabled="mailfake.data.archivefolder === null" @change="saveFieldData('archiveid', $event)" />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="4">
                <b-form-group label="A">
                  <b-form-input v-model="mailfake.data.toaddress" @change="saveFieldData('toaddress', $event)" />
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="CC">
                  <b-form-input v-model="mailfake.data.ccaddress" @change="saveFieldData('ccaddress', $event)" />
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="BCC">
                  <b-form-input v-model="mailfake.data.bccaddress" :disabled="mailfake.data.mailboxesfolders_id === 1" @change="saveFieldData('bccaddress', $event)" />
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Date">
                  <b-form-input type="date" v-model="mailfake.data.date" @change="saveFieldData('date', $event)" />
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Time">
                  <b-form-input type="time" v-model="mailfake.data.time" @change="saveFieldData('time', $event)" />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="8">
                <b-form-group label="Oggetto">
                  <b-form-input v-model="mailfake.data.subject" @change="saveFieldData('subject', $event)"/>
                </b-form-group>
              </b-col>
              <b-col lg="4">
                <b-form-group label="Template">
                  <b-form-select v-model="mailfake.data.templates_id" :options="templates" value-field="id" text-field="val" @change="saveFieldData('templates_id', $event)"/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="12">
                <customeditor v-model="mailfake.data.body" :height="winHeight - 600" :showToolbar="true" @input="saveFieldData('body', $event)"/>
              </b-col>
            </b-row>
            <b-form-group label="Allegati">
              <b-modal v-model="mailfake.showUpload" title="Carica File" hide-footer>
                  <b-form-file @change="fileChange"/>
                </b-modal>
              <b-dropdown class="mr-2 mb-2" size="sm" v-for="(item, index) of mailfake.data.attachments" :key="index" :text="item">
                <b-dropdown-item @click="deleteAttachments(item)">Elimina</b-dropdown-item>
              </b-dropdown>
              <b-btn variant="primary" @click="mailfake.showUpload = true">Carica</b-btn>
            </b-form-group>
            <b-btn variant="success" @click="saveDataMailfake()">Salva</b-btn>
          </div>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import customeditor from '../customeditor.vue';
export default {
  name: "cheats",
  components: {
    customeditor
  },
  data() {
    return {
      mailfake: {
        data: null,
        showUpload: false
      },
      mailboxes: [],
      templates: [],
      folders: [{
          id: 1,
          val: "Ricevute"
      }, {
          id: 3,
          val: "Inviate"
      }],
      archivefolders: [{
        id: null,
        val: "Non archiviare"
      },{
        id: "companies",
        val: "Aziende"
      }, {
        id: "invoices",
        val: "Fatture"
      },{ 
        id: "orders",
        val: "Commesse"
      },{
        id: "quotes",
        val: "Preventivi"
      },{
        id: "contracts",
        val: "Contratti"
      },{
        id: "audits",
        val: "Audits"
      }]
    }
  },
  computed: {
    winHeight (){
      return this.$store.getters.getWinHeight;
    },
  },
  created(){
    this.loadDataMailfake(0);
  },
  methods: {
    loadDataMailfake(id){
      this.openHttp("cheats/mailboxes", {}).then((result) => {
        this.mailboxes = result;
      })
      this.openHttp("cheats/templates", {}).then((result) => {
        this.templates = result;
      })
      this.openHttp("cheats/mailfake", { id: id }).then((result) => {
        this.mailfake.data = result;
      })
    },

    saveFieldData(field, val){
      this.saveHttp("cheats/save", { id: this.mailfake.data.id, field: field, val: val }).then((result) => {
        if (result !== null) this.loadDataMailfake(this.mailfake.data.id);
      })
    },
    fileChange(event){
      var reader = new FileReader();
      if (event.target.files != null){
        reader.readAsDataURL(event.target.files[0]);
        reader.onload = ()=> {
          this.saveHttp("cheats/attachments/save", { id: this.mailfake.data.id, filename: event.target.files[0].name, content: reader.result }).then((result) => {
            if ( result !== null ){
              this.mailfake.showUpload = false;
              this.loadDataMailfake(this.mailfake.data.id);
            }
          })
        }
      } else {
        reader.readAsDataURL(event.dataTransfer.files[0]);
        reader.onload = ()=> {
          this.saveHttp("cheats/attachments/save", { id: this.mailfake.data.id, filename: event.dataTransfer.files[0].name, content: reader.result }).then((result) => {
            if ( result !== null ){
              this.mailfake.showUpload = false;
              this.loadDataMailfake(this.mailfake.data.id);
            }
          })
        }
      }
    },
    deleteAttachments(filename){
      this.deleteHttp("cheats/attachments/delete", { id: this.mailfake.data.id, filename: filename }).then((result) => {
        if ( result !== null ) this.loadDataMailfake(this.mailfake.data.id);
      })
    },
    saveDataMailfake(){
      this.saveHttp("cheats/send", this.mailfake.data).then((result) => {
        if ( result !== null ) this.loadDataMailfake(0);
      })
    },
  }
}
</script>