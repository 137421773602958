<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" size="lg" :title="'Monitoring - ' + data.monitoring.id" scrollable @hidden="closeForm" @close="closeForm">
      <b-table-simple responsive>
        <b-thead head-variant="light">
          <b-tr>
            <b-th style="width: 25%">Parametro</b-th>
            <b-th>Valore</b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr>
            <b-td>Monitorato</b-td>
            <b-td>{{ data.monitoring.monitored }}</b-td>
          </b-tr>
          <b-tr>
            <b-td>Monitorante</b-td>
            <b-td>{{ data.monitoring.monitoring }}</b-td>
          </b-tr>
          <b-tr>
            <b-td>Norme</b-td>
            <b-td>{{ data.monitoring.standards }}</b-td>
          </b-tr>
          <b-tr>
            <b-td>Ruolo</b-td>
            <b-td>{{ data.monitoring.role }}</b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
      <b-table responsive :fields="fields" :items="data.questions">
        <template v-slot:cell(question)="row">
          <div>
          {{ row.item.question }}
          </div>
          <b-button-group size="sm" class="mt-2">
            <b-button :variant="row.item.evaluation === null ? 'warning': ''" @click="row.item.evaluation = null">Non valutato</b-button>
            <b-button :variant="row.item.evaluation === true ? 'success': ''" @click="row.item.evaluation = true">Positivo evaluation</b-button>
            <b-button :variant="row.item.evaluation === false ? 'danger': ''" @click="row.item.evaluation = false">Negativo evaluation</b-button>
          </b-button-group>
          <b-form-textarea v-show="row.item.evaluation === false" v-model="row.item.note" class="mt-2" rows="2" max-rows="6"/>
        </template>
      </b-table>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="success" @click="saveData">Salva</b-btn>
        </div>
      </template>           
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "checklistmonitoring",
  data() {
    return {
      show: false,
      data: null,
      fields: [{
        key: 'question',
        label: "Question",
      }]
    }
  },
  methods: {
    loadData( id ){
      this.openHttp("checklists/monitoring", { id: id }).then((result) => {
        this.data = result;
        this.show = true;
      })
    },
    saveData(){
      this.saveHttp("checklists/monitoring/save", this.data.questions).then((result) => {
        if ( result !== true ) this.closeForm();
      })
    },
    closeForm(){
      this.show = false;
      this.data = null;
      this.$emit('update');
    },
  },
}
</script>