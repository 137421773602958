<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" size="xl" :title="'Log - ' + data.auditlog.id" @hidden="closeForm()" @close="closeForm()">
      <b-row>
        <b-col lg="4">
          <b-form-group label="Azienda">
            <b-form-input v-model="data.auditlog.company"/>
          </b-form-group>
        </b-col>
        <b-col lg="2">
          <b-form-group label="Ente">
            <b-form-input v-model="data.auditlog.certificationbody"/>
          </b-form-group>
        </b-col>
        <b-col lg="2">
          <b-form-group label="Data report">
            <b-form-input v-model="data.auditlog.datereport" type="date"/>
          </b-form-group>
        </b-col>
        <b-col lg="2">
          <b-form-group>
            <audit ref="audit"/>
            <label>Audit</label> - <b-link v-show="data.auditlog.audits_id > 0" @click="$refs.audit.loadData(data.auditlog.audits_id)"> Apri</b-link>
            <b-form-input :value="data.auditlog.audits_id"/>
          </b-form-group>
        </b-col>
        <b-col lg="2">
          <b-form-group label="Sotto accreditamento">
            <b-form-select v-model="data.auditlog.underaccreditation" :options="underAccreditationCombo" value-field="id" text-field="val"/>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="4">
          <b-form-group label="Norme">
            <multiselect v-model="standardsSupport" :options="data.standards" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="data.auditlog.standards = updateCombo(standardsSupport)"/>
          </b-form-group>
        </b-col>
        <b-col lg="2">
          <b-form-group label="Fase">
            <b-form-select v-model="data.auditlog.contractsphases_id" :options="data.contractsphases" value-field="id" text-field="val"/>
          </b-form-group>
        </b-col>
        <b-col lg="2">
          <b-form-group label="Ruolo">
            <b-form-select v-model="data.auditlog.usersroles_id" :options="data.usersroles" value-field="id" text-field="val"/>
          </b-form-group>
        </b-col>
        <b-col lg="2">
          <b-form-group label="Giorni uomo">
            <b-form-input v-model="data.auditlog.manday" min="0" step="0.0001" type="number"/>
          </b-form-group>
        </b-col>
      </b-row>
      <b-form-group label="IAFs">
        <multiselect v-model="nacesiafcodesSupport" :options="data.nacesiafcodes" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="data.auditlog.nacesiafcodes = updateCombo(nacesiafcodesSupport)"/>
      </b-form-group>
      <b-form-group label="Enti">
        <multiselect v-model="certificationbodiesSupport" :options="data.certificationbodies" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="data.auditlog.certificationbodies = updateCombo(certificationbodiesSupport)"/>
      </b-form-group>
      <b-form-group label="Date">
        <b-btn variant="primary" size="sm" @click="addDate()">Aggiungi</b-btn>
        <b-row class="mt-3">
          <b-col lg="3" class="mb-1" v-for="(item, index) in data.auditlog.dates" :key="index">
            <b-input-group size="sm">
              <b-form-input type="date" :value="item" @change="changeDate(index, $event)"/>
              <template #append>
                <b-btn variant="danger" @click="deleteDate(index)">Elimina</b-btn>
              </template>
            </b-input-group>
          </b-col>
        </b-row>
      </b-form-group>
      <b-form-group label="Scopo">
        <b-btn variant="primary" size="sm" @click="data.auditlog.languages.push({id: null, scope: null, note: null})">Aggiungi</b-btn>
        <b-table responsive class="mt-2" :items="data.auditlog.languages" :fields="fields.languages">
          <template v-slot:cell(id)="row">
            <b-form-select v-model="row.item.id" :options="data.languages" value-field="id" text-field="val"/>
          </template>
          <template v-slot:cell(scope)="row">
            <b-form-textarea v-model="row.item.scope" rows="2"/>
          </template>
          <template v-slot:cell(note)="row">
            <b-form-textarea v-model="row.item.note" rows="2"/>
          </template>
          <template v-slot:cell(delete)="row">
            <b-btn variant="danger" size="sm" @click="data.auditlog.languages.splice(row.index, 1)">Elimina</b-btn>
          </template>
        </b-table>
      </b-form-group>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="primary" v-show="data.auditlog.id > 0" class="mr-2" @click="loadData(data.auditlog.users_id, data.auditlog.id)">Aggiorna</b-btn>
          <b-btn variant="success" class="mr-2" @click="saveData()">Salva</b-btn>
          <b-btn variant="danger" v-show="data.auditlog.id > 0" @click="deleteData()">Elimina</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "curriculumauditlog",
  data() {
    return {
      show: false,
      data: null,
      standardsSupport: [],
      nacesiafcodesSupport: [],
      certificationbodiesSupport: [],
      underAccreditationCombo: [{
        id: false,
        val: "Non presenziato"
      },{
        id: true,
        val: "Sotto accreditamento"
      }],
      fields: {
        languages: [{
          key: "id",
          label: "Lingua"
        },{
          key: "scope",
          label: "Scopo"
        },{
          key: "note",
          label: "Note"
        },{
          key: "delete",
          label: ""
        }]
      }
    }
  },
  methods: {
    loadData(usersId, id){
      this.openHttp("curricula/auditlog", { id: id, users_id: usersId }).then((result) => {
        this.standardsSupport = this.convertCombo(result.standards, result.auditlog.standards);
        this.nacesiafcodesSupport = this.convertCombo(result.nacesiafcodes, result.auditlog.nacesiafcodes);
        this.certificationbodiesSupport = this.convertCombo(result.certificationbodies, result.auditlog.certificationbodies);
        this.data = result;
        this.show = true;
      })
    },
    addDate(){
      let d = new Date();
      this.data.auditlog.dates.push([d.getFullYear(), d.getMonth() + 1, d.getDate()].map(n => n < 10 ? `0${n}` : `${n}`).join('-'));
    },
    changeDate(index, value){
      this.data.auditlog.dates[index] = value;
    },
    deleteDate(index){
      this.data.auditlog.dates.splice(index, 1);
    },
    saveData(){
      this.saveHttp("curricula/auditlog/save", this.data.auditlog).then((result) => {
        if (result !== null) this.closeForm(true);
      })
    },
    deleteData(){
      this.deleteHttp("curricula/auditlog/delete", { id: this.data.auditlog.id }).then((result) => {
        if (result !== null) this.closeForm(true);
      })
    },
    closeForm(reload = false){
      this.data = null;
      this.show = false;
      if (reload === true) this.$emit("update");
    }
  }
}
</script>