<template>
  <div>
    <company ref="company"/>
    <b-row>
      <b-col lg="2">
        <b-form-group label="ID">
          <b-form-input type="number" v-model="id"/>
        </b-form-group>
      </b-col>
      <b-col lg="2">
        <b-form-group label="ID">
          <b-btn variant="primary" @click="loadData()">Carica</b-btn>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <div>
          <b-link @click="$refs.company.loadData(data.auditfix.companies_id)">{{ data.auditfix.colcompanyname }}</b-link>
        </div>
        <div>
          {{ data.auditfix }}
        </div>
      </b-col>
    </b-row>
    <h4>Audits Filtrati</h4>
    <b-row>
      <b-col>
        <ul>
          <li v-for="(item, index) of data.auditsfiltered" :key="index">{{ item }}
          <b-link @click="attachToAudit(item.contracts_id, item.id)">Attacca</b-link>
          </li>
        </ul>
      </b-col>
    </b-row>
    <h4>Audits</h4>
    <b-row>
      <b-col>
        <ul>
          <li v-for="(item, index) of data.audits" :key="index">{{ item }}
          <b-link @click="attachToAudit(item.contracts_id, item.id)">Attacca</b-link>
          </li>
        </ul>
      </b-col>
    </b-row>
    <h4>Contratti</h4>
    <ul>
      <li v-for="(item, index) of data.contracts" :key="index">{{ item }}
        <b-link @click="attachToContract(item.id)">Attacca</b-link>
        <b-link @click="deleteDataContract(item.id)">Elimina</b-link>
      </li>
    </ul>
    <b-row>
      <b-col>
        <b-btn variant="primary" class="mr-2" @click="nextData()">Prossimo</b-btn>
        <b-btn variant="danger" class="mr-2" @click="deleteData(data.auditfix.id)">Elimina</b-btn>
      </b-col>
    </b-row>
  </div>
</template>

<script>

export default {
  name: "zzz2",
  data() {
    return {
      id: 0,
      data: null
    }
  },
  computed: {
    winHeight (){
      return this.$store.getters.getWinHeight;
    },
  },
  created(){
    this.loadData();
  },
  methods: {
    loadData(){
      this.openHttp("zzz2/", { id: this.id }).then((result) => {
        this.data = result;
      })
    },
    nextData(){
      this.id = this.data.auditfix.id + 1;
      this.loadData();
    },
    attachToContract(contracts_id){
      this.saveHttp("zzz2/attach", { id: this.data.auditfix.id, audits_id: null, contracts_id: contracts_id }).then((result) => {
        if (result !== null) {
          this.nextData();
        }
      })
    },
    attachToAudit(contracts_id, audits_id){
      this.saveHttp("zzz2/attach", { id: this.data.auditfix.id, audits_id: audits_id, contracts_id: contracts_id }).then((result) => {
        if (result !== null) {
          this.nextData();
        }
      })
    },
    deleteData(id){
      this.deleteHttp("zzz2/delete", { id: id }).then((result) => {
        if (result !== null) {
          this.id = this.data.auditfix.id + 1;
          this.loadData();
        }
      })
    },
    deleteDataContract(id){
      this.deleteHttp("zzz2/deletecontract", { id: id }).then((result) => {
        if (result !== null) {
          this.loadData();
        }
      })
    }
  }
}
</script>
