<template>
  <div>
    <b-modal v-model="show" title="Reset Password" no-close-on-esc no-close-on-backdrop hide-header-close hide-footer>
      <b-form>
        <b-form-group label="Mail">
          <b-form-input type="email" v-model="mail"/>
        </b-form-group>
        <b-btn variant="primary" @click="resetData()">Reset</b-btn>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "resetmail",
  data() {
    return {
      show: true,
      mail: null,
    }
  },
  methods: {
    resetData(){
      this.openHttp("reset/mail", { mail: this.mail } ).then((result) => {
        if (result !== null) {
          this.$bvToast.toast("Mail reset sent", { title: "ERP", variant: "primary", appendToast: true });
          this.show = false;
          var self = this;
          setTimeout(function() {
            self.$router.push("/login");
          }, 3000);
        }
      })
    }
  }
}
</script>