<template>
    <div>
        <b-modal v-if="data != null" v-model="show" size="lg" :title="'Step - ' + data.step.certificationbody + ' - ' + data.step.standard" scrollable @hidden="closeForm" @close="closeForm">
            <b-table :sticky-header="(winHeight - 395) + 'px'" :items="data.step.auditsphases" :fields="fields">
                <template v-slot:cell(standardspoints)="row">
                    <b-form-checkbox-group v-model="row.item.standardspoints">
                        <b-form-checkbox v-for="(item, index) of data.standardspoints" :key="index" :value="item.id">{{ item.val }}</b-form-checkbox>
                    </b-form-checkbox-group>
                </template>
                <template v-slot:cell(select)="row">
                    <b-btn variant="info" size="sm" class="mr-2" @click="row.item.standardspoints = []">Nessuno</b-btn>
                    <b-btn variant="info" size="sm" @click="row.item.standardspoints = data.standardspoints.map(x => x.id)">Tutti</b-btn>
                </template>
            </b-table>
            <template v-slot:modal-footer>
                <div class="w-100">
                    <b-btn variant="success" @click="saveData">Salva</b-btn>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
export default {
    name: "step",
    data() {
        return {
            show: false,
            data: null,
            fields: [{
                key: "phase",
                label: "Punto",
                thStyle: {
                    width: '20%'
                },
            },{
                key: 'standardspoints',
                label: 'Punti',
                thStyle: {
                    width: '70%'
                },
            },{
                key: 'select',
                label: '',
                thStyle: {
                    width: '10%'
                },
            }],
        }
    },
    computed: {
        winHeight (){
            return this.$store.getters.getWinHeight;
        },
    },
    methods: {
        loadData( certificationbodies_id, standards_id ){
            this.openHttp("standards/step", { certificationbodies_id: certificationbodies_id, standards_id: standards_id }).then((result) => {
                this.data = result;
                this.show = true;
            })
        },
        saveData(){
            this.saveHttp("standards/step/save", this.data.step).then((result) => {
                if (result !== null) this.closeForm();
            })
        },
        closeForm(){
            this.show = false;
            this.data = null;
            this.$emit('update');
        },
    },
}
</script>