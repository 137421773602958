<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" :title="'Grafico - ' + data.chart.id" size="xl" scrollable @hidden="closeForm()" @close="closeForm()">
      <b-row>
        <b-col lg="6">
          <b-form-group label="Grafico">
            <b-form-input v-model="data.chart.chartname"/>
          </b-form-group>
        </b-col>
        <b-col lg="2">
          <b-form-group label="Altezza">
            <b-form-input type="number" v-model="data.chart.heightgo"/>
          </b-form-group>
        </b-col>
        <b-col lg="2">
          <b-form-group label="Larghezza">
            <b-form-input type="number" v-model="data.chart.widthgo"/>
          </b-form-group>
        </b-col>
        <b-col lg="2">
          <b-form-group label="Tipo">
            <b-form-input v-model="data.chart.typego"/>
          </b-form-group>
        </b-col>
      </b-row>
      <b-form-group label="Utenti">
        <multiselect v-model="usersSupport" :options="data.users" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="data.chart.users = updateCombo(usersSupport)"/>
      </b-form-group>
      <b-form-group label="Go">
        <b-form-textarea v-model="data.chart.sqlgo" rows="10" max-rows="15"/>
      </b-form-group>
      <b-form-group label="Test data" v-if="test != null">
        {{ test }}
      </b-form-group>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="primary" class="mr-2" v-if="data.chart.id > 0" @click="loadData(data.chart.id)">Refresh</b-btn>
          <b-btn variant="success" class="mr-2" @click="saveData()">Salva</b-btn>
          <b-btn variant="danger" class="mr-2" v-show="data.chart.id > 0" @click="deleteData()">Elimina</b-btn>
          <b-btn variant="warning" class="mr-2" v-show="data.chart.id > 0" @click="data.chart.id = 0">Duplica</b-btn>
          <b-btn variant="info" @click="testData()">Test</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>

export default {
  name: "userschart",
  data() {
    return {
      data: null,
      show: false,
      usersSupport: [],
      test: null
    }
  },
  methods: {
    loadData( id ){
      this.test = null;
      this.openHttp("users/chart", { id: id }).then((result) => {
        this.usersSupport = this.convertCombo(result.users, result.chart.users);
        this.data = result;
        this.show = true;
      })
    },
    saveData(){
      this.saveHttp("users/chart/save", this.data.chart).then((result) => {
        if ( result !== null ) this.loadData(result);
      })
    },
    deleteData(){
      this.deleteHttp("users/chart/delete", { id: this.data.chart.id }).then((result) => {
        if ( result !== null ) this.closeForm();
      })
    },
    testData(){
      this.openHttp("users/chart/test", { test: this.data.chart.sqlgo }).then((result) => {
        this.test = result;
      })
    },
    closeForm(){
      this.show = false;
      this.data = null;
      this.$emit('update');
    },
  }
}
</script>