<template>
    <div>
        <b-modal v-if="data !== null" v-model="show" :title="'Preventivo - ' + data.quote.id" size="lg" @hidden="closeForm()" @close="closeForm()">
            <b-row>
                <b-col lg="4">
                    <b-form-group label="Azienda">
                        <b-form-input :value="data.quote.company"/>
                    </b-form-group>
                </b-col>
                <b-col lg="4">
                    <b-form-group label="Ente">
                        <b-form-input :value="data.quote.certificationbody"/>
                    </b-form-group>                    
                </b-col>
            </b-row>
            <b-row>
                <b-col lg="12">
                    <b-form-group label="Steps">
                        <b-table no-border-collapse :items="data.steps" :fields="fields"/>
                    </b-form-group>  
                </b-col>
            </b-row>
            <b-row>
                <b-col lg="12">
                    <b-form-group label="Contratto">
                        <b-form-select v-model="data.quote.contracts_id" :options="addNullCombo(data.contracts, 'Nessuno')" value-field="id" text-field="val"/>
                    </b-form-group>
                </b-col>
            </b-row>
            <template v-slot:modal-footer>
                <div class="w-100">
                    <b-btn variant="success" class="mr-2" @click="saveData">Salva</b-btn>
                    <b-btn variant="primary" @click="prepareData">Prepara</b-btn>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
export default {
    name: "contractquote",
    data() {
        return {
            show: false,
            data: null,
            fields: [{
                key: 'stepdate',
                label: "Date",
                formatter: value => {
                    if (value == null) return null;
                    var d = new Date(value);
                    return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
                }
            },{
                key: 'description',
                label: "Servizio",
            },{
                key: 'quotation',
                label: 'Importo',
            }]
        }
    },
    methods: {
        loadData( id ){
            this.openHttp("contracts/quote", { id: id }).then((result) => {
                this.data = result;
                this.show = true;
            })
        },
        saveData(){
            this.saveHttp("contracts/quote/save", this.data.quote).then((result) => {
                if ( result !== null ) this.closeForm();
            })
        },
        prepareData(){
            this.saveHttp("contracts/quote/prepare", { id: this.data.quote.id }).then((result) => {
                if ( result !== null ) this.closeForm();
            })
        },
        closeForm(){
            this.show = false;
            this.data = null;
            this.$emit('update');
        },
    },
}
</script>