<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" size="lg" :title="'Contatto - ' + data.action.id" scrollable @hidden="closeForm" @close="closeForm">
      <company ref="company" @update="loadData(data.action.id)"/>
      <mail ref="mail"/>
      <b-card no-body>
        <b-tabs card>
          <b-tab title="Contatto" active>
            <b-row>
              <b-col lg="4">
                <b-form-group label="Campagna">
                  <b-form-input :value="data.action.campaign"/>
                </b-form-group>
              </b-col>
              <b-col lg="8">
                <b-form-group>
                  <label><b-link @click="$refs.company.loadData(data.action.companies_id)">Azienda</b-link></label>
                  <b-form-input :value="data.action.company"/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="3">
                <b-form-group label="Nome">
                  <b-form-input :value="data.action.fullname"/>
                </b-form-group>
              </b-col>
              <b-col lg="3">
                <b-form-group label="Contatto">
                  <b-form-input :value="data.action.contact"/>
                </b-form-group>
              </b-col>
              <b-col lg="6">
                <b-form-group label="Mansione">
                  <b-form-input :value="data.action.job"/>
                </b-form-group>
              </b-col>
            </b-row>
          </b-tab>
          <b-tab title="Mails">
            <b-btn variant="info" @click="prepareMail()" size="sm">Carica</b-btn>
            <b-modal v-model="mail.show" title="Pianifica">
              <b-form-group label="Casella">
                <b-form-select v-model="mail.filters.mailboxes_id" :options="mail.filtersMenu.mailboxes" value-field="id" text-field="val"/>
              </b-form-group>
              <b-form-group label="Template">
                <b-form-select v-model="mail.filters.marketingtemplates_id" :options="mail.filtersMenu.marketingtemplates" value-field="id" text-field="val"/>
              </b-form-group>
              <template v-slot:modal-footer>
                <div class="w-100">
                  <b-btn variant="primary" @click="saveMail()">Salva</b-btn>
                </div>
              </template>
            </b-modal>
            <b-table no-border-collapse :sticky-header="(winHeight - 420) + 'px'" responsive class="mt-2" :tbody-tr-class="rowClass" :items="data.mails" :fields="fields.mails">
              <template v-slot:cell(mailboxesmails_id)="row">
                <b-link v-if="row.value > 0" @click="$refs.mail.loadData(row.value)">Mail</b-link>
              </template>
            </b-table>
          </b-tab>
          <b-tab title="Notes">
            <b-table no-border-collapse :sticky-header="(winHeight - 420) + 'px'" responsive :items="data.notes" :fields="fields.notes"/>
          </b-tab>
          <b-tab title="Storia">
            <b-table no-border-collapse :sticky-header="(winHeight - 420) + 'px'" responsive :items="data.kanban" :fields="fields.kanban" />
          </b-tab>
        </b-tabs>
      </b-card>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="success" class="mr-2" @click="saveData()">Salva</b-btn>
          <b-btn variant="danger" v-show="data.action.id > 0" @click="deleteData()">Elimina</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "marketingaction",
  data() {
    return {
      show: false,
      data: null,
      actions: {
        isBusy: true,
        search: {
          page: 1,
          filters: {
            marketingcampaigns_id: null
          },
          sortby: "company",
          sortdesc: false,
        }
      },
      fields: {
        mails: [{
          key: 'marketingtemplate',
          label: "Template",
        },{
          key: 'mailbox',
          label: "Casella",
        },{
          key: 'datetime',
          label: "Invio",
          sortable: true
        },{
          key: 'success',
          label: "Esito",
        },{
          key: 'mailboxesmails_id',
          label: "Mail",
        }],
        notes: [{
          key: 'datetime',
          label: "Data",
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            const today = new Date();
            var d = new Date(value);
            if ( d.getDate() == today.getDate() && d.getMonth() == today.getMonth() && d.getFullYear() == today.getFullYear()){
              return [ d.getHours(), d.getMinutes() ].map(n => n < 10 ? `0${n}` : `${n}`).join(':');
            } else {
              return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
            }
          }
        },{
          key: 'fullname',
          label: "Persona",
        },{
          key: 'deadline',
          label: "Scadenza"
        },{
          key: "note",
          label: "Note"
        },{
          key: "donedatetime",
          label: "Stato",
          formatter: value => {
            return value === null ? "Da fare" : "Completata";
          }
        }],
        kanban: [{
          key: 'datetime',
          label: "Data",
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            const today = new Date();
            var d = new Date(value);
            if ( d.getDate() == today.getDate() && d.getMonth() == today.getMonth() && d.getFullYear() == today.getFullYear()){
              return [ d.getHours(), d.getMinutes() ].map(n => n < 10 ? `0${n}` : `${n}`).join(':');
            } else {
              return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
            }
          }
        },{
          key: "status",
          label: "Stato",
        },{
          key: 'fullname',
          label: "Persona",
        }]
      },
      mail: {
        show: false,
        filtersMenu: {
          mailboxes: [],
          marketingtemplates: []
        },
        filters: {
          marketingactions_id: null,
          mailboxes_id: null,
          marketingtemplates_id: null
        }
      }
    }
  },
  computed: {
    winHeight (){
      return this.$store.getters.getWinHeight;
    },
    rowsMails() {
      return this.data.mails.length;
    }
  },
  methods: {
    rowClass( item ){
      if (item !== null) return item.read === true ? "table-success" : "";
    },
    loadData(id){
      this.openHttp("marketing/action", { id: id }).then((result) => {
        this.data = result;
        this.show = true;
      })
    },
    saveData(){
      this.saveHttp("marketing/action/save", this.data.action).then((result) => {
        if (result !== null) this.loadData(result);
      })
    },
    deleteData(){
      this.deleteHttp("marketing/action/delete", { id: this.data.action.id }).then((result) => {
        if ( result !== null) this.closeForm();
      })
    },

    prepareMail(){
      this.openHttp("marketing/action/preparemail", null).then((result) => {
        this.mail.filtersMenu = result;
        this.mail.filters.mailboxes_id = null;
        this.mail.filters.marketingtemplates_id = null;
        this.mail.show = true;
      })
    },
    saveMail(){
      this.mail.show = false;
      this.mail.filters.marketingactions_id = this.data.action.id;
      this.saveHttp("marketing/action/savemail", this.mail.filters).then((result) => {
        if (result !== null) this.loadData(this.data.action.id);
      })
    },







    
    deleteAction(id){
      console.log(id);
      /*
      this.deleteHttp("marketing/campaign/maildelete", { id: id }).then((result) => {
        if (result !== null) this.loadData(this.data.campaign.id);
      })
      */
    },

    closeForm(){
      this.show = false;
      this.data = null;
      this.$emit('update');
    },
  },
}
</script>