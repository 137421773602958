<template>
    <div>
        <communication ref="communication" @update="loadData"/>
        <b-modal v-model="showFilter" title="Filtra">
            <b-form-group label="Tipologia">
                <b-form-select v-model="tempFilter.type" :options="filtersMenu.types" value-field="id" text-field="val"/>
            </b-form-group>
            <b-form-group label="Stato" v-show="tempFilter.type === 1">
                <b-form-select v-model="tempFilter.read" :options="filtersMenu.read" value-field="id" text-field="val"/>
            </b-form-group>
            <b-form-group label="Da">
                <b-form-input type="date" v-model="tempFilter.from"/>
            </b-form-group>
            <b-form-group label="A">
                <b-form-input type="date" v-model="tempFilter.to"/>
            </b-form-group>
            <template v-slot:modal-footer>
                <div class="w-100">
                    <b-btn variant="primary" class="mr-2" @click="filterSet">Filtra</b-btn>
                    <b-btn variant="warning" @click="filterReset">Reset</b-btn>
                </div>
            </template>
        </b-modal>
        <b-btn class="mr-2" variant="primary" @click="loadData">Aggiorna</b-btn>
        <b-btn class="mr-2" variant="secondary" @click="showFilter = true">Filtra</b-btn>
        <b-btn variant="success" @click="$refs.communication.loadData(0)">Nuovo</b-btn>
        <b-table no-border-collapse :sticky-header="(winHeight - 205) + 'px'" hover responsive show-empty mode="remote" class="mt-3" :items="items.data" :fields="fields" :busy="isBusy" :no-local-sorting="true" :sort-by.sync="search.sortby" :sort-desc.sync="search.sortdesc" @sort-changed="sortingChange" @row-clicked="$refs.communication.loadData($event.id)">
            <template v-slot:table-busy>
                <div class="text-center text-primary">
                    <b-spinner class="align-middle"/>
                </div>
            </template>
            <template v-slot:empty>Non ci sono risultati</template>
        </b-table>
        <tablebottom :busy="isBusy" :currentpage="search.page" :total="items.total" @updateCurrentPage="search.page = $event" @loadData="loadData"/>
    </div>
</template>

<script>
export default {
    name: "communications",
    data() {
        return {
            isBusy: true,
            showFilter: false,
            search: {
                page: 1,
                filters: {
                    type: 1,
                    read: false,
                    from: null,
                    to: null,
                },
                sortby: "date",
                sortdesc: true
            },
            tempFilter: {
                type: 1,
                read: false,
                from: null,
                to: null,
            },
            filtersMenu: {
                types:[{
                    id: 1,
                    val: "Ricevute",
                },{
                    id: 2,
                    val: "Inviate",
                },],
                read:[{
                    id: false,
                    val: "Non lette",
                },{
                    id: true,
                    val: "Lette",
                },],
            },
            fields: [{
                key: 'date',
                label: 'Data',
                sortable: true,
                formatter: value => {
                if (value == null) return null;
                var d = new Date(value);
                return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
                },
            },{
                key: 'sender',
                label: 'Mittente',
                sortable: true,
            },{
                key: 'subject',
                label: 'Oggetto',
            },{
                key: 'notread',
                label: 'Non letto',
            },{
                key: 'read',
                label: 'Letto',
            }],
            items: {
                total: 0,
                data: [],
            }
        }
    },
    computed: {
        winHeight (){
            return this.$store.getters.getWinHeight;
        },
    },
    created(){
        this.loadData();
    },
    methods: {
        loadData(){
            this.openHttp("communications/", this.search).then((result) => {
                this.items = result;
                this.isBusy = false;
            })
        },
        filterSet(){
            this.showFilter = false;
            this.search.page = 1;            
            this.search.filters = this.tempFilter;
            this.loadData();
        },
        filterReset(){
            this.tempFilter = {
                type: 1,
                read: false,
                from: null,
                to: null,
            }
            this.filterSet();
        },
        sortingChange(ctx) {
            this.search.sortby = ctx.sortBy;
            this.search.sortdesc = ctx.sortDesc;
            this.loadData();
        }
    }
}
</script>