<template>
  <div>
    <monitoringanswers ref="monitoring" @update="loadData"/>
    <monitoringquestion ref="question" @update="loadData"/>
    <b-card no-body>
      <b-tabs card>
        <b-tab title="Monitoring" active>
          <b-modal v-model="monitorings.showFilter" title="Filters">
            <!--
            <b-form-group label="Standard">
              <b-form-select v-model="monitorings.tempFilter.standards_id" :options="addNullCombo(monitorings.filtersMenu.standards.filter(x => x.certificationbodies.includes(133)))" value-field="id" text-field="val"/>
            </b-form-group>
            <b-form-group label="User">
              <b-form-select v-model="monitorings.tempFilter.users_id" :options="addNullCombo(monitorings.filtersMenu.users.filter(x => x.certificationbodies.includes(133)))" value-field="id" text-field="val"/>
            </b-form-group>
            -->
            <template v-slot:modal-footer>
              <div class="w-100">
                <b-btn variant="primary" class="mr-2" @click="filterSetMonitorings">Filtro</b-btn>
                <b-btn variant="warning" @click="filterResetMonitorings">Reset</b-btn>
              </div>
            </template>
          </b-modal>
          <b-btn class="mr-2" variant="primary" @click="loadMonitorings">Aggiorna</b-btn>
          <b-btn class="mr-2" variant="secondary" @click="filterOpenMonitorings">Filtra</b-btn>
          <b-table no-border-collapse :sticky-header="(winHeight - 305) + 'px'" hover responsive show-empty mode="remote" class="mt-3" :items="monitorings.items.data" :fields="monitorings.fields" :busy="monitorings.isBusy" :no-local-sorting="true" :sort-by.sync="monitorings.search.sortby" :sort-desc.sync="monitorings.search.sortdesc" :tbody-tr-class="rowClass" @sort-changed="sortingChangeMonitorings" @row-clicked="loadMonitoring($event.monitorings_id)">
            <template v-slot:table-busy>
              <div class="text-center text-primary">
                <b-spinner class="align-middle"/>
              </div>
            </template>
            <template v-slot:empty>Non ci sono risultati</template>
          </b-table>
          <tablebottom :busy="monitorings.isBusy" :currentpage="monitorings.search.page" :total="monitorings.items.total" @updateCurrentPage="monitorings.search.page = $event" @loadData="loadMonitorings"/>
        </b-tab>
        <b-tab title="Domande">
          <b-modal v-model="questions.showFilter" title="Filters">
            <b-form-group label="Standard">
              <b-form-select v-model="questions.tempFilter.standards_id" :options="addNullCombo(questions.filtersMenu.standards)" value-field="id" text-field="val"/>
            </b-form-group>
            <b-form-group label="Role">
              <b-form-select v-model="questions.tempFilter.usersroles_id" :options="addNullCombo(questions.filtersMenu.usersroles)" value-field="id" text-field="val"/>
            </b-form-group>
            <template v-slot:modal-footer>
              <div class="w-100">
                <b-btn variant="primary" class="mr-2" @click="filterSetQuestions">Filtro</b-btn>
                <b-btn variant="warning" @click="filterResetQuestions">Reset</b-btn>
              </div>
            </template>
          </b-modal>
          <b-btn class="mr-2" variant="primary" @click="loadQuestions">Aggiorna</b-btn>
          <b-btn class="mr-2" variant="secondary" @click="filterOpenQuestions">Filtra</b-btn>
          <b-btn variant="success" @click="$refs.question.loadData(0)">Nuovo</b-btn>
          <b-table no-border-collapse :sticky-header="(winHeight - 305) + 'px'" responsive show-empty mode="remote" class="mt-3" :items="questions.items.data" :fields="questions.fields" :busy="questions.isBusy" :no-local-sorting="true" :sort-by.sync="questions.search.sortby" :sort-desc.sync="questions.search.sortdesc" @sort-changed="sortingChangeQuestions" @row-clicked="$refs.question.loadData($event.id)">
            <template v-slot:table-busy>
              <div class="text-center text-primary">
                <b-spinner class="align-middle"/>
              </div>
            </template>
            <template v-slot:empty>Non ci sono risultati</template>
          </b-table>
          <tablebottom :busy="questions.isBusy" :currentpage="questions.search.page" :total="questions.items.total" @updateCurrentPage="questions.search.page = $event" @loadData="loadQuestions"/>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import monitoringanswers from './monitoringanswers.vue';
import monitoringquestion from './monitoringquestion.vue';

export default {
  name: "monitorings",
  components : { monitoringanswers, monitoringquestion },
  data() {
    return {
      monitorings: {
        isBusy: true,
        showFilter: false,
        search: {
          page: 1,
          filters: {
            standards_id: null,
            certificationbodies_id: null,
            users_id: null
          },
          sortby: 'date',
          sortdesc: false,
        },
        tempFilter: {
          standards_id: null,
          certificationbodies_id: null,
          users_id: null
        },
        filtersMenu: {
          standards:[],
          certificationbodies: [],
          users_id: []
        },
        fields: [{
          key: "fullname",
          label: "Monitorato",
          sortable: true,
        },{
          key: 'standard',
          label: 'Standard',
          sortable: true,
        },{
          key: 'usersrole',
          label: "Ruolo",
          sortable: true,
        },{
          key: 'date',
          label: 'Data',
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          }
        },{
          key: "fullnamemonitoring",
          label: "Monitorato da",
        }],
        items: {
          total: 0,
          data: []
        }
      },
      questions: {
        isBusy: true,
        showFilter: false,
        search: {
          page: 1,
          filters: {
            standards_id: null,
            certificationbodies_id: null,
            usersroles_id: null
          },
          sortby: 'orderquestion',
          sortdesc: false
        },
        tempFilter: {
          standards_id: null,
          certificationbodies_id: null,
          usersroles_id: null
        },
        filtersMenu: {
          standards:[],
          certificationbodies: [],
          usersroles: [],
        },
        fields: [{
          key: 'orderquestion',
          label: "Ordine",
          sortable: true,
        },{
          key: 'question',
          label: "Domanda",
        },{
          key: 'standards',
          label: 'Standards',
        },{
          key: 'roles',
          label: "Ruoli"
        }],
        items: {
          total: 0,
          data: [],
        }
      },
    }
  },
  computed: {
    winHeight (){
      return this.$store.getters.getWinHeight;
    },
  },
  created(){
    this.loadData();
  },
  methods: {
    rowClass(item, type){
      if (!item || type !== 'row') return "";
      return "table-" + ( item.monitorings_date === null && item.audit_date !== null ? "danger" : ( item.monitorings_date === null && item.audit_date === null ? "warning" : "success"));
    },
    loadData(){
      this.loadMonitorings();
      this.loadQuestions();
    },
    loadMonitorings(){
      this.monitorings.isBusy = true;
      this.openHttp("/monitorings", this.monitorings.search ).then((result) => {
        this.monitorings.isBusy = false;
        this.monitorings.items = result;
      })
    },
    filterOpenMonitorings(){
      this.openHttp("monitorings/filters", {} ).then((result) => {
        this.monitorings.filtersMenu = result;
        this.monitorings.showFilter = true;
      })
    },
    filterSetMonitorings(){
      this.monitorings.showFilter = false;
      this.monitorings.search.filters = this.monitorings.tempFilter;
      this.loadMonitorings();
    },
    filterResetMonitorings(){
      this.monitorings.tempFilter = {
        certificationbodies_id: null,
        standards_id: null,
        users_id: null
      };
      this.filterSetMonitorings();
    },
    sortingChangeMonitorings(ctx) {
      this.monitorings.search.sortby = ctx.sortBy;
      this.monitorings.search.sortdesc = ctx.sortDesc;
      this.loadMonitorings();
    },
    loadMonitoring( id ){
      this.$refs.monitoring.loadData( id );
    },
    loadQuestions(){
      this.questions.isBusy = true;
      this.openHttp("monitorings/questions", this.questions.search ).then((result) => {
        this.questions.items = result;
        this.questions.isBusy = false;
      })
    },
    filterOpenQuestions(){
      this.openHttp("monitorings/filters", {}).then((result) => {
        this.questions.filtersMenu = result;
        this.questions.showFilter = true;
      })
    },
    filterSetQuestions(){
      this.questions.showFilter = false;
      this.questions.search.filters = this.questions.tempFilter;
      this.loadQuestions();
    },
    filterResetQuestions(){
      this.questions.tempFilter = {
        certificationbodies_id: null,
        standards_id: null,
        usersroles_id: null
      };
      this.filterSetQuestions();
    },
    sortingChangeQuestions(ctx) {
      this.questions.search.sort.by = ctx.sortBy;
      this.questions.search.sort.desc = ctx.sortDesc;
      this.loadQuestions();
    }
  }
}
</script>