<template>
    <div>
        <b-modal v-if="data !== null" v-model="show" :title="'Tag - ' + data.tag.id" @hidden="closeForm()" @close="closeForm()">
            <b-form-group label="Tag">
                <b-form-input v-model="data.tag.tag"/>
            </b-form-group>
            <template v-slot:modal-footer>
                <div class="w-100">
                    <b-btn variant="success" class="mr-2" @click="saveData">Salva</b-btn>
                    <b-btn variant="danger" v-show="data.tag.id > 0" @click="deleteData">Elimina</b-btn>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>

export default {
    name: "tag",
    data() {
        return {
            show: false,
            data: null,
        }
    },
    methods: {
        loadData(id){
            this.openHttp("companiessettings/tag", { id: id }).then((result) => {
                this.data = result;
                this.show = true;
            })
        },
        saveData(){
            this.saveHttp("companiessettings/tag/save", this.data.tag).then((result) => {
                if ( result !== null ) this.closeForm();
            })
        },
        deleteData(){
            this.deleteHttp("companiessettings/tag/delete", { id: this.data.tag.id }).then((result) => {
                if ( result !== null ) this.closeForm();
            })
        },
        closeForm(){
            this.show = false;
            this.data = null;
            this.$emit('update');
        }
    },
}
</script>