<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" size="xl" :title="'Curriculum - ' + data.curriculum.id" scrollable @hidden="closeForm()" @close="closeForm()">
      <curriculumlanguege ref="language"  @update="loadData(data.curriculum.id, 0)"/>
      <curriculumcertificate ref="certificate" @update="loadData(data.curriculum.id)"/>
      <curriculumcertificationbody ref="certificationbody" @update="loadData(data.curriculum.id)"/>
      <curriculumeducation ref="education" @update="loadData(data.curriculum.id)"/>
      <curriculumexperience ref="experience" @update="loadData(data.curriculum.id)"/>
      <curriculumconsultancy ref="consultancy" @update="loadData(data.curriculum.id)"/>
      <curriculumauditlog ref="auditlog" @update="loadData(data.curriculum.id)"/>
      <curriculumdocument ref="document" @update="loadData(data.curriculum.id)"/>
      <b-card no-body>
        <b-tabs card>
          <b-tab title="Anagrafica" active>
            <b-row>
              <b-col lg="4">
                <b-form-group label="Nome">
                  <b-form-input v-model="data.curriculum.firstname"/>
                </b-form-group>
              </b-col>
              <b-col lg="4">
                <b-form-group label="Cognome">
                  <b-form-input v-model="data.curriculum.lastname"/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="3">
                <b-form-group label="Luogo di nascita">
                  <b-form-input v-model="data.curriculum.birthplace"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Data di nascita">
                  <b-form-input v-model="data.curriculum.birthdate" type="date"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Codice fiscale">
                  <b-form-input v-model="data.curriculum.taxcode"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Documento">
                  <b-form-input v-model="data.curriculum.personalid"/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="6">
                <b-form-group label="Residenza">
                  <b-form-input v-model="data.curriculum.address"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Stato">
                  <b-form-select v-model="data.curriculum.states_id" :options="data.states" value-field="id" text-field="val"/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="3">
                <b-form-group label="Telefono">
                  <b-form-input v-model="data.curriculum.phone"/>
                </b-form-group>
              </b-col>
            </b-row>
          </b-tab>
          <b-tab title="Enti">
            <b-btn variant="primary" size="sm" @click="$refs.certificationbody.loadData(data.curriculum.id, 0)">Nuovo</b-btn>
            <b-table no-border-collapse :sticky-header="(winHeight - 390) + 'px'" responsive class="mt-2" :items="data.certificationbodies" :fields="fields.certificationbodies" @row-clicked="$refs.certificationbody.loadData(data.curriculum.id, $event.id)"/>
          </b-tab>
          <b-tab title="Lingue">
            <b-btn variant="primary" size="sm" @click="$refs.language.loadData(data.curriculum.id, 0)">Nuovo</b-btn>
            <b-table no-border-collapse :sticky-header="(winHeight - 390) + 'px'" responsive class="mt-2" :items="data.languageslevels" :fields="fields.languages" @row-clicked="$refs.language.loadData(data.curriculum.id, $event.id)"/>
          </b-tab>
          <b-tab :title="'Attestati ' + data.certificates.length">
            <b-btn variant="primary" size="sm" @click="$refs.certificate.loadData(data.curriculum.id, 0)">Nuovo</b-btn>
            <b-table no-border-collapse :sticky-header="(winHeight - 390) + 'px'" responsive class="mt-2" :items="data.certificates" :fields="fields.certificates" @row-clicked="$refs.certificate.loadData(data.curriculum.id, $event.id)">
                <template v-slot:cell(degree)="row">
                  <div v-for="(item, index) of row.value" :key="index">{{ item.lang + ') ' + item.val }}</div>
                </template>
            </b-table>
          </b-tab>
          <b-tab :title="'Educazione ' + data.education.length">
            <b-btn variant="primary" size="sm" @click="$refs.education.loadData(data.curriculum.id, 0)">Nuovo</b-btn>
            <b-table no-border-collapse :sticky-header="(winHeight - 390) + 'px'" responsive class="mt-2" :items="data.education" :fields="fields.education" @row-clicked="$refs.education.loadData(data.curriculum.id, $event.id)">
              <template v-slot:cell(discipline)="row">
                <div v-for="(item, index) of row.value" :key="index">{{ item.lang + ') ' + item.val }}</div>
              </template>
              <template v-slot:cell(title)="row">
                <div v-for="(item, index) of row.value" :key="index">{{ item.lang + ') ' + item.val }}</div>
              </template>
            </b-table>
          </b-tab>
          <b-tab :title="'Esperienze lavorative ' + data.experiences.length">
            <b-btn variant="primary" size="sm" @click="$refs.experience.loadData(data.curriculum.id, 0)">Nuovo</b-btn>
            <b-table no-border-collapse :sticky-header="(winHeight - 390) + 'px'" responsive class="mt-2" :items="data.experiences" :fields="fields.experiences" @row-clicked="$refs.experience.loadData(data.curriculum.id, $event.id)">
              <template v-slot:cell(sector)="row">
                <div v-for="(item, index) of row.value" :key="index">{{ item.lang + ') ' + item.val }}</div>
              </template>
              <template v-slot:cell(responsibilities)="row">
                <div v-for="(item, index) of row.value" :key="index">{{ item.lang + ') ' + item.val }}</div>
              </template>
            </b-table>
          </b-tab>
          <b-tab :title="'Consulenze ' + data.consultancies.length">
            <b-btn variant="primary" size="sm" @click="$refs.consultancy.loadData(data.curriculum.id, 0)">Nuovo</b-btn>
            <b-table no-border-collapse :sticky-header="(winHeight - 390) + 'px'" responsive class="mt-2" :items="data.consultancies" :fields="fields.consultancies" @row-clicked="$refs.consultancy.loadData(data.curriculum.id, $event.id)">
              <template v-slot:cell(sector)="row">
                <div v-for="(item, index) of row.value" :key="index">{{ item.lang + ') ' + item.val }}</div>
              </template>
              <template v-slot:cell(responsibilities)="row">
                <div v-for="(item, index) of row.value" :key="index">{{ item.lang + ') ' + item.val }}</div>
              </template>
            </b-table>
          </b-tab>
          <b-tab :title="'Audit logs ' + data.auditlogs.length">
            <b-btn variant="primary" size="sm" @click="$refs.auditlog.loadData(data.curriculum.id, 0)">Nuovo</b-btn>
            <b-btn variant="info" class="ml-2" size="sm" @click="importLogsData()">Importa</b-btn>
            <b-table no-border-collapse :sticky-header="(winHeight - 390) + 'px'" responsive class="mt-3" :items="data.auditlogs" :fields="fields.auditlogs" @row-clicked="$refs.auditlog.loadData(data.curriculum.id, $event.id)"/>
          </b-tab>
          <b-tab :title="'Errori audit logs ' + data.errorlogs.length">
            <b-table no-border-collapse :sticky-header="(winHeight - 370) + 'px'" responsive :items="data.errorlogs" :fields="fields.errorlogs" @row-clicked="$refs.auditlog.loadData(data.curriculum.id, $event.id)"/>
          </b-tab>
          <b-tab :title="'Documenti ' + data.documents.length">
            <b-btn variant="info" class="mr-2" size="sm" @click="$refs.document.prepareDocuments(data.curriculum.id)">Genera</b-btn>
            <b-table no-border-collapse :sticky-header="(winHeight - 390) + 'px'" :tbody-tr-class="rowClassDocuments" responsive class="mt-3" :items="data.documents" :fields="fields.documents" @row-clicked="$refs.document.loadData('users', data.curriculum.id, $event.id)">
              <template v-slot:cell(id)="row">
                <b-btn variant="primary" size="sm" v-if="row.item.datetime !== null" @click="$refs.document.downloadData(row.item.id)">Scarica</b-btn>
              </template>
            </b-table>
          </b-tab>
        </b-tabs>
      </b-card>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="primary" class="mr-2" @click="loadData(data.curriculum.id)">Aggiorna</b-btn>
          <b-btn variant="success" @click="saveData">Salva</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import curriculumlanguege from './curriculacomponents/curriculumlanguege.vue';
import curriculumcertificate from './curriculacomponents/curriculumcertificate.vue';
import curriculumcertificationbody from './curriculacomponents/curriculumcertificationbody.vue';
import curriculumexperience from './curriculacomponents/curriculumexperience.vue';
import curriculumeducation from './curriculacomponents/curriculumeducation.vue';
import curriculumconsultancy from './curriculacomponents/curriculumconsultancy.vue';
import curriculumauditlog from './curriculacomponents/curriculumauditlog.vue';
import curriculumdocument from './curriculacomponents/curriculumdocument.vue';

export default {
  name: "curriculum",
  components: { curriculumlanguege, curriculumcertificate, curriculumcertificationbody, curriculumexperience, curriculumeducation, curriculumconsultancy, curriculumauditlog, curriculumdocument },
  data() {
    return {
      show: false,
      data: null,
      qualifications: [],
      fields: {
        certificationbodies: [{
          key: 'certificationbody',
          label: 'Ente'
        },{
          key: 'signature1',
          label: 'Firma 1'
        },{
          key: 'user2',
          label: "Approvato da"
        },{
          key: 'signature2',
          label: 'Firma 2'
        }],
        languages: [{
          key: 'val',
          label: 'Lingua',
        },{
          key: 'speak',
          label: 'Parlato',
        },{
          key: 'read',
          label: 'Compreso',
        },{
          key: 'write',
          label: 'Scritto',
        }],
        certificates: [{
          key: 'fromdate',
          label: 'Da',
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          }        
        },{
          key: 'todate',
          label: 'A',
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          }
        },{
          key: 'degree',
          label: 'Certificato / Attestato'
        },{
          key: 'issuer',
          label: 'Rilasciato da'
        },{
          key: 'standards',
          label: 'Norme',
        },{
          key: 'standardsareas',
          label: 'Aree',
        },{
          key: 'duration',
          label: 'Ore',
        },{
          key: 'qualify40',
          label: '40h',
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            return value === true ? "Si" : "No";
          }
        },{
          key: 'iafs',
          label: 'IAFs',
        },{
          key: 'documentscount',
          label: 'Documenti',
        }],
        education: [{
          key: 'fromdate',
          label: 'Da',
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          }        
        },{
          key: 'todate',
          label: 'A',
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          }
        },{
          key: 'school',
          label: 'Scuola',
        },{
          key: 'discipline',
          label: 'Disciplina'
        },{
          key: 'title',
          label: 'Titolo',
        },{
          key: 'standardsareas',
          label: 'Aree',
        },{
          key: "iafs",
          label: "IAFs",
        },{
          key: 'documentscount',
          label: 'Documenti',
        }],
        experiences: [{
          key: 'fromdate',
          label: 'Da',
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          }        
        },{
          key: 'todate',
          label: 'A',
          sortable: true,
          formatter: value => {
            if (value == null) return 'In Corso';
            var d = new Date(value);
            return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          }
        },{
          key: 'company',
          label: 'Azienda',
          sortable: true
        },{
          key: 'sector',
          label: 'Settore'
        },{
          key: "responsibilities",
          label: "Responsabilità"
        },{
          key: "standardsareas",
          label: "Aree",
        },{
          key: 'iafs',
          label: 'IAFs',
        },{
          key: 'documentscount',
          label: 'Documenti',
        }],
        consultancies: [{
          key: "fromdate",
          label: "Da",
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          }        
        },{
          key: "todate",
          label: "A",
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          }
        },{
          key: "company",
          label: "Azienda",
          sortable: true,
        },{
          key: 'sector',
          label: 'Settore',
        },{
          key: 'daysdone',
          label: 'Giornate',
          sortable: true,
        },{
          key: "standardsareas",
          label: "Aree",
        },{
          key: "iafs",
          label: 'IAFs',
        },{
          key: 'responsibilities',
          label: 'Responsibilities',
        },{
          key: 'documentscount',
          label: 'Documenti',
        }],
        auditlogs: [{
          key: 'datereport',
          label: 'Data',
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          }
        },{
          key: 'company',
          label: 'Azienda',
        },{
          key: 'certificationbody',
          label: 'Ente',
        },{
          key: 'manday',
          label: 'gg',
        },{
          key: 'usersrole',
          label: 'Ruolo',
        },{
          key: 'standards',
          label: 'Norme',
        },{
          key: "iafs",
          label: 'IAFs',
        },{
          key: 'phase',
          label: 'Fase',
        },{
          key: "audits_id",
          label: "Audit",
          formatter: value => {
            return value > 0 ? "Si" : "No";
          }
        }],
        errorlogs: [{
          key: 'datereport',
          label: 'Data',
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          }
        },{
          key: 'company',
          label: 'Azienda',
        },{
          key: 'certificationbody',
          label: 'Ente',
        },{
          key: 'dates',
          label: 'Date',
        }],
        documents: [{
          key: 'filename',
          label: 'File',
          sortable: true,
        },{
          key: 'datetime',
          label: 'Aggiornamento',
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-') + " " + [d.getHours(), d.getMinutes(), d.getSeconds()].map(n => n < 10 ? `0${n}` : `${n}`).join(':');
          }
        },{
          key: "locked",
          label: "Bloccato",
          formatter: value => {
            return value === true ? "Si" : "";
          }
        },{
          key: "id",
          label: "",
        }]
      },
    }
  },
  computed: {
    winHeight(){
      return this.$store.getters.getWinHeight;
    },
  },
  methods: {
    rowClassDocuments(item) {
      return item !== null && item.datetime === null ? "table-danger" : "";
    },
    loadData(id){
      this.openHttp("curricula/curriculum", { id: id }).then((result) => {
        this.data = result;
        this.show = true;
      })
    },
    loadQualifications(){
      this.openHttp("curricula/curriculum/qualifications", { id: this.data.curriculum.id }).then((result) => {
        console.log("asd");
        this.qualifications = result;
      })
    },
    importLogsData(){
      this.saveHttp("curricula/curriculum/importlogs", {id: this.data.curriculum.id } ).then((result) => {
        if ( result !== null ) this.loadData( this.data.curriculum.id );
      })
    },
    saveData(){
      this.saveHttp("curricula/curriculum/save", this.data.curriculum ).then((result) => {
        if ( result !== null ) this.loadData( this.data.curriculum.id );
      })
    },
    closeForm(){
      this.show = false;
      this.data = null;
      this.$emit("update");
    }
  },
}
</script>