<template>
  <div>
    <invoice ref="invoice" @update="loadData(true)"/>
    <b-modal v-if="showFilter" v-model="showFilter" title="Filtra">
      <b-form-group label="Id">
        <b-form-input type="number" v-model="tempFilter.id"/>
      </b-form-group>
      <b-form-group label="Codice">
        <b-form-input type="number" v-model="tempFilter.code"/>
      </b-form-group>
      <b-form-group label="Da">
        <b-form-input type="date" v-model="tempFilter.from"/>
      </b-form-group>
      <b-form-group label="A">
        <b-form-input type="date" v-model="tempFilter.to"/>
      </b-form-group>
      <b-form-group label="Azienda">
        <b-form-select v-model="tempFilter.companies_id" :options="addNullCombo(filtersMenu.companies)" value-field="id" text-field="val"/>
      </b-form-group>
      <b-form-group label="Ditta">
        <b-form-select v-model="tempFilter.internalcompanies_id" :options="addNullCombo(filtersMenu.internalcompanies)" value-field="id" text-field="val"/>
      </b-form-group>
      <b-form-group label="Tipologia">
        <b-form-select v-model="tempFilter.invoicestypes_id" :options="addNullCombo(filtersMenu.invoicestypes)" value-field="id" text-field="val"/>
      </b-form-group>
      <b-form-group label="Arretrato">
        <b-form-select v-model="tempFilter.arrear" :options="addNullCombo(arrearCombo)" value-field="id" text-field="val"/>
      </b-form-group>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="primary" class="mr-2" @click="filterSet">Filtra</b-btn>
          <b-btn variant="warning" @click="filterReset">Reset</b-btn>
        </div>
      </template>
    </b-modal>
    <b-btn class="mr-2" variant="primary" @click="loadData">Aggiorna</b-btn>
    <b-btn class="mr-2" variant="secondary" @click="filterOpen">Filtra</b-btn>
    <b-btn variant="success" @click="$refs.invoice.loadData(0)">Nuovo</b-btn>
    <b-table no-border-collapse :sticky-header="(winHeight - 205) + 'px'" hover responsive show-empty mode="remote" class="mt-3" :items="itemStyled" :fields="fields" :busy="isBusy" :no-local-sorting="true" :sort-by.sync="search.sortby" :sort-desc.sync="search.sortdesc" @sort-changed="sortingChange" @row-clicked="$refs.invoice.loadData($event.id)">
      <template v-slot:table-busy>
        <div class="text-center text-primary">
          <b-spinner class="align-middle"/>
        </div>
      </template>
      <template v-slot:empty>Non ci sono risultati</template>
      <template v-slot:cell(services)="row">
        <div v-for="item in row.item.services" :key="item">{{ item }}</div>
      </template>
    </b-table>
    <tablebottom :busy="isBusy" :currentpage="search.page" :total="items.total" @updateCurrentPage="search.page = $event" @loadData="loadData" :extraText="'- Totale: ' + items.total_total + ' € - Arretrato: ' + items.total_arrear + ' €'"/>
  </div>
</template>

<script>
export default {
  name: "invoices",
  data() {
    return {
      isBusy: true,
      showFilter: false,
      search: {
        page: 1,
        filters: {
          id: null,
          code: null,
          from: null,
          to: null,
          companies_id: null,
          internalcompanies_id: null,
          invoicestypes_id: null,
          arrear: true,
        },
        sortby: null,
        sortdesc: false,
      },
      tempFilter: {
        id: null,
        code: null,
        from: null,
        to: null,
        companies_id: null,
        internalcompanies_id: null,
        invoicestypes_id: null,
        arrear: true,
      },
      filtersMenu: {
        companies:[],
        internalcompanies:[],
      },
      fields: [{
        key: 'code',
        label: "Progressivo",
        sortable: true,
      },{
        key: 'date',
        label: 'Data',
        sortable: true,
        formatter: value => {
          if (value == null) return null;
          var d = new Date(value);
          return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
        },
      },{
        key: 'company',
        label: 'Azienda',
        sortable: true,
      },{
        key: 'internalcompany',
        label: 'Ditta',
        sortable: true,
      },{
        key: 'invoicestype',
        label: "Tipo",
        sortable: true,
      },{
        key: 'services',
        label: 'Servizi',
      },{
        key: 'orders',
        label: 'Commesse',
      },{
        key: 'deaddate',
        label: 'Scadenza',
        sortable: true,
        formatter: value => {
          if (value == null) return null;
          var d = new Date(value);
          return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
        },
      },{
        key: 'total',
        label: 'Totale',
      },{
        key: 'arrear',
        label: 'Arretrato',
      }],
      items: {
        total: 0,
        data: [],
        total_total: 0,
        total_arrear: 0,
      },
      arrearCombo: [{
        id: true,
        val: "Arretrato"
      },{
        id: false,
        val: "Pagate 100%"
      }]
    }
  },
  computed: {
    winHeight (){
      return this.$store.getters.getWinHeight;
    },
    itemStyled(){
      let tempItems = this.items.data;
      for ( let item of tempItems ){
        item._cellVariants = {
          deaddate: item.arrear != 0 ? "danger" : "",
          total: item.arrear != 0 ? "danger" : "",
          arrear: item.arrear != 0 ? "danger" : "",
        }
      }
      return tempItems;
    },
  },
  created(){
    this.loadData();
  },
  methods: {
    loadData( notScroll = false ){
      if (notScroll == false) this.isBusy = true;
      this.openHttp("invoices/", this.search).then((result) => {
        this.isBusy = false;
        this.items = result;
      })
    },
    filterOpen(){
      this.openHttp("invoices/filters", null).then((result) => {
        this.filtersMenu = result;
        this.showFilter = true;
      })
    },
    filterSet(){
      this.showFilter = false;
      this.search.page = 1;
      this.search.filters = this.tempFilter;
      this.loadData();
    },
    filterReset(){
      this.tempFilter = {
        id: null,
        code: null,
        from: null,
        to: null,
        companies_id: null,
        internalcompanies_id: null,
        invoicestypes_id: null,
        arrear: true,
      }
      this.filterSet();
    },
    sortingChange(ctx) {
      this.search.sortby = ctx.sortBy;
      this.search.sortdesc = ctx.sortDesc;
      this.loadData();
    }
  }
}
</script>