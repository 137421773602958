<template>
    <div>
        <b-modal v-model="show" v-if="data !== null" :title="'Imparzialità / Nomina - ' + data.impartiality.id" @hidden="closeForm()" @close="closeForm()">
            <auditsignature ref="signature" @update="loadData(data.audit.id)"/>
            <b-form-group label="Incaricato">
                <b-form-input :value="data.impartiality.fullname"/>
            </b-form-group>
            <b-form-group label="Spunte">
                <b-form-checkbox v-model="data.impartiality.gdpr">GDPR</b-form-checkbox>
                <b-form-checkbox v-model="data.impartiality.noconsultancy3years">No consulenza 3 anni</b-form-checkbox>
                <b-form-checkbox v-model="data.impartiality.nointernalaudit3years">No internal audit 3 anni</b-form-checkbox>
                <b-form-checkbox v-model="data.impartiality.nocontractual3years">No contratti 3 anni</b-form-checkbox>
                <b-form-checkbox v-model="data.impartiality.interestofcompetiting">No conflitti di interesse</b-form-checkbox>
            </b-form-group>
            <b-form-group label="Note conflitto">
                <b-form-textarea v-model="data.impartiality.note" rows="2"/>
            </b-form-group>
            <b-row>
                <b-col lg="4">
                    <b-form-group label="Date">
                        <b-form-input type="date" v-model="data.impartiality.date"/>
                    </b-form-group>
                </b-col>
                <b-col lg="8" v-if="data.impartiality.signatures_id !== null">
                    <b-form-group label="Firma">
                        <b-btn :variant="data.impartiality.signed === true ? 'success' : 'danger'" @click="$refs.signature.loadData(data.impartiality.signatures_id)">{{ data.impartiality.signed === true ? 'Firmato' : 'Non firmato' }}</b-btn>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col lg="4">
                    <b-form-group label="Deciso da">
                        <b-form-select v-model="data.impartiality.decision_users_id" :options="data.users" value-field="id" text-field="val"/>
                    </b-form-group>
                </b-col>
                <b-col lg="4">
                    <b-form-group label="Decisione data">
                        <b-form-input type="date" v-model="data.impartiality.decision_date"/>
                    </b-form-group>
                </b-col>
                <b-col lg="4">
                    <b-form-group label="Decisione">
                        <b-form-select v-model="data.impartiality.decision" :options="decisionCombo" value-field="id" text-field="val"/>
                    </b-form-group>
                </b-col>
            </b-row>
            <template v-slot:modal-footer>
                <div class="w-100">
                    <b-btn variant="success" @click="saveData()">Salva</b-btn>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
import auditsignature from './auditsignature.vue';

export default {
    name: "auditimpartiality",
    components: {
        auditsignature
    },
    data() {
        return {
            show: false,
            data: null,
            decisionCombo: [{
                id: null,
                val: "Da decidere"
            },{
                id: true,
                val: "Accettato"
            },{
                id: true,
                val: "Rifiutato"
            }]
        }
    },
    methods: {
        loadData(id, auditsId){
            this.openHttp("audits/impartiality", {id: id, audits_id: auditsId}).then((result) => {
                this.data = result;
                this.show = true;
            })
        },
        saveData(){
            this.saveHttp("audits/impartiality/save", this.data.impartiality).then((result) => {
                if (result !== null) this.closeForm();
            })
        },
        closeForm(){
            this.show = false;
            this.data = null;
            this.$emit('update');
        }
    }
}
</script>