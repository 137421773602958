<template>
  <div>
    <b-modal v-model="show" title="Login" no-close-on-esc no-close-on-backdrop hide-header-close hide-footer>
      <b-form>
        <b-form-group label="Mail">
          <b-form-input type="email" v-model="mail" required/>
        </b-form-group>
        <b-form-group label="Password">
          <b-form-input type="password" v-model="psw" required/>
        </b-form-group>
        <b-btn variant="primary" class="mr-2" @click="login()">Login</b-btn>
        <b-btn variant="warning" to="/resetmail">Reset</b-btn>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: true,
      mail: null,
      psw: null,
    }
  },
  created(){
    this.$store.commit('unSetToken');
  },
  methods: {
    login(){
      this.openHttp("login", { mail: this.mail, psw: this.psw }).then((result) => {
        if (result !== null) {
          this.$store.commit("setToken", result.token);
          this.$store.commit("setLang", result.languages_id);
          this.$store.commit("setInternal", result.internal);
          this.$store.commit("setRoutes", result.routes);
          this.$router.push("/homepage");
        } else {
          this.$store.commit('unSetToken');
          this.psw = null;
          this.$bvToast.toast("Mail / Password wrong", { title: "Gestionale - Err", variant: "danger", appendToast: true });
        }
      }) 
    }
  }
}
</script>