<template>
  <div>
    <company ref="company" @update="loadData(true)"/>
    <b-card no-body>
      <b-tabs card>
        <b-tab title="Claudia" active>
          <b-modal v-model="table1.showFilter" title="Filtra">
            <b-form-group label="Tipologia">
              <multiselect v-model="table1.tempFilter.companiestypesSupport" :options="table1.filtersMenu.companiestypes" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="table1.tempFilter.companiestypes = updateCombo(table1.tempFilter.companiestypesSupport)"/>
            </b-form-group>
            <b-form-group label="IAF">
              <b-form-select v-model="table1.tempFilter.iafcodes_id" :options="addNullCombo(table1.filtersMenu.iafcodes)" value-field="id" text-field="val"/>
            </b-form-group>
            <b-form-group label="NACE">
              <b-form-select v-model="table1.tempFilter.naces_id" :options="addNullCombo(table1.filtersMenu.naces)" value-field="id" text-field="val"/>
            </b-form-group>
            <b-form-group label="Tags">
              <multiselect v-model="table1.tempFilter.tagsSupport" :options="table1.filtersMenu.tags" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="table1.tempFilter.tags = updateCombo(table1.tempFilter.tagsSupport)"/>
            </b-form-group>
            <b-row>
              <b-col lg="4">
                <b-form-group label="Città">
                  <b-form-input v-model="table1.tempFilter.city"/>
                </b-form-group>          
              </b-col>
              <b-col lg="4">
                <b-form-group label="Provincia">
                  <b-form-select v-model="table1.tempFilter.provinces_id" :options="addNullCombo(table1.filtersMenu.provinces)" value-field="id" text-field="val"/>
                </b-form-group>          
              </b-col>
              <b-col lg="4">
                <b-form-group label="Regione">
                  <b-form-select v-model="table1.tempFilter.regions_id" :options="addNullCombo(table1.filtersMenu.regions)" value-field="id" text-field="val"/>
                </b-form-group>
              </b-col>
            </b-row>
            <template v-slot:modal-footer>
              <div class="w-100">
                <b-btn variant="primary" class="mr-2" @click="filterSet1()">Filtra</b-btn>
              </div>
            </template>
          </b-modal>
          <b-btn class="mr-2" variant="primary" @click="loadData1()">Aggiorna</b-btn>
          <b-btn class="mr-2" variant="secondary" @click="filterOpen1()">Filtra</b-btn>
          <b-btn variant="warning" @click="filterReset1()">Vedi tutto</b-btn>
          <b-table no-border-collapse :sticky-header="(winHeight - 305) + 'px'" responsive show-empty mode="remote" class="mt-3" :tbody-tr-class="rowClassDeadLine" :items="table1.items.data" :fields="fields" :busy="table1.isBusy" :no-local-sorting="true" :sort-by.sync="table1.search.sortby" :sort-desc.sync="table1.search.sortdesc" @sort-changed="sortingChange1" @row-clicked="$refs.company.loadData($event.id)">
            <template v-slot:table-busy>
              <div class="text-center text-primary">
                <b-spinner class="align-middle"/>
              </div>
            </template>
            <template v-slot:empty>Non ci sono risultati</template>
          </b-table>
          <tablebottom :busy="table1.isBusy" :currentpage="table1.search.page" :total="table1.items.total" @updateCurrentPage="table1.search.page = $event" @loadData="loadData1()"/>
        </b-tab>
        <b-tab title="In corso">
          <b-modal v-model="table2.showFilter" title="Filtra">
            <b-form-group label="Tipologia">
              <multiselect v-model="table2.tempFilter.companiestypesSupport" :options="table2.filtersMenu.companiestypes" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="table2.tempFilter.companiestypes = updateCombo(table2.tempFilter.companiestypesSupport)"/>
            </b-form-group>
            <b-row>
              <b-col lg="6">
                <b-form-group label="IAF">
                  <b-form-select v-model="table2.tempFilter.iafcodes_id" :options="addNullCombo(table2.filtersMenu.iafcodes)" value-field="id" text-field="val"/>
                </b-form-group>
              </b-col>
              <b-col lg="6">
                <b-form-group label="NACE">
                  <b-form-select v-model="table2.tempFilter.naces_id" :options="addNullCombo(table2.filtersMenu.naces)" value-field="id" text-field="val"/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-form-group label="Tags">
              <multiselect v-model="table2.tempFilter.tagsSupport" :options="table2.filtersMenu.tags" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="table2.tempFilter.tags = updateCombo(table2.tempFilter.tagsSupport)"/>
            </b-form-group>
            <b-form-group label="Città">
              <b-form-input v-model="table2.tempFilter.city"/>
            </b-form-group>          
            <b-form-group label="Provincia">
              <b-form-select v-model="table2.tempFilter.provinces_id" :options="addNullCombo(table2.filtersMenu.provinces)" value-field="id" text-field="val"/>
            </b-form-group>          
            <b-form-group label="Regione">
              <b-form-select v-model="table2.tempFilter.regions_id" :options="addNullCombo(table2.filtersMenu.regions)" value-field="id" text-field="val"/>
            </b-form-group>
            <template v-slot:modal-footer>
              <div class="w-100">
                <b-btn variant="primary" class="mr-2" @click="filterSet2()">Filtra</b-btn>
              </div>
            </template>
          </b-modal>
          <b-btn class="mr-2" variant="primary" @click="loadData2()">Aggiorna</b-btn>
          <b-btn class="mr-2" variant="secondary" @click="filterOpen2()">Filtra</b-btn>
          <b-btn variant="warning" @click="filterReset2()">Vedi tutto</b-btn>
          <b-table no-border-collapse :sticky-header="(winHeight - 305) + 'px'" responsive show-empty mode="remote" class="mt-3" :tbody-tr-class="rowClassDeadLine" :items="table2.items.data" :fields="fields" :busy="table2.isBusy" :no-local-sorting="true" :sort-by.sync="table2.search.sortby" :sort-desc.sync="table2.search.sortdesc" @sort-changed="sortingChange2" @row-clicked="$refs.company.loadData($event.id)">
            <template v-slot:table-busy>
              <div class="text-center text-primary">
                <b-spinner class="align-middle"/>
              </div>
            </template>
            <template v-slot:empty>Non ci sono risultati</template>
          </b-table>
          <tablebottom :busy="table2.isBusy" :currentpage="table2.search.page" :total="table2.items.total" @updateCurrentPage="table2.search.page = $event" @loadData="loadData2()"/>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
export default {
  name: "marketingmuller",
  data() {
    return {
      table1: {
        isBusy: true,
        showFilter: false,
        filtersMenu: {
          companiestypes:[],
          iafcodes: [],
          naces: [],
          tags:[],
          provinces: [],
          regions: []
        },
        tempFilter: {
          companiestypes: [],
          companiestypesSupport: [],
          iafcodes_id: null,
          naces_id: null,
          tags: [],
          tagsSupport: [],
          city: null,
          provinces_id: null,
          regions_id: null,
          usersdeadline_id: 133,
        },
        search: {
          page: 1,
          filters: {
            companiestypes: [],
            companiestypesSupport: [],
            iafcodes_id: null,
            naces_id: null,
            tags: [],
            tagsSupport: [],
            city: null,
            provinces_id: null,
            regions_id: null,
            usersdeadline_id: 133,
          },
          sortby: "deadline",
          sortdesc: false,
        },
        items: {
          total: 0,
          data: [],
        }
      },
      table2: {
        isBusy: true,
        showFilter: false,
        filtersMenu: {
          companiestypes:[],
          iafcodes: [],
          naces: [],
          tags:[],
          provinces: [],
          regions: []
        },
        tempFilter: {
          companiestypes: [],
          companiestypesSupport: [],
          iafcodes_id: null,
          naces_id: null,
          tags: [],
          tagsSupport: [],
          city: null,
          provinces_id: null,
          regions_id: null,
          usersdeadline_id: null,
        },
        search: {
          page: 1,
          filters: {
            companiestypes: [],
            companiestypesSupport: [],
            iafcodes_id: null,
            naces_id: null,
            tags: [],
            tagsSupport: [],
            city: null,
            provinces_id: null,
            regions_id: null,
            usersdeadline_id: null,
          },
          sortby: "company",
          sortdesc: false,
        },
        items: {
          total: 0,
          data: [],
        }
      },
      fields: [{
        key: 'company',
        label: 'Azienda',
        sortable: true,
      },{
        key: 'supplier',
        label: 'Segnalatore',
        sortable: true
      },{
        key: 'companiestype',
        label: 'Tipologia',
      },{
        key: 'tags',
        label: 'Tags'
      },{
        key: 'city',
        label: 'Città',
      },{
        key: 'mail',
        label: 'Mail',
        sortable: true,
        formatter: value => {
          return value === true ? 'Si' : 'No'; 
        }
      },{
        key: "note",
        label: 'Note',
        sortable: true,
        formatter: value => {
          if (value == null) return null;
          var d = new Date(value);
          return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
        }
      },{
        key: 'deadline',
        label: 'Scadenza',
        sortable: true,
        formatter: value => {
          if (value == null) return null;
          var d = new Date(value);
          return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
        }
      }]
    }
  },
  computed: {
    winHeight (){
      return this.$store.getters.getWinHeight;
    }
  },
  created(){
    this.loadData();
  },
  methods: {
    loadData(){
      this.table1.search = this.$store.getters.getSavedSearch("marketingmuller", "table1", this.table1.search);
      this.loadData1();
      this.table2.search = this.$store.getters.getSavedSearch("marketingmuller", "table2", this.table2.search);
      this.loadData2();
    },
    rowClassDeadLine(item) {
      if ( item == null || item.deadline === null) return "";
      let today = new Date();
      let deadline = new Date(item.deadline);
      return today.getTime() >= deadline.getTime() ? "table-danger" : "";
    },
    loadData1( notScroll = false ){
      if (notScroll == false) this.table1.isBusy = true;
      this.openHttp("marketingmuller/", this.table1.search).then((result) => {
        this.$store.commit("setSavedSearch", { form: "marketingmuller", tab: "table1", data: this.table1.search });
        this.table1.items = result;
        this.table1.isBusy = false;
      })
    },
    filterOpen1(){
      this.openHttp("companies/filters", null).then((result) => {
        result.tagsSupport = this.convertCombo(result.tags, this.table1.tempFilter.tags);
        this.table1.filtersMenu = result;
        this.table1.showFilter = true;
      })
    },
    filterSet1(){
      this.table1.showFilter = false;
      this.table1.search.page = 1;
      this.table1.search.filters = this.table1.tempFilter;
      this.loadData1();
    },
    filterReset1(){
      this.table1.tempFilter = {
        companiestypes: [],
        supportcompaniestypes: [],
        iafcodes_id: null,
        naces_id: null,
        tags: [],
        city: null,
        provinces_id: null,
        regions_id: null,
        usersdeadline_id: 133,
      };
      this.filterSet1();
    },
    sortingChange1(ctx) {
      this.table1.search.sortby = ctx.sortBy;
      this.table1.search.sortdesc = ctx.sortDesc;
      this.loadData1();
    },
    loadData2( notScroll = false ){
      if (notScroll == false) this.table2.isBusy = true;
      this.openHttp("marketingmuller/", this.table2.search).then((result) => {
        this.$store.commit("setSavedSearch", { form: "marketingmuller", tab: "table2", data: this.table2.search });
        this.table2.items = result;
        this.table2.isBusy = false;
      })
    },
    filterOpen2(){
      this.openHttp("companies/filters", null).then((result) => {
        result.tagsSupport = this.convertCombo(result.tags, this.table2.tempFilter.tags);
        this.table2.filtersMenu = result;
        this.table2.showFilter = true;
      })
    },
    filterSet2(){
      this.table2.showFilter = false;
      this.table2.search.page = 1;
      this.table2.search.filters = this.table2.tempFilter;
      this.loadData2();
    },
    filterReset2(){
      this.table2.tempFilter = {
        companiestypes: [],
        supportcompaniestypes: [],
        iafcodes_id: null,
        naces_id: null,
        tags: [],
        city: null,
        provinces_id: null,
        regions_id: null,
        usersdeadline_id: null,
      };
      this.filterSet2();
    },
    sortingChange2(ctx) {
      this.table2.search.sortby = ctx.sortBy;
      this.table2.search.sortdesc = ctx.sortDesc;
      this.loadData2();
    }
  }
}
</script>