<template>
  <div>
    <company ref="company" @update="loadData(true)"/>
    <b-modal v-model="showFilter" size="lg" title="Filtra">
      <b-row>
        <b-col lg="1">
          <b-form-group label="Id">
            <b-form-input type="number" v-model="tempFilter.id"/>
          </b-form-group>
        </b-col>
        <b-col lg="6">
          <b-form-group label="Azienda">
            <b-form-input v-model="tempFilter.company"/>
          </b-form-group>
        </b-col>
        <b-col lg="2">
          <b-form-group label="P.IVA">
            <b-form-input v-model="tempFilter.vat"/>
          </b-form-group>
        </b-col>
        <b-col lg="3">
          <b-form-group label="Tipologia" v-if="filtersMenu.companiestypes.length > 0">
            <multiselect v-model="tempFilter.companiestypesSupport" :options="filtersMenu.companiestypes" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="tempFilter.companiestypes = updateCombo(tempFilter.companiestypesSupport)"/>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="4">
          <b-form-group label="Segnalatore">
            <b-form-select v-model="tempFilter.suppliers_id" :options="addNullCombo(filtersMenu.suppliers)" value-field="id" text-field="val"/>
          </b-form-group>
        </b-col>
        <b-col lg="4">
          <b-form-group label="Cognome">
            <b-form-input v-model="tempFilter.lastname"/>
          </b-form-group>
        </b-col>
        <b-col lg="4">
          <b-form-group label="Recapito (tel, mail, web)">
            <b-form-input v-model="tempFilter.contact"/>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="3" v-if="filtersMenu.iafcodes.length > 0">
          <b-form-group label="IAF">
            <b-form-select v-model="tempFilter.iafcodes_id" :options="addNullCombo(filtersMenu.iafcodes)" value-field="id" text-field="val"/>
          </b-form-group>
        </b-col>
        <b-col lg="3" v-if="filtersMenu.naces.length > 0">
          <b-form-group label="NACE">
            <b-form-select v-model="tempFilter.naces_id" :options="addNullCombo(filtersMenu.naces)" value-field="id" text-field="val"/>
          </b-form-group>
        </b-col>
        <b-col lg="6" v-if="filtersMenu.tags.length > 0">
          <b-form-group label="Tags">
            <multiselect v-model="tempFilter.tagsSupport" :options="filtersMenu.tags" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="tempFilter.tags = updateCombo(tempFilter.tagsSupport)"/>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="4" v-if="filtersMenu.provinces.length > 1">
          <b-form-group label="Città">
            <b-form-input v-model="tempFilter.city"/>
          </b-form-group>          
        </b-col>
        <b-col lg="4" v-if="filtersMenu.provinces.length > 1">
          <b-form-group label="Provincia">
            <b-form-select v-model="tempFilter.provinces_id" :options="addNullCombo(filtersMenu.provinces)" value-field="id" text-field="val"/>
          </b-form-group>          
        </b-col>
        <b-col lg="4" v-if="filtersMenu.regions.length > 1">
          <b-form-group label="Regione">
            <b-form-select v-model="tempFilter.regions_id" :options="addNullCombo(filtersMenu.regions)" value-field="id" text-field="val"/>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="4" v-if="filtersMenu.usersdeadline.length > 1">
          <b-form-group label="Scadenza">
            <b-form-select v-model="tempFilter.usersdeadline_id" :options="addNullCombo(filtersMenu.usersdeadline)" value-field="id" text-field="val"/>
          </b-form-group>
        </b-col>
      </b-row>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="primary" class="mr-2" @click="filterSet()">Filtra</b-btn>
          <b-btn variant="warning" @click="filterReset()">Reset</b-btn>
        </div>
      </template>
    </b-modal>
    <b-btn class="mr-2" variant="primary" @click="loadData()">Aggiorna</b-btn>
    <b-btn class="mr-2" variant="secondary" @click="filterOpen()">Filtra</b-btn>
    <b-btn variant="success" v-if="level >= 2" @click="$refs.company.loadData(0)">Nuovo</b-btn>
    <b-table no-border-collapse :sticky-header="(winHeight - 205) + 'px'" hover responsive show-empty mode="remote" class="mt-3" :tbody-tr-class="rowClassDeadLine" :items="items.data" :fields="fields" :busy="isBusy" :no-local-sorting="true" :sort-by.sync="search.sortby" :sort-desc.sync="search.sortdesc" @sort-changed="sortingChange" @row-clicked="$refs.company.loadData($event.id)">
      <template v-slot:table-busy>
        <div class="text-center text-primary">
          <b-spinner class="align-middle"/>
        </div>
      </template>
      <template v-slot:empty>Non ci sono risultati</template>
    </b-table>
    <tablebottom :busy="isBusy" :currentpage="search.page" :total="items.total" @updateCurrentPage="search.page = $event" @loadData="loadData()"/>
  </div>
</template>

<script>
export default {
  name: "companies",
  data() {
    return {
      isBusy: true,
      showFilter: false,
      search: {
        page: 1,
        filters: {
          id: null,
          company: null,
          vat: null,
          companiestypes: [],
          companiestypesSupport: [],
          contact: null,
          lastname: null,
          suppliers_id: null,
          iafcodes_id: null,
          naces_id: null,
          tags: [],
          tagsSupport: [],
          city: null,
          provinces_id: null,
          regions_id: null,
          usersdeadline_id: null,
        },
        sortby: null,
        sortdesc: false,
      },
      tempFilter: {
        id: null,
        company: null,
        vat: null,
        companiestypes: [],
        companiestypesSupport: [],
        contact: null,
        lastname: null,
        suppliers_id: null,
        iafcodes_id: null,
        naces_id: null,
        tags: [],
        tagsSupport: [],
        city: null,
        provinces_id: null,
        regions_id: null,
        usersdeadline_id: null,
      },
      filtersMenu: {
        companiestypes:[],
        suppliers:[],
        iafcodes: [],
        naces: [],
        tags:[],
        provinces: [],
        regions: [],
        usersdeadline: [],
      },
      fields: [{
        key: 'company',
        label: 'Azienda',
        sortable: true,
      },{
        key: 'companiestype',
        label: 'Tipologia',
      },{
        key: 'supplier',
        label: 'Segnalatore',
        sortable: true,
        thClass: 'd-none',
        tdClass: 'd-none',
      },{
        key: 'tags',
        label: 'Tags',
        thClass: 'd-none',
        tdClass: 'd-none',
      },{
        key: 'city',
        label: 'Città',
      },{
        key: 'mail',
        label: 'Mail',
        sortable: true,
        formatter: value => {
          return value === true ? 'Si' : 'No'; 
        },
        thClass: 'd-none',
        tdClass: 'd-none',
      },{
        key: 'note',
        label: 'Note',
        sortable: true,
        formatter: value => {
          if (value == null) return null;
          var d = new Date(value);
          return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
        },
        thClass: 'd-none',
        tdClass: 'd-none',
      },{
        key: 'deadline',
        label: 'Scadenza',
        sortable: true,
        formatter: value => {
          if (value == null) return null;
          var d = new Date(value);
          return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
        },
        thClass: 'd-none',
        tdClass: 'd-none',
      }],
      items: {
        total: 0,
        data: [],
      }
    }
  },
  computed: {
    level(){
      return this.$store.getters.getRoutes.find(x => x.route === "companies").level;
    },
    winHeight (){
      return this.$store.getters.getWinHeight;
    }
  },
  created(){
    this.loadData();
  },
  methods: {
    rowClassDeadLine(item) {
      if ( item == null || item.deadline === null) return "";
      let today = new Date();
      let deadline = new Date(item.deadline);
      return today.getTime() >= deadline.getTime() ? "table-danger" : "";
    },
    loadData( notScroll = false ){
      if (notScroll == false) this.isBusy = true;
      this.showFilter = false;
      this.openHttp("companies/companies", this.search).then((result) => {
        this.isBusy = false;
        this.items = result;
        if ( this.level >= 2 ){
          delete this.fields.find(x => x.key === "supplier").thClass;
          delete this.fields.find(x => x.key === "supplier").tdClass;
          delete this.fields.find(x => x.key === "tags").thClass;
          delete this.fields.find(x => x.key === "tags").tdClass;
          delete this.fields.find(x => x.key === "mail").thClass;
          delete this.fields.find(x => x.key === "mail").tdClass;
          delete this.fields.find(x => x.key === "note").thClass;
          delete this.fields.find(x => x.key === "note").tdClass;
          delete this.fields.find(x => x.key === "deadline").thClass;
          delete this.fields.find(x => x.key === "deadline").tdClass;
        }
      })
    },
    filterOpen(){
      this.openHttp("companies/filters", null).then((result) => {
        if (typeof(result.tags) !== 'undefined') result.tagsSupport = this.convertCombo(result.tags, this.tempFilter.tags);
        this.filtersMenu = result;
        this.showFilter = true;
      })
    },
    filterSet(){
      this.search.page = 1;
      this.search.filters = this.tempFilter;
      this.loadData();
    },
    filterReset(){
      this.tempFilter = {
        id: null,
        company: null,
        vat: null,
        companiestypes: [],
        supportcompaniestypes: [],
        contact: null,
        lastname: null,
        suppliers_id: null,
        iafcodes_id: null,
        naces_id: null,
        tags: [],
        city: null,
        provinces_id: null,
        regions_id: null,
        usersdeadline_id: null,
      };
      this.filterSet();
    },
    sortingChange(ctx) {
      this.search.sortby = ctx.sortBy;
      this.search.sortdesc = ctx.sortDesc;
      this.loadData();
    }
  }
}
</script>