<template>
    <div>
        <company ref="company" @update="loadData"/>
        <b-modal v-model="show" v-if="data !== null" title="Mappa" size="xl" @hidden="closeForm" @close="closeForm">
            <gmap-map :center="location" :zoom="zoom" style="width: 100%" v-bind:style="{ height: (winHeight - 220) + 'px' }" @bounds_changed="bounds_changed_map($event)">
                <gmap-marker v-for="(item, key) in coordinates" :key="key" :position="getPosition(item)" :clickable="true" @click="loadItem(item.id)" @mouseover="showByIndex = key;" @mouseout="showByIndex = null" :icon="require('@/assets/' + ( item.id === lastId ? '0' : item.icon ) + '.png')">
                    <gmap-info-window :opened="showByIndex === key">
                        {{item.company}}<br>{{item.companiestype}}
                    </gmap-info-window>
                </gmap-marker>
            </gmap-map>
            <template v-slot:modal-footer>
                <div class="w-100">
                    <b-btn class="mr-2">Totale: {{ total }}</b-btn>
                    <b-btn variant="primary" @click="loadData( location.id, location.lat, location.lng )">Carica</b-btn>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
export default {
    name: "companymap",
    data() {
        return {
            show: false,
            showByIndex: null,
            location: {
                id: null,
                lat: 45.6512,
                lng: 9.5512,
                lat_p1: 45.6247947627811,
                lng_p1: 9.484037486267072,
                lat_p2: 45.67759279420247,
                lng_p2: 9.618362513732892,
            },
            zoom: 14,
            total: 0,
            data: [],
            coordinates: [],
            infoPosition: null,
            infoContent: {
                company: null,
                companiestype: null,
            },
            infoOpened: true,
            infoCurrentKey: null,
            infoOptions: {
                pixelOffset: {
                    width: 0,
                    height: -35
                }
            },
        }
    },
    computed: {
        winHeight(){
            return this.$store.getters.getWinHeight;
        },
    },
    methods: {        
        bounds_changed_map(event){
            if (event !== undefined){
                this.location.lat_p1 = event.Ab.g;
                this.location.lng_p1 = event.Ra.g;
                this.location.lat_p2 = event.Ab.h;
                this.location.lng_p2 = event.Ra.h;
            }
        },
        getPosition(marker) {
            return {
                lat: parseFloat(marker.lat),
                lng: parseFloat(marker.lng)
            }
        },
        toggleInfo(marker, key) {
            this.infoPosition = this.getPosition(marker);
            this.infoContent = marker;
            if (this.infoCurrentKey == key) {
                this.infoOpened = !this.infoOpened;
            } else {
                this.infoOpened = true;
                this.infoCurrentKey = key;
            }
        },
        loadData( id, lat, lng){
            this.location.id = id;
            this.location.lat = lat;
            this.location.lng = lng;
            this.lastId = id;
            this.show = true;
            setTimeout(function(this2){
                this2.openHttp("companiesmaps/map", this2.location).then((result) => {
                    this2.total = result.total;
                    this2.coordinates = result.data;
                    this2.show = true;
                })
            }, 200, this);
        },
        loadItem(id){
            this.lastId = id;
            this.$refs.company.loadData(id);
        },
        closeForm(){
            this.show = false;
            this.coordinates = [];
            this.total = 0;
            this.$emit('update');
        }
    }
}
</script>