

<template>
    <div>
        <b-modal v-if="data !== null" v-model="show" :title="'Provicia - ' + data.province.id" @hidden="closeForm()" @close="closeForm()">
            <b-form-group label="Provincia">
                <b-form-input v-model="data.province.province"/>
            </b-form-group>
            <b-form-group label="Regione">
                <b-form-select v-model="data.province.regions_id" :options="data.regions" value-field="id" text-field="val"/>
            </b-form-group>
            <template v-slot:modal-footer>
                <div class="w-100">
                    <b-btn variant="success" class="mr-2" @click="saveData">Salva</b-btn>
                    <b-btn variant="danger" v-show="data.province.id > 0" @click="deleteData">Elimina</b-btn>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>

export default {
    name: "province",
    data() {
        return {
            show: false,
            data: null,
        }
    },
    methods: {
        loadData(id){
            this.openHttp("companiessettings/province", { id: id }).then((result) => {
                this.data = result;
                this.show = true;
            })
        },
        saveData(){
            this.saveHttp("companiessettings/province/save", this.data.province).then((result) => {
                if ( result !== null ) this.closeForm();
            })
        },
        deleteData(){
            this.deleteHttp("companiessettings/province/delete", { id: this.data.province.id }).then((result) => {
                if ( result !== null ) this.closeForm();
            })
        },
        closeForm(){
            this.show = false;
            this.data = null;
            this.$emit('update');
        }
    },
}
</script>