<template>
  <div>
    <company ref="company" @update="loadData"/>
    <b-modal v-model="showFilter" title="Filtra">
      <b-form-group label="Da">
        <b-form-input type="date" v-model="tempFilter.from"/>
      </b-form-group>
      <b-form-group label="A">
        <b-form-input type="date" v-model="tempFilter.to"/>
      </b-form-group>
      <b-form-group label="Utente">
        <b-form-select v-model="tempFilter.users_id" :options="addNullCombo(filtersMenu.users, 'Tutti')" value-field="id" text-field="val"/>
      </b-form-group>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="primary" class="mr-2" @click="filterSet">Filtra</b-btn>
          <b-btn variant="warning" @click="filterReset">Reset</b-btn>
        </div>
      </template>
    </b-modal>
    <b-btn class="mr-2" variant="primary" @click="loadData">Aggiorna</b-btn>
    <b-btn class="mr-2" variant="secondary" @click="filterOpen">Filtra</b-btn>
    <b-btn class="mr-2" variant="info">Tot minuti: {{ Math.round(items.billed / 60) }}</b-btn>
    <b-table no-border-collapse :sticky-header="(winHeight - 205) + 'px'" hover responsive show-empty mode="remote" class="mt-3" :items="items.data" :fields="fields" :busy="isBusy" :no-local-sorting="true" :sort-by.sync="search.sortby" :sort-desc.sync="search.sortdesc" @sort-changed="sortingChange" @row-clicked="loadItem($event.companies_id)">
      <template v-slot:table-busy>
        <div class="text-center text-primary">
          <b-spinner class="align-middle"/>
        </div>
      </template>
      <template v-slot:empty>Non ci sono risultati</template>
    </b-table>
    <tablebottom :busy="isBusy" :currentpage="search.page" :total="items.total" @updateCurrentPage="search.page = $event" @loadData="loadData"/>
  </div>
</template>

<script>
export default {
  name: "phonecalls",
  data() {
    return {
      isBusy: true,
      showFilter: false,
      search: {
        page: 1,
        filters: {
          from: null,
          to: null,
          users_id: null,
        },
        sortby: "date",
        sortdesc: true,
      },
      tempFilter: {
        fromdate: null,
        todate: null,
        users_id: null,
      },
      filtersMenu: {
        users:[],
      },
      fields: [{
        key: 'date',
        label: 'Data',
        sortable: true,
        formatter: value => {
          if (value == null) return null;
          var d = new Date(value);
          return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
        },
      },{
        key: 'time',
        label: 'Ora',
        sortable: true,
      },{
        key: 'user',
        label: 'Utente',
      },{
        key: 'billsec',
        label: 'Tel(sec.)',
      },{
        key: 'phonenumber',
        label: 'Numero',
      },{
        key: 'company',
        label: 'Azienda',
      }],
      items: {
        total: 0,
        billed: 0,
        data: [],
      }
    }
  },
  computed: {
    winHeight (){
      return this.$store.getters.getWinHeight;
    },
  },
  created(){
    const today = new Date();
    this.tempFilter.fromdate = [ today.getFullYear(), today.getMonth() + 1, today.getDate() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
    this.search.filters.fromdate = [ today.getFullYear(), today.getMonth() + 1, today.getDate() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
    this.loadData();
  },
  methods: {
    loadData(){
      this.isBusy = true;
      this.openHttp("phonecalls/", this.search).then((result) => {
        this.items = result;
        this.isBusy = false;
      })
    },
    filterOpen(){
      this.openHttp("phonecalls/filters", null).then((result) => {
        this.filtersMenu = result;
        this.showFilter = true;
      })
    },
    filterSet(){
      this.showFilter = false;
      this.search.page = 1;
      this.search.filters = this.tempFilter;
      this.loadData();
    },
    filterReset(){
      this.tempFilter = {
        fromdate: null,
        todate: null,
        users_id: null,
      };
      this.search.filters = this.tempFilter;
      this.loadData();
    },
    sortingChange(ctx) {
      this.search.sortby = ctx.sortBy;
      this.search.sortdesc = ctx.sortDesc;
      this.loadData();
    },
    loadItem(id){
      if (id > 0) this.$refs.company.loadData(id);
    },
  }
}
</script>