<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" :title="'Utente - ' + data.user.id" size="lg" @hidden="closeForm()" @close="closeForm()">
      <b-row>
        <b-col lg="2">
          <b-form-group label="Titolo">
            <b-form-select v-model="data.user.titles_id" :options="data.titles" value-field="id" text-field="val"/>
          </b-form-group>
        </b-col>
        <b-col lg="5">
          <b-form-group label="Nome">
            <b-form-input v-model="data.user.firstname"/>
          </b-form-group>
        </b-col>
        <b-col lg="5">
          <b-form-group label="Cognome">
            <b-form-input v-model="data.user.lastname"/>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="2">
          <b-form-group label="Stato">
            <b-form-select v-model="data.user.active" :options="activeCombo" value-field="id" text-field="val"/>
          </b-form-group>
        </b-col>
        <b-col lg="2">
          <b-form-group label="Tipo">
            <b-form-select v-model="data.user.internal" :options="internalCombo" value-field="id" text-field="val"/>
          </b-form-group>
        </b-col>
        <b-col lg="4">
          <b-form-group label="Mail">
            <b-form-input v-model="data.user.mail" :disabled="!data.user.active"/>
          </b-form-group>
        </b-col>
        <b-col lg="4">
          <b-form-group label="Password">
            <b-form-input v-model="data.user.psw" :disabled="!data.user.active"/>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="2">
          <b-form-group label="Piano">
            <b-form-select v-model="data.user.plan" :options="yesnoCombo" value-field="id" text-field="val" :disabled="!data.user.active"/>
          </b-form-group>
        </b-col>
        <b-col lg="2">
          <b-form-group label="PBX">
            <b-form-input type="number" v-model="data.user.pbx" :disabled="!data.user.active"/>
          </b-form-group>
        </b-col>
        <b-col lg="2">
          <b-form-group label="Segnare ore?">
            <b-form-select v-model="data.user.markhours" :options="yesnoCombo" value-field="id" text-field="val" :disabled="!data.user.active"/>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="4">
          <b-form-group label="Lingua">
            <b-form-select v-model="data.user.languages_id" :options="data.languages" value-field="id" text-field="val"/>
          </b-form-group>
        </b-col>
        <b-col lg="4">
          <b-form-group label="Segnalatore">
            <b-form-select v-model="data.user.suppliers_id" :options="data.suppliers" value-field="id" text-field="val"/>
          </b-form-group>
        </b-col>
      </b-row>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="success" class="mr-2" @click="saveData()">Salva</b-btn>
          <b-btn variant="danger" v-show="data.user.id > 0" @click="deleteData()">Elimina</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>

export default {
  name: "usersuser",
  data() {
    return {
      data: null,
      show: false,
      activeCombo: [{
        id: true,
        val: "Attivo",
      },{
        id: false,
        val: "Non attivo",
      }],
      yesnoCombo: [{
        id: true,
        val: "Si",
      },{
        id: false,
        val: "No",
      }],
      internalCombo: [{
        id: true,
        val: "Interno",
      },{
        id: false,
        val: "Esterno",
      }]
    }
  },
  methods: {
    loadData(id){      
      if ( this.data === null ) this.show = false;
      this.openHttp("users/user", { id: id }).then((result) => {
        this.data = result;
        this.show = true;
      })
    },
    saveData(){
      this.saveHttp("users/user/save", this.data.user).then((result) => {
        if (result !== null) this.closeForm();
      })
    },
    deleteData(){
      this.deleteHttp("users/user/delete", { id: this.data.user.id }).then((result) => {
        if (result !== null) this.closeForm();
      })
    },
    closeForm(){
      this.show = false;
      this.data = null;
      this.$emit('update');
    },
  }
}
</script>