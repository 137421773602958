<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" title="Banca" size="lg" @hidden="closeForm()" @close="closeForm()">
      <b-table no-border-collapse :sticky-header="(winHeight - 250) + 'px'" :items="data" :fields="fields">
        <template v-slot:cell(money)="row">
          <b-form-input type="number" v-model="row.item.money"/>
        </template>
      </b-table>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="success" @click="saveData">Salva</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "bank",
  data() {
    return {
      show: true,
      data: null,
      fields: [{
        key: "tagname",
        label: "Banca",
      },{
        key: "money",
        label: "Importo"
      }],
    }
  },
  computed: {
    winHeight (){
      return this.$store.getters.getWinHeight;
    },
    bankPass (){
      return this.$store.getters.getBankPsw;
    }
  },
  methods: {
    loadData( date ){
      this.openHttp("banks/bank", {password: this.bankPass, date: date }).then((result) => {
        this.data = result;
        this.show = true;
      })
    },
    saveData(){
      this.saveHttp("banks/bank/save", {password: this.bankPass, data: this.data}).then((result) => {
        if ( result !== null ) this.closeForm();
      })
    },
    closeForm(){
      this.show = false;
      this.data = null;
      this.$emit('update');
    },
  },
}
</script>