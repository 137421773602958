<template>
  <div>
    <b-card no-body>
      <b-tabs card>
        <b-tab title="Piano" active>
          <ordersday ref="ordersday" @update="loadData"/>
          <b-modal v-if="ordersdays.showFilter" v-model="ordersdays.showFilter" title="Filtra">
            <b-form-group label="Collaboratore" v-if="ordersdays.filtersMenu.users !== null">
              <b-form-select v-model="ordersdays.tempFilter.users_id" :options="addNullCombo(ordersdays.filtersMenu.users, 'Tutti')" value-field="id" text-field="val"/>
            </b-form-group>
            <b-form-group label="Tipologia" v-if="ordersdays.filtersMenu.internal !== null">
              <b-form-select v-model="ordersdays.tempFilter.internal" :options="ordersdays.filtersMenu.internal" value-field="id" text-field="val"/>
            </b-form-group>
            <b-form-group label="Ricerca">
              <b-form-input v-model="ordersdays.tempFilter.string"/>
            </b-form-group>
            <template v-slot:modal-footer>
              <div class="w-100">
                <b-btn variant="primary" class="mr-2" @click="filterSet">Filtra</b-btn>
                <b-btn variant="warning" @click="filterReset">Reset</b-btn>
              </div>
            </template>
          </b-modal>
          <b-btn variant="secondary" class="mr-2" @click="filterOpen">Filtra</b-btn>
          <b-btn variant="success" class="mr-2" @click="$refs.ordersday.loadData(0)">Nuovo</b-btn>
          <b-btn variant="primary" class="mr-1" @click="previousMonth">&#60;</b-btn>
          <b-btn variant="primary" class="mr-1" @click="loadOrdersdays">{{ (ordersdays.search.month + 1) + '-' + ordersdays.search.year }}</b-btn>
          <b-btn variant="primary" @click="nextMonth">&#62;</b-btn>
          <b-table-simple :sticky-header="(winHeight - 270) + 'px'" class="mt-3">
            <b-thead>
              <b-tr>
                <b-th v-for="item in ordersdays.days" :key="item.id">{{ item.val }}</b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr v-for="item in ordersdays.weeks" :key="item.week">
                <b-td v-for="item2 in item.days" :key="item2.day" :style="{ width: item2.id < 5 ? '18%' : '5%' }" style="border-right: 2px solid #dee2e6; border-top: 2px solid #dee2e6; border-left: 2px solid #dee2e6; border-bottom: 2px;">
                  <div style="min-height: 150px">
                    <div style="display: flex; justify-content: flex-end">
                      <div>{{ item2.day.substring(8, 10) }}</div>
                    </div>
                    <div v-for="appointment in item2.appointments" :key="appointment.id" :style="{ backgroundColor: '#' + appointment.color }" style="padding: 5px; margin-bottom: 5px; border-radius: 5px;" @click="$refs.ordersday.loadData(appointment.id)">
                      <b>{{ appointment.fullname }}<br></b>
                      <b v-if="appointment.company === null">{{ appointment.ordersdaystype }}<br></b>
                      <b v-if="appointment.company !== null">{{ appointment.company + ' (' + appointment.orders_id + ')' }}</b>
                      <div v-if="appointment.note !== null">{{ appointment.note }}</div>
                      <b v-if="appointment.morning === true && appointment.afternoon === false">Mattina</b>
                      <b v-if="appointment.morning === false && appointment.afternoon === true">Pomeriggio</b>
                      <div v-if="appointment.certificationbody !== null && appointment.certificationbody !== '-'"><font :color="appointment.certificationbody !== 'Tuv Hessen' ? 'red': ''">{{ appointment.certificationbody }}</font></div>
                    </div>
                  </div>
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-tab>
        <b-tab title="Audits">
          <b-btn class="mr-2" variant="primary" @click="loadAudits">Aggiorna</b-btn>
          <b-table no-border-collapse :sticky-header="(winHeight - 305) + 'px'" hover responsive show-empty mode="remote" class="mt-3" :items="audits.items.data" :fields="audits.fields" :busy="audits.isBusy" @row-clicked="$refs.ordersday.loadData($event.id)">
            <template v-slot:table-busy>
              <div class="text-center text-primary">
                <b-spinner class="align-middle"/>
              </div>
            </template>
            <template v-slot:empty>Non ci sono risultati</template>
            <template v-slot:cell(services)="row">
              <div v-for="item in row.item.services" :key="item">{{ item }}</div>
            </template>
          </b-table>
          <tablebottom :busy="audits.isBusy" :currentpage="audits.search.page" :total="audits.items.total" @updateCurrentPage="audits.search.page = $event" @loadData="loadAudits"/>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
export default {
  name: "ordersdays",
  data() {
    return {
      ordersdays: {
        search: {
          users_id: null,
          internal: null,
          string: null,
          month: null,
          year: null,
        },
        days: [{
          id: 0,
          val: 'Lunedì',
        },{
          id: 1,
          val: 'Martedì',
        },{
          id: 2,
          val: 'Mercoledì',
        },{
          id: 3,
          val: 'Giovedì',
        },{
          id: 4,
          val: 'Venerdì',
        },{
          id: 5,
          val: 'Sabato',
        },{
          id: 6,
          val: 'Domenica',
        }],
        daylist: [],
        weeks: [],
        showFilter: false,
        tempFilter: {
          users_id: null,
          internal: null,
          string: null,
        },
        filtersMenu: {
          companies:[],
          internalcompanies:[],
        },
        data: [],
      },
      audits: {
        isBusy: true,
        search: {
          page: 1,
        },
        fields: [{
          key: 'orders_id',
          label: 'N°',
        },{
          key: 'date',
          label: 'Data',
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          },
        },{
          key: 'title',
          label: 'Appuntamento',
        },{
          key: 'orders_id',
          label: 'Commessa',
        },{
          key: 'services',
          label: 'Servizi',
        },{
          key: 'certificationbody',
          label: 'Ente',
        },{
          key: 'fullname',
          label: 'Incaricato',
        }],
        items: {
          total: 0,
          data: [],
        }
      }
    }
  },
  computed: {
    winHeight (){
      return this.$store.getters.getWinHeight;
    },
  },
  created(){
    this.loadData();
  },
  methods: {
    previousMonth(){
      this.ordersdays.search.month = this.ordersdays.search.month - 1;
      if ( this.ordersdays.search.month === -1 ){
        this.ordersdays.search.month = 11;
        this.ordersdays.search.year = this.ordersdays.search.year - 1;
      }
      this.loadOrdersdays();
    },
    nextMonth(){
      this.ordersdays.search.month = this.ordersdays.search.month + 1;
      if ( this.ordersdays.search.month === 12 ){
        this.ordersdays.search.month = 0;
        this.ordersdays.search.year = this.ordersdays.search.year + 1;
      }
      this.loadOrdersdays();
    },
    loadData(){
      this.loadOrdersdays();
      this.loadAudits();
    },
    loadOrdersdays(){
      let today = new Date();
      if ( this.ordersdays.search.month === null ) this.ordersdays.search.month = today.getMonth();
      if ( this.ordersdays.search.year === null ) this.ordersdays.search.year = today.getFullYear();
      
      let fromDate = new Date( this.ordersdays.search.year, this.ordersdays.search.month, 1);
      let day = fromDate.getDay();
      fromDate = new Date( fromDate.setDate(fromDate.getDate() - day + (day == 0 ? -6 : 1)));
      let toDate = new Date( this.ordersdays.search.year, this.ordersdays.search.month + 1, 0);
      toDate = new Date( toDate.setDate(toDate.getDate() - toDate.getDay() + 7 + 1) );
      this.ordersdays.weeks = [];
      this.ordersdays.search.fromdate = fromDate;
      this.ordersdays.search.todate = toDate;

      this.openHttp("ordersdays/", this.ordersdays.search).then((result) => {
        let dt = fromDate;
        dt.setDate( dt.getDate() + 1 );
        for(this.ordersdays.daylist = []; dt <= toDate; dt.setDate(dt.getDate()+1)) this.ordersdays.daylist.push( new Date( dt ) );
        this.ordersdays.daylist = this.ordersdays.daylist.map((v)=>v.toISOString().slice(0,10));
        for( let i = 0; i <= Math.floor(this.ordersdays.daylist.length / 7) - 1; i++ ){
          let week = {
            week: i,
            days: [],
          };
          for( let j = 0; j < 7; j++ ){
            week.days.push({
              id: j,
              day: this.ordersdays.daylist[ i * 7 + j ],
              appointments: result.filter(x => x.date === this.ordersdays.daylist[ i * 7 + j ]),
            })
          }
          this.ordersdays.weeks.push(week);
        }
      })
    },
    filterOpen(){
      this.openHttp("ordersdays/filters", null).then((result) => {
        this.ordersdays.filtersMenu = result;
        this.ordersdays.showFilter = true;
      })
    },
    filterSet(){
      this.ordersdays.showFilter = false;
      this.ordersdays.search = {
        users_id: this.ordersdays.tempFilter.users_id,
        internal: this.ordersdays.tempFilter.internal,
        string: this.ordersdays.tempFilter.string,
        month: this.ordersdays.search.month,
        year: this.ordersdays.search.year,
      };
      this.loadOrdersdays();
    },
    filterReset(){
      this.ordersdays.tempFilter = {
        users_id: null,
        internal: null,
        string: null,
        month: this.ordersdays.search.month,
        year: this.ordersdays.search.year,
      };
      this.filterSet();
    },
    loadAudits(){
      this.audits.isBusy = true;
      this.openHttp("ordersdays/audits", this.audits.search).then((result) => {
        this.audits.items = result;
        this.audits.isBusy = false;
      })
    }
  }
}
</script>