import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        showNavBar: false,
        token: localStorage.getItem("token"),
        internal: localStorage.getItem("internal"),
        lang: localStorage.getItem("lang"),
        routes: localStorage.getItem("routes") === undefined ? [] : JSON.parse(localStorage.getItem("routes")),
        searches: localStorage.getItem("searches") === undefined ? {} : JSON.parse(localStorage.getItem("searches")),
        mail: localStorage.getItem("mail") === undefined ? { mailboxes_id: null, templates_id: null, deadlineyesno: false } : JSON.parse(localStorage.getItem("mail")),
        windowWidth: 0,
        windowHeight: 0,
    },
    getters: {
        getNavBar : state => {
            return state.showNavBar;
        },
        getToken : state => {
            return state.token;
        },
        getLang : state => {
            return state.lang;
        },
        getInternal : state => {
            return state.internal;
        },
        getRoutes : state => {
            return state.routes;
        },
        checkRoute: (state) => (route, level) => {
            return state.routes.findIndex(x => x.route === route && x.level >= level) > -1;
        },
        getWinHeight : state => {
            return state.windowHeight;
        },
        getWinWidth : state => {
            return state.windowWidth;
        },
        getSavedMail: (state) => () => {
            return state.mail;
        },
        getSavedSearch: state => (form, tab, defaultvalue) => {
            if (state.searches === null) state.searches = {};
            return state.searches[form + "_" + tab] !== undefined ? state.searches[form + "_" + tab] : defaultvalue;
        }
    },
    mutations: {
        setToken : (state, token) => {
            if (token != null){
                localStorage.setItem("token", token);
                state.token = token;
            }
        },
        setNavbar: (state, status) => {
            state.showNavBar = status;
        },
        setLang : (state, lang) => {
            localStorage.setItem("lang", lang);
            state.lang = lang;
        },
        setInternal: (state, internal) => {
            localStorage.setItem("internal", internal);
            state.internal = internal;
        },
        setRoutes: (state, routes) => {
            localStorage.setItem("routes", JSON.stringify(routes));
            state.routes = routes;
        },
        setWinHeight: (state, height) => {
            state.windowHeight = height;
        },
        setWinWidth: (state, width) => {
            state.windowWidth = width;
        },
        unSetToken: (state) => {
            state.showNavBar = false;
            localStorage.clear();
            state.token = null;
            state.lang = null;
            state.internal = null;
            state.routes = [];
            localStorage.clear();
        },
        setSavedMail: (state, {mailboxesId, templatesId, deadlineYesNo }) => {
            state.mail = {
                mailboxes_id: mailboxesId,
                templates_id: templatesId,
                deadlineyesno: deadlineYesNo
            }; 
            localStorage.setItem("mail", JSON.stringify(state.mail));
        },
        setSavedSearch: (state, {form, tab, data}) => {
            if (state.searches === null) state.searches = {}; 
            state.searches[form + "_" + tab] = data;
            localStorage.setItem("searches", JSON.stringify(state.searches));
        }
    },
})