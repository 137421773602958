<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" size="lg" :title="'Immagine mail - ' + data.image.id" scrollable @hidden="closeForm()" @close="closeForm()">
      <b-form-group label="Dominio">
        <b-form-select v-model="data.image.webdomains_id" :options="data.webdomains" value-field="id" text-field="val"/>
      </b-form-group>
      <b-form-group label="File">
        <b-form-input v-model="data.image.filename"/>
      </b-form-group>
      <b-form-group label="Carica file">
        <b-form-file @change="fileChange"/>
      </b-form-group>  
      <b-form-group label="Anteprima" v-if="data.image.id > 0">
        <b-link target="_blank" :href="'https://www.' + data.webdomains.find(x => x.id === data.image.webdomains_id ).val + '/mailsimages/' + data.image.filename">{{ 'https://www.' + data.webdomains.find(x => x.id === data.image.webdomains_id ).val + '/mailsimages/' + '/' + data.image.filename }}</b-link>
        <b-img :src="'https://www.' + data.webdomains.find(x => x.id === data.image.webdomains_id ).val + '/mailsimages/' + data.image.filename" fluid-grow></b-img>
      </b-form-group>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="success" class="mr-2" @click="saveData()">Salva</b-btn>
          <b-btn variant="danger" v-show="data.image.id > 0" @click="deleteData()">Elimina</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "mailsettingimage",
  data() {
    return {
      show: true,
      data: null,
    }
  },
  methods: {
    loadData(id){
      this.openHttp("mailssettings/image", { id: id }).then((result) => {
        this.data = result;
        this.show = true;
      })
    },
    fileChange(event){
      var reader = new FileReader();
      if (event.target.files != null){
        reader.readAsDataURL(event.target.files[0]);
        reader.onload = ()=> {
          this.data.image.filename = event.target.files[0].name;
          this.data.image.filecontent = reader.result;
        }
      } else {
        reader.readAsDataURL(event.dataTransfer.files[0]);
        reader.onload = ()=> {
          this.data.image.filename = event.dataTransfer.files[0].name;
          this.data.image.filecontent = reader.result;
        }
      }
    },
    saveData(){
      this.saveHttp("mailssettings/image/save", this.data.image).then((result) => {
        if (result !== null) this.closeForm();
      })
    },
    deleteData(){
      this.deleteHttp("mailssettings/image/delete", { id: this.data.image.id }).then((result) => {
        if (result !== null) this.closeForm();
      })
    },
    closeForm(){
      this.show = false;
      this.data = null;
      this.$emit('update');
    },
  },
}
</script>