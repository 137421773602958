<template>
    <div>
        <companymarketing ref="marketing" @update="loadData(true)"/>
        <b-btn variant="primary" @click="loadData">Aggiorna</b-btn>
        <b-table no-border-collapse :sticky-header="(winHeight - 205) + 'px'" hover responsive show-empty mode="remote" class="mt-3" :items="items.data" :fields="fields" :busy="isBusy" :no-local-sorting="true" :sort-by.sync="search.sort.by" :sort-desc.sync="search.sort.desc" @sort-changed="sortingChange" @row-clicked="$refs.marketing.loadData($event.id)">
            <template v-slot:table-busy>
                <div class="text-center text-primary">
                    <b-spinner class="align-middle"/>
                </div>
            </template>
            <template v-slot:empty>Non ci sono risultati</template>
            <template v-slot:cell(id)="row">
                <b-btn :disabled="row.item.progressive === 1" variant="info" size="sm" class="mr-2"  @click="downData(row.item.id)"><b-icon-arrow-up/></b-btn>
                <b-btn :disabled="row.item.progressive === items.total" variant="info" size="sm" @click="upData(row.item.id)"><b-icon-arrow-down/></b-btn>
            </template>
        </b-table>
        <tablebottom :busy="isBusy" :currentpage="search.page" :total="items.total" @updateCurrentPage="search.page = $event" @loadData="loadData()"/>
    </div>
</template>

<script>
import companymarketing from './companymarketing.vue';
 
export default {
    name: "companiesmarketing",
    components: { companymarketing },
    data() {
        return {
            isBusy: true,
            showFilter: false,
            search: {
                page: 1,
                sort: {
                    by: null,
                    desc: false,
                },
            },
            fields: [{
                key: "id",
                label: "",
            },{
                key: 'progressive',
                label: '#',
                sortable: true,
            },{
                key: 'company',
                label: 'Azienda',
                sortable: true,
            },{
                key: 'worker',
                label: 'Riferimento',
            },{
                key: 'date_expiry',
                label: 'Scadenza',
                sortable: true,
                formatter: value => {
                    if (value == null) return null;
                    var d = new Date(value);
                    return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
                },
            },{
                key: 'date_revaluation',
                label: 'Rivalutazione',
                sortable: true,
                formatter: value => {
                    if (value == null) return null;
                    var d = new Date(value);
                    return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
                },
            },{
                key: 'note',
                label: 'Nota',
            },{
                key: 'province',
                label: 'Provincia',
            }],
            items: {
                total: 0,
                data: [],
            }
        }
    },
    computed: {
        winHeight(){
            return this.$store.getters.getWinHeight;
        }
    },
    created(){
        this.loadData();
    },
    methods: {
        loadData( notScroll = false ){
            if (notScroll == false) this.isBusy = true;
            this.showFilter = false;
            this.openHttp("companiesmarketing_companies", this.search).then((result) => {
                this.isBusy = false;
                this.items = result;
            })
        },
        sortingChange(ctx) {
            this.search.sort.by = ctx.sortBy;
            this.search.sort.desc = ctx.sortDesc;
            this.loadData();
        },
        upData(id){
            this.saveHttp("companiesmarketing_marketing_up", { id: id }, "Azienda salvata!" ).then((result) => {
                if ( result.result == true ) this.loadData();
            })
        },
        downData(id){
            this.saveHttp("companiesmarketing_marketing_down", { id: id }, "Azienda salvata!" ).then((result) => {
                if ( result.result == true ) this.loadData();
            })
        },
    }
}
</script>