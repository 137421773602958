<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" size="xl" :title="'Dettaglio - ' + data.user.fullname + ' ' + data.user.date" scrollable @hidden="closeForm()" @close="closeForm()">
      <company ref="company"/>
      <order ref="order"/>
      <b-card no-body>        
        <b-tabs card>
          <b-tab title="Piano" active v-if="data.ordersdays.length > 0">
            <b-table no-border-collapse :sticky-header="(winHeight - 345)+ 'px'" :items="data.ordersdays" :fields="fields.ordersdays">
              <template v-slot:cell(company)="row">
                <b-link @click="$refs.company.loadData(row.item.companies_id)">{{ row.value }}</b-link>
              </template>
              <template v-slot:cell(orders_id)="row">
                <b-link @click="$refs.order.loadData(row.item.orders_id)">{{ row.value }}</b-link>
              </template>
            </b-table>
          </b-tab>
          <!--
          <b-tab title="Resa" v-if="data.orders.length > 0">
            <b-table no-border-collapse :sticky-header="(winHeight - 345)+ 'px'" :items="data.orders" :fields="fields.orders">
              <template v-slot:cell(company)="row">
                <b-link @click="$refs.company.loadData(row.item.companies_id)">{{ row.value }}</b-link>
              </template>
              <template v-slot:cell(orders_id)="row">
                <b-link @click="$refs.order.loadData(row.item.orders_id)">{{ row.value }}</b-link>
              </template>
            </b-table>
          </b-tab>
          -->
          <b-tab title="Ore" v-if="data.hours.length > 0">
            <b-table no-border-collapse :sticky-header="(winHeight - 345)+ 'px'" :items="data.hours" :fields="fields.hours">
              <template v-slot:cell(company)="row">
                <b-link @click="$refs.company.loadData(row.item.companies_id)">{{ row.value }}</b-link>
              </template>
              <template v-slot:cell(orders_id)="row">
                <b-link @click="$refs.order.loadData(row.item.orders_id)">{{ row.value }}</b-link>
              </template>
            </b-table>
          </b-tab>
          <b-tab title="Spese" v-if="data.costs.length > 0">
            <b-table no-border-collapse :sticky-header="(winHeight - 345)+ 'px'" :items="data.costs" :fields="fields.costs">
              <template v-slot:cell(company)="row">
                <b-link @click="$refs.company.loadData(row.item.companies_id)">{{ row.value }}</b-link>
              </template>
              <template v-slot:cell(orders_id)="row">
                <b-link @click="$refs.order.loadData(row.item.orders_id)">{{ row.value }}</b-link>
              </template>
            </b-table>
          </b-tab>
          <b-tab title="Telefono" v-if="data.phonecalls.length > 0">
            <linechart :height="(winHeight / 2 - 185)" ref="chartPhonecalls"/>
            <b-table no-border-collapse :sticky-header="(winHeight - 185)+ 'px'" :items="data.phonecalls" :fields="fields.phonecalls"/>
          </b-tab>
          <b-tab title="Percorso" v-if="data.paths.length > 0">
            <linechart :height="(winHeight / 2 - 185)" ref="chartPaths"/>
            <b-table no-border-collapse :sticky-header="(winHeight / 2 - 185)+ 'px'" :items="data.paths" :fields="fields.paths"/>
          </b-tab>
        </b-tabs>
      </b-card>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="primary" @click="loadData(data.users_id, data.date)">Aggiorna</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import linechart from '../linechart';

export default {
  name: "kpi",
  components: {
    linechart,
  },
  data() {
    return {
      show: true,
      data: null,
      fields: {
        ordersdays: [{
          key: 'ordersdaystype',
          label: "Tipologia",
        },{
          key: "company",
          label: "Azienda",
        },{
          key: 'orders_id',
          label: "Commessa",
        },{
          key: "services",
          label: "Servizi",
        },{
          key: "note",
          label: "Note",
        },{
          key: "morningafternoon",
          label: "Durata",
          formatter: (value, key, item) => {
            return item.morning === true && item.afternoon === true ? "Giornata" : ( item.morning === true ? "Mattina" : (item.afternoon === true ? "Pomeriggio" : "?") );
          }
        }],
        orders: [
          
        ],
        hours: [{
          key: 'company',
          label: 'Azienda',
        },{
          key: "orderscoststype",
          label: 'Tipo',
        },{
          key: 'orders_id',
          label: "Commessa"
        },{
          key: 'done',
          label: 'Ore',
          formatter: value => {
            return value || ' h.';
          },
        },{
          key: 'note',
          label: 'Note',
        }],
        costs: [{
          key: 'company',
          label: 'Azienda',
        },{
          key: "orderscoststype",
          label: 'Tipo',
        },{
          key: 'orders_id',
          label: "Commessa"
        },{
          key: 'cost',
          label: 'Spesa',
          formatter: value => {
            return value || ' €';
          },
        },{
          key: 'note',
          label: 'Note',
        }],
        phonecalls: [{
          key: "time",
          label: "Ora",
        },{
          key: "billsec",
          label: "Durata (sec)",
        },{
          key: 'phonenumber',
          label: "Telefono"
        },{
          key: 'company',
          label: "Azienda",
        },{
          key: 'companiestype',
          label: 'Tipo'
        }],
        paths: [{
          key: 'vehicle',
          label: "Auto",
        },{
          key: 'time',
          label: 'Ora',
        },{
          key: 'speed',
          label: 'Velocità',
        },{
          key: 'city',
          label: 'Città',
        },{
          key: 'province',
          label: "Provincia",
        }]
      },
      chartOptionPhonecalls: {
        responsive: true, 
        maintainAspectRatio: false,
        title: {
          display: true,
          text: "Ora",
        },
        scales: {
          yAxes: [{
            ticks: { suggestedMin: 0 }
          }]
        }
      },
      chartOptionPaths: {
        responsive: true,
        maintainAspectRatio: false,
        title: {
          display: true,
          text: "Orario",
        },
        scales: {
          yAxes: [{
            ticks: { suggestedMin: 0 }
          }]
        }
      },
      chartDataPhonecalls: {
        labels: [],
        dataset: []
      },
      chartDataPaths: {
        labels: [],
        dataset: []
      }
    }
  },
  computed: {
    winHeight (){
      return this.$store.getters.getWinHeight;
    }
  },
  methods: {
    loadData(userId, date){
      this.openHttp("kpis/kpi", { users_id: userId, date: date }).then((result) => {
        this.data = result;
        this.chartDataPhonecalls = {
          labels: this.data.phonecallschart.map(x => x.hh),
          datasets: [{
            label: "Conteggio",
            borderColor: "#0000FF",
            data: this.data.phonecallschart.map(x => x.callscount)
          },{
            label: "Minuti",
            borderColor: "#00FF00",
            data: this.data.phonecallschart.map(x => x.callssecs)
          }]
        };
        this.chartDataPaths = {
          labels: this.data.pathschart.map(x => x.tm),
          datasets: [{
            label: "Media",
            borderColor: "#0000FF",
            data: this.data.pathschart.map(x => x.avgspeed)
          },{
            label: "Max",
            borderColor: "#00FF00",
            data: this.data.pathschart.map(x => x.maxspeed)
          }]
        };

        this.show = true;
        this.$nextTick(() => {
          this.$refs.chartPhonecalls.load(this.chartDataPhonecalls, this.chartOptionPhonecalls);
          this.$refs.chartPaths.load(this.chartDataPaths, this.chartOptionPaths);
        })
      })
    },
    closeForm(){
      this.show = false;
      this.data = null;
      this.$emit('update');
    },
  },
}
</script>