<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" size="xl" :title="'Commessa - ' + data.order.id" scrollable @hidden="closeForm" @close="closeForm">
      <b-card no-body>
        <b-tabs card>
          <b-tab title="Commessa" active>
            <b-row>
              <b-col lg="6">
                <company ref="company" @update="loadData(data.order.id)"/>
                <b-form-group>
                  <b-link v-if="data.order.id > 0" @click="$refs.company.loadData(data.order.companies_id)">Azienda</b-link><label v-else>Azienda</label> 
                  <multiselect v-model="companies_idSupport" :options="data.companies" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="data.order.companies_id = updateComboSimple(companies_idSupport)" @search-change="asyncCompanies"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Ditta">
                  <b-form-select v-model="data.order.internalcompanies_id" :options="data.internalcompanies" value-field="id" text-field="val"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Stato">
                  <b-form-select v-model="data.order.ordersstates_id" :options="data.ordersstates" value-field="id" text-field="val"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Preventivo">
                  <b-form-input type='number' step='0.01' v-model="data.order.quote"/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-form-group label="Descrizione" v-if="data.order.description !== null">
              <b-form-textarea v-model="data.order.description" rows="3" disabled/>
            </b-form-group>
            <b-row>
              <b-col lg="2">
                <b-form-group label="Scadenza">
                  <b-form-input type="date" v-model="data.order.date"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Mese">
                  <b-form-select v-model="data.order.months_id" :options="data.months" value-field="id" text-field="val"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Fatture">
                  <b-form-input :value="data.invoices"/>
                </b-form-group>
              </b-col>
              <b-col lg="3">
                <b-form-group label="Ente">
                  <b-form-select v-model="data.order.certificationbodies_id" :options="data.certificationbodies" value-field="id" text-field="val"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Collaboratore">
                  <b-form-select v-model="data.order.users_id" :options="data.users" value-field="id" text-field="val"/>
                </b-form-group>
              </b-col>
              <b-col lg="1">
                <!--
                <b-modal id ="modalHoursForeceast" title="Ore Preventivate" size="lg" hide-footer>
                  <b-row>
                    <b-col lg="3" v-for="item in data.order.hoursforecast" :key="item.id">
                      <b-form-group :label="item.val">
                        <b-form-input type="number" v-model="item.forecast"/>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-modal>
                -->
                <b-form-group label="Preventivate">
                  <!--
                  <b-btn variant="outline-secondary text-left" style="width: 100%;" v-b-modal.modalHoursForeceast>{{ data.order.hoursforecast.reduce(function (a, b) { return b.forecast == null ? a * 1 : a + b.forecast * 1; }, 0) }}</b-btn>
                  -->
                  <b-form-input type='number' step='0.01' v-model="data.order.hoursestimated"/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row v-if="level >= 2">
              <b-col lg="2">
                <b-form-group label="Fattura 1">
                  <b-form-input v-model="data.order.fat1"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="N. Fattura 1">
                  <b-form-input v-model="data.order.nfat1"/>
                </b-form-group>
              </b-col>
              <b-col lg="1">
                <b-form-group label="Pagamento 1">
                  <b-form-checkbox v-model="data.order.pag1"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Fattura 2">
                  <b-form-input v-model="data.order.fat2"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="N. Fattura 2">
                  <b-form-input v-model="data.order.nfat2"/>
                </b-form-group>
              </b-col>
              <b-col lg="1">
                <b-form-group label="Pagamento 2">
                  <b-form-checkbox v-model="data.order.pag2"/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-form-group label="Note">
              <b-form-textarea v-model="data.order.note" rows="3"/>
            </b-form-group>
            <b-form-group label="Servizi">
              <orderservice ref="service" @update="loadData(data.order.id)"/>
              <b-btn variant="primary" size="sm" @click="$refs.service.loadData(data.order.id, 0)" v-if="level >= 2">Nuovo</b-btn>
              <b-table responsive :items="data.services" :fields="fields.services" class="mt-3" @row-clicked="$refs.service.loadData(data.order.id, $event.id)"/>
            </b-form-group>
          </b-tab>
          <b-tab title="Kilometri" v-if="level >= 2">
            <b-row>
              <b-col lg="2">
                <b-form-group label="Km">
                  <b-form-input type='number' step='0.01' v-model="data.order.km"/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-form-group label="Sedi">
              <b-table responsive :items="data.sites" :fields="fields.sites"/>
            </b-form-group>
          </b-tab>
          <b-tab title="Spese">
            <ordercost ref="cost" @update="loadData(data.order.id)"/>
            <b-row class="mb-2">
              <b-col lg="8">
                <b-btn variant="primary" size="sm" @click="$refs.cost.loadData([data.order.id ], 0)">Nuova</b-btn>
              </b-col>
              <b-col lg="4">
                <b-form-input v-model="fields.costCriteria" size="sm" placeholder="Filtra..."/>
              </b-col>
            </b-row>
            <b-table responsive :sticky-header="(winHeight - 375) + 'px'" :items="data.costs" :fields="fields.costs" @row-clicked="$refs.cost.loadData([data.order.id ], $event.id)" :filter="fields.costCriteria"/>
          </b-tab>
          <b-tab title="Ore">
            <orderhour ref="hour" @update="loadData(data.order.id)"/>
            <b-row class="mb-2">
              <b-col lg="8">
                <b-btn variant="primary" size="sm" @click="loadHour(0)">Nuova</b-btn>
              </b-col>
              <b-col lg="4">
                <b-form-input v-model="fields.hourCriteria" size="sm" placeholder="Filtra..."/>
              </b-col>
            </b-row>
            <b-table responsive :sticky-header="(winHeight - 375) + 'px'" :items="data.hours" :fields="fields.hours" @row-clicked="loadHour($event.id)" :filter="fields.hourCriteria"/>
          </b-tab>
          <b-tab title="Email">
            <mailnew ref="mailnew" @update="loadData(data.order.id)"/>
            <mail ref="mail" @update="loadData(data.order.id)"/>
            <b-row class="mb-2">
              <b-col lg="8">
                <b-btn variant="primary" size="sm" @click="loadHour(0)">Nuova</b-btn>
              </b-col>
              <b-col lg="4">
                <b-form-input v-model="fields.mailsCriteria" size="sm" placeholder="Filtra..."/>
              </b-col>
            </b-row>            
            <b-table :sticky-header="(winHeight - 375) + 'px'" :tbody-tr-class="rowClassMails" responsive :items="data.mails" :fields="fields.mails" @row-clicked="$refs.mail.loadData($event.id)" :filter="fields.mailsCriteria"/>
          </b-tab>
        </b-tabs>
      </b-card>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="primary" v-show="data.order.id > 0" class="mr-2" @click="loadData(data.order.id)">Aggiorna</b-btn>
          <b-btn variant="success" class="mr-2" @click="saveData">Salva</b-btn>
          <b-btn variant="danger" class="mr-2" v-show="level >= 2 && data.order.id > 0" @click="deleteData">Elimina</b-btn>
          <b-btn variant="warning" v-show="level >= 2 && data.order.id > 0" @click="duplicateData">Duplica</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import orderservice from './orderscomponents/orderservice.vue';

export default {
  name: "order",
  components: { orderservice },
  data() {
    return {
      show: true,
      data: null,
      companies_idSupport: [],
      fields: {
        services: [{
          key: 'service',
          label: "Servizio",
        },{
          key: 'note',
          label: 'Note',
        }],
        costCriteria: null,
        costs: [{
          key: 'date',
          label: "Data",
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          }
        },{
          key: "user",
          label: "Collaboratore",
        },{
          key: 'type',
          label: "Tipologia",
        },{
          key: 'cost',
          label: "Spesa",
        },{
          key: 'note',
          label: "Note",
        },{
          key: 'refund',
          label: "Rimborso",
        },{
          key: 'refunded',
          label: "Rimborsato",
        },{
          key: 'refundnote',
          label: "Note Rimborso",
        }],
        hourCriteria: null,
        hours: [{
          key: 'date',
          label: "Data",
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          }
        },{
          key: 'user',
          label: 'Collaboratore',
        },{
          key: 'type',
          label: "Tipologia",
        },{
          key: 'done',
          label: "Ore",
        },{
          key: 'note',
          label: "Note",
        }],
        mailsCriteria: null,
        mails: [{
          key: 'folder',
          label: "Cartella",
          sortable: true
        },{
          key: 'from',
          label: 'Da',
        },{
          key: 'to',
          label: 'A',
        },{
          key: 'subject',
          label: "Oggetto",
        },{
          key: 'datetime',
          label: "Data",
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            const today = new Date();
            var d = new Date(value);
            if ( d.getDate() == today.getDate() && d.getMonth() == today.getMonth() && d.getFullYear() == today.getFullYear()){
              return [ d.getHours(), d.getMinutes() ].map(n => n < 10 ? `0${n}` : `${n}`).join(':');
            } else {
              return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
            }
          },
        }],
        sites: [{
          key: 'address',
          label: "Indirizzo",
        },{
          key: 'city',
          label: 'Città',
        },{
          key: 'postalcode',
          label: "CAP",
        },{
          key: 'province',
          label: "Provincia",
        },{
          key: 'distancefrommadone',
          label: "Distanza da madone",
        },{
          key: 'durationfrommadone',
          label: "Tempo da madone",
        }]
      }
    }
  },
  computed: {
    winHeight (){
      return this.$store.getters.getWinHeight;
    },
    level(){
      return this.$store.getters.getRoutes.find(x => x.route === "orders").level;
    }
  },
  methods: {
    rowClassMails(item) {
      if (item !== null) return item.seen === false ? "table-primary" : (item.ping === true ? "table-success" : "");
    },
    loadData( id ){
      this.fields.costCriteria = null;
      this.fields.hourCriteria = null;
      this.fields.mailsCriteria = null;
      if (this.data === null) this.show = false;
      this.openHttp("orders/order", { id: id }).then((result) => {
        this.companies_idSupport = this.convertComboSimple(result.companies, result.order.companies_id);
        this.data = result;
        this.show = true;
      })
    },
    asyncCompanies (query) {
      if (query.length > 3){
        this.openHttp("orders/order/companies", { search: query }).then((result) => {
          for ( let item of result ) if ( this.data.companies.findIndex( x => x.id === item.id ) === -1 ) this.data.companies.push(item);
          this.data.companies = this.data.companies.sort((a,b) => ( a.val > b.val ) ? 1 : 0 ); 
        })
      }
    },
    loadHour(id){
      this.$refs.hour.loadData( [ this.data.order.id ], id );
    },
    saveData(){
      this.saveHttp("orders/order/save", this.data.order).then((result) => {
        if ( result !== null ) this.loadData( result );
      })
    },
    deleteData(){
      this.deleteHttp("orders/order/delete", { id: this.data.order.id }).then((result) => {
        if ( result !== null ) this.closeForm();
      })
    },
    duplicateData(){
      this.saveHttp("orders/order/duplicate", { id: this.data.order.id }).then((result) => {
        if ( result !== null ) this.loadData(result);
      })
    },
    closeForm(){
      this.show = false;
      this.data = null;
      this.$emit('update');
    },
  },
}
</script>