<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" :title="'Mail Alias - ' + data.mailboxalias.id" scrollable @hidden="closeForm" @close="closeForm">
      <b-form-group label="Dominio">
        <b-form-select v-model="data.mailboxalias.webdomains_id" :options="data.webdomains" value-field="id" text-field="val"/>
      </b-form-group>
      <b-form-group label="Origine">
        <b-form-input v-model="data.mailboxalias.source"/>
      </b-form-group>
      <b-form-group label="Destinazione">
        <b-form-select v-model="data.mailboxalias.mailboxes_id" :options="data.mailboxes" value-field="id" text-field="val"/>
      </b-form-group>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="success" class="mr-2" @click="saveData()">Salva</b-btn>
          <b-btn variant="danger" v-show="data.mailboxalias.id > 0" @click="deleteData()">Elimina</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "mailboxalias",
  data() {
    return {
      show: true,
      data: null,
    }
  },
  methods: {
    loadData(id){
      this.openHttp("users/mailboxalias", { id: id }).then((result) => {
        this.data = result;
        this.show = true;
      })
    },
    saveData(){
      this.saveHttp("users/mailboxalias/save", this.data.mailboxalias).then((result) => {
         console.log(result);
        if (result == true) this.closeForm();
      })
    },
    deleteData(){
      this.deleteHttp("users/mailboxalias/delete", { id: this.data.mailboxalias.id }).then((result) => {
        if (result == true) this.closeForm();
      })
    },
    closeForm(){
      this.show = false;
      this.data = null;
      this.$emit('update');
    },
  },
}
</script>