<template>
    <div>
        <b-modal v-if="data!= null" v-model="show" size="lg" :title="'Decision - ' + data.decision.id">
            <b-row>
                <b-col lg="4">
                    <b-form-group label="Certification manager">
                        <b-form-select v-model="data.decision.users_id1" :options="addNullCombo(data.users1, 'Completare ...')" value-field="id" text-field="val"/>
                    </b-form-group>
                </b-col>
                <b-col lg="2">
                    <b-form-group label="Data">
                        <b-form-input type="date" v-model="data.decision.date1"/>
                    </b-form-group>
                </b-col>
                <b-col lg="2">
                    <b-form-group label="Decisione">
                        <b-form-select v-model="data.decision.auditsdecisionstypes_id1" :options="addNullCombo(data.decisionstypes, 'Completare ...')" value-field="id" text-field="val"/>
                    </b-form-group>
                </b-col>
                <b-col lg="4">
                    <b-form-group label="Note">
                        <b-form-textarea v-model="data.decision.note1" rows="3" max-rows="6"/>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col lg="4">
                    <b-form-group label="Lead auditor">
                        <b-form-select v-model="data.decision.users_id2" :options="addNullCombo(data.users2, 'Completare ...')" value-field="id" text-field="val"/>
                    </b-form-group>
                </b-col>
                <b-col lg="2">
                    <b-form-group label="Data">
                        <b-form-input type="date" v-model="data.decision.date2"/>
                    </b-form-group>
                </b-col>
                <b-col lg="2">
                    <b-form-group label="Decisione">
                        <b-form-select v-model="data.decision.auditsdecisionstypes_id2" :options="addNullCombo(data.decisionstypes, 'Completare ...')" value-field="id" text-field="val"/>
                    </b-form-group>
                </b-col>
                <b-col lg="4">
                    <b-form-group label="Note">
                        <b-form-textarea v-model="data.decision.note2" rows="3" max-rows="6"/>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col lg="4">
                    <b-form-group label="Veto">
                        <b-form-select v-model="data.decision.users_id3" :options="addNullCombo(data.users3, 'Completare ...')" value-field="id" text-field="val"/>
                    </b-form-group>
                </b-col>
                <b-col lg="2">
                    <b-form-group label="Data">
                        <b-form-input type="date" v-model="data.decision.date3"/>
                    </b-form-group>
                </b-col>
                <b-col lg="2">
                    <b-form-group label="Decisione">
                        <b-form-select v-model="data.decision.auditsdecisionstypes_id3" :options="addNullCombo(data.decisionstypes, 'Completare ...')" value-field="id" text-field="val"/>
                    </b-form-group>
                </b-col>
                <b-col lg="4">
                    <b-form-group label="Note">
                        <b-form-textarea v-model="data.decision.note3" rows="3" max-rows="6"/>
                    </b-form-group>
                </b-col>
            </b-row>
            <template v-slot:modal-footer>
                <div class="w-100">
                    <b-btn variant="success" @click="saveData()">Salva</b-btn>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
export default {
    name: "decision",
    data() {
        return {
            data: null,
            show: true,
            resultCombo:[{
                id: null,
                val: "In corso"
            },{
                id: true,
                val: "Approvato"
            },{
                id: false,
                val: "Non approvato"
            }]
        }
    },
    methods: {
        loadData(auditsId, id){
            this.openHttp2("audits/decisions/decision", { audits_id: auditsId, id: id }).then((result) => {
                this.data = result;
                this.show = true;
            })
        },
        saveData(){
            this.saveHttp2("audits/decisions/decision/save", this.data.decision).then((result) => {
                if (result !== null) this.closeForm();
            })
        },
        closeForm(){
            this.show = false;
            this.data = null;
            this.$emit("update");
        },
    },
}
</script>