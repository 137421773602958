<template>
  <div>
    <incomeinvoice ref="incomeinvoice" @update="loadInvoices()"/>
    <b-modal v-if="invoices.showFilter" v-model="invoices.showFilter" title="Filtra">
      <b-form-group label="Riferimento">
        <b-form-input v-model="invoices.tempFilter.refinvoice"/>
      </b-form-group>
      <b-form-group label="Da">
        <b-form-input type="date" v-model="invoices.tempFilter.from"/>
      </b-form-group>
      <b-form-group label="A">
        <b-form-input type="date" v-model="invoices.tempFilter.to"/>
      </b-form-group>
      <b-form-group label="Azienda">
        <b-form-select v-model="invoices.tempFilter.companies_id" :options="addNullCombo(invoices.filtersMenu.companies)" value-field="id" text-field="val"/>
      </b-form-group>
      <b-form-group label="Ditta">
        <b-form-select v-model="invoices.tempFilter.internalcompanies_id" :options="addNullCombo(invoices.filtersMenu.internalcompanies)" value-field="id" text-field="val"/>
      </b-form-group>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="primary" class="mr-2" @click="filterSetInvoices">Filtra</b-btn>
          <b-btn variant="warning" @click="filterResetInvoices">Reset</b-btn>
        </div>
      </template>
    </b-modal>
    <b-btn class="mr-2" variant="primary" @click="loadInvoices">Aggiorna</b-btn>
    <b-btn class="mr-2" variant="secondary" @click="filterOpenInvoices">Filtra</b-btn>
    <b-table no-border-collapse :sticky-header="(winHeight - 305) + 'px'" hover responsive show-empty mode="remote" class="mt-3" :items="invoices.items.data" :fields="invoices.fields" :busy="invoices.isBusy" :no-local-sorting="true" :sort-by.sync="invoices.search.sortby" :sort-desc.sync="invoices.search.sortdesc" @sort-changed="sortingChangeInvoices" @row-clicked="$refs.incomeinvoice.loadData($event.id)">
      <template v-slot:table-busy>
        <div class="text-center text-primary">
          <b-spinner class="align-middle"/>
        </div>
      </template>
      <template v-slot:empty>Non ci sono risultati</template>
    </b-table>
    <tablebottom :busy="invoices.isBusy" :currentpage="invoices.search.page" :total="invoices.items.total" @updateCurrentPage="invoices.search.page = $event" @loadData="loadInvoices" :extraText="'- Totale: ' + invoices.items.total_total"/>
  </div>
</template>

<script>
export default {
  name: "incomeinvoices",
  data() {
    return {
      invoices: {
        isBusy: true,
        showFilter: false,
        search: {
          page: 1,
          filters: {
            refinvoice: null,
            from: null,
            to: null,
            companies_id: null,
            internalcompanies_id: null,
          },
          sortby: null,
          sortdesc: false,
        },
        tempFilter: {
          refinvoice: null,
          from: null,
          to: null,
          companies_id: null,
          internalcompanies_id: null,
        },
        filtersMenu: {
          companies:[],
          internalcompanies:[],
        },
        fields: [{
          key: 'internalcompany',
          label: 'Ditta',
          sortable: true,
        },{
          key: 'company',
          label: 'Azienda',
          sortable: true,
        },{
          key: "refinvoice",
          label: 'Fattura',
          sortable: true,
        },{
          key: 'date',
          label: 'Data',
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          },
        },{
          key: 'total',
          label: 'Totale',
        }],
        items: {
          total: 0,
          data: [],
          total_total: 0,
        }
      }
    }
  },
  computed: {
    winHeight (){
      return this.$store.getters.getWinHeight;
    },
  },
  created(){
    this.loadInvoices();
  },
  methods: {
    loadInvoices(){
      this.invoices.isBusy = true;
      this.openHttp("incomeinvoices/", this.invoices.search).then((result) => {
        this.invoices.items = result;
        this.invoices.isBusy = false;
      })
    },
    filterOpenInvoices(){
      this.openHttp("incomeinvoices/filters", null).then((result) => {
        this.invoices.filtersMenu = result;
        this.invoices.showFilter = true;
      })
    },
    filterSetInvoices(){
      this.invoices.showFilter = false;
      this.invoices.search.page = 1;
      this.invoices.search.filters = this.invoices.tempFilter;
      this.loadInvoices();
    },
    filterResetInvoices(){
      this.invoices.tempFilter = {
        refinvoice: null,
        from: null,
        to: null,
        companies_id: null,
        internalcompanies_id: null,
      }
      this.filterSetInvoices();
    },
    sortingChangeInvoices(ctx) {
      this.invoices.search.sortby = ctx.sortBy;
      this.invoices.search.sortdesc = ctx.sortDesc;
      this.loadInvoices();
    },
  }
}
</script>