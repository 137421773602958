<template>
    <div>
        <b-modal v-if="data!== null" v-model="show" :title="'Audit - ' + data.audits_id" size="lg">
            <zzzmigratecontract ref="migratecontract"/>
            <company ref="company"/>
            <contract ref="contract"/>
            <audit ref="audit"/>
            <b-row>
                <b-col lg="4">
                    <b-link @click="$refs.company.loadData(data.contract.companies_id)">{{ data.contract.company }}</b-link><br>
                    <b-link @click="$refs.contract.loadData(data.contract.contracts_id)">Contratto {{ data.contract.contract }}</b-link><br>
                    <b-link @click="$refs.audit.loadData(data.audits_id)">Audit {{ data.audits_id }}</b-link><br>
                    {{ data.contract }}
                </b-col>
                <b-col lg="8">
                    <b-table responsive :items="data.usersrolesdates" :fields="fields">
                        <template v-slot:cell(auditsstandardsphases_id)="row">
                            <b-form-select size="sm" v-model="row.item.auditsstandardsphases_id" :options="data.standardsphases" value-field="id" text-field="val"/>
                        </template>
                        <template v-slot:cell(users_id)="row">
                            <b-form-select size="sm" v-model="row.item.users_id" :options="data.users" value-field="id" text-field="val"/>
                        </template>
                        <template v-slot:cell(usersroles_id)="row">
                            <b-form-select size="sm" v-model="row.item.usersroles_id" :options="data.usersroles" value-field="id" text-field="val"/>
                        </template>
                        <template v-slot:cell(date)="row">
                            <b-form-input size="sm" type="date" v-model="row.item.date"/>
                        </template>
                        <template v-slot:cell(manday)="row">
                            <b-form-input size="sm" type="number" v-model="row.item.manday" step="0.00001" min="0"/>
                        </template>
                        <template v-slot:cell(delete)="row">
                            <b-btn size="sm" variant="danger" @click="data.usersrolesdates.splice(data.usersrolesdates.findIndex( x => x.id === row.item.id ), 1)">Elimina</b-btn>
                        </template>
                    </b-table>
                </b-col>
            </b-row>
            <template v-slot:modal-footer>
                <div class="w-100">
                    <b-btn variant="success" class="mr-2" @click="saveData">Salva</b-btn>
                    <b-btn variant="warning" @click="zzzmigratecontract.loadData(data.contracts_id)">Merge contract</b-btn>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
import zzzmigratecontract from './zzzmigratecontract.vue';

export default {
    name: "auditcalendardate",
    components: {
        zzzmigratecontract
    },
    data() {
        return {
            data: null,
            show: true,
            fields: [{
                key: "auditsstandardsphases_id",
                label: "Norma / Fase",
            },{
                key: "users_id",
                label: "Persona"
            },{
                key: "usersroles_id",
                label: "Ruolo",
            },{
                key: "date",
                label: "Data",
            },{
                key: "manday",
                label: "gg/uomo",
            },{
                key: "delete",
                label: "",
            }]
        }
    },
    methods: {
        loadData(id){
            this.openHttp("zzz/audit", { id: id } ).then((result) => {
                this.data = result
                this.show = true;
            })
        },
        saveData(){
            this.saveHttp("zzz/audit/save", { audits_id: this.data.audits_id, usersrolesdates: this.data.usersrolesdates }).then((result) => {
                if ( result !== null ){
                    this.$emit("update");
                    this.show = false;
                }
            })
        },
    },
}
</script>