<template>
    <div>
        <b-modal v-if="data !== null" v-model="show" :title="'Errore - ' + data.contracterror.id" size="lg" scrollable @hidden="closeForm()" @close="closeForm()">
            <b-form-group label="Nome">
                <b-form-input v-model="data.contracterror.errorname"/>
            </b-form-group>
            <b-form-group label="SQL">
                <b-form-textarea v-model="data.contracterror.errorsql" rows="5"/>
            </b-form-group>
            <template v-slot:modal-footer>
                <div class="w-100">
                    <b-btn variant="success" class="mr-2" @click="saveData">Salva</b-btn>
                    <b-btn variant="danger" v-show="data.contracterror.id > 0" @click="deleteData">Elimina</b-btn>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
export default {
    name: "contracterror",
    data() {
        return {
            show: false,
            data: null
        }
    },
    methods: {
        loadData(id){
            this.openHttp("standards/contracterror", { id: id }).then((result) => {
                this.data = result;
                this.show = true;
            })
        },
        saveData(){
            this.saveHttp("standards/contracterror/save", this.data.contracterror).then((result) => {
                if ( result !== null ) this.closeForm();
            })
        },
        deleteData(){
            this.deleteHttp("standards/contracterror/delete", { id: this.data.contracterror.id }).then((result) => {
                if ( result !== null ) this.closeForm();
            })
        },
        closeForm(){
            this.show = false;
            this.data = null;
            this.$emit('update');
        },
    },
}
</script>