<template>
    <div>
        <order ref="order"/>
        <company ref="company"/>
        <kpi ref="kpi"/>
        <b-btn variant="primary" class="mb-2" @click="loadData()">Aggiorna</b-btn>
        <b-card no-body>
            <b-tabs card>
                <b-tab v-for="(item, index) of data" :key="index" :title="item.fullname">
                    <b-row>
                        <b-col v-for="(item2, index2) of item.dates" :key="index2" :lg="(item2.dow !== 6 && item2.dow !== 0) ? 2 : 1">
                            <b-card :title="item2.datesimple + ' ' + mandays.find(x => item2.dow === x.id).val">
                                <b-card-text>
                                    <div v-show="item2.ordersdays.length > 0">
                                        <strong>Commesse:</strong>
                                        <div v-for="(item3, index3) of item2.ordersdays" :key="index3">
                                            <b-link @click="$refs.company.loadData(item3.companies_id)">{{ item3.company }}</b-link> {{ item3.ordersdaystype }}: <b-link @click="$refs.order.loadData(item3.orders_id)">{{ item3.orders_id }}</b-link> <span v-show="item3.ordersdaystype !== null">{{ item3.ordersdaystype }}</span> <span v-show="item3.note !== null">{{ item3.note }}</span>
                                        </div>
                                    </div>
                                    <div v-show="item2.hours.length > 0">
                                        <strong>Ore:</strong>
                                        <div v-for="(item3, index3) of item2.hours" :key="index3">
                                            {{ item3.type + ' ' + item3.hour + 'h' }}
                                        </div>
                                    </div>
                                    <div v-show="item2.costs.length > 0">
                                        <strong>Costi:</strong>
                                        <div v-for="(item3, index3) of item2.costs" :key="index3">
                                            {{ item3.type + ' ' + item3.cost + '€' }}
                                        </div>
                                    </div>
                                    <div v-show="item2.vehicles.length > 0">
                                        <strong>Automezzi:</strong>
                                        <div v-for="(item3, index3) of item2.vehicles" :key="index3">
                                            {{ item3.vehicle }}: {{ item3.mintime }} - {{ item3.maxtime }}
                                        </div>
                                    </div>
                                    <div v-show="item2.phonecalls.length > 0">
                                        <strong>Telefonate:</strong>
                                        <div v-for="(item3, index3) of item2.phonecalls" :key="index3">
                                            {{ item3.companiestype }}: tot: {{ item3.cntcalls }} - min: {{ item3.sumcalls }}
                                        </div>
                                    </div>
                                </b-card-text>
                                <b-link class="card-link" @click="$refs.kpi.loadData(item.id, item2.date)">Dettagli</b-link>
                            </b-card>
                        </b-col>
                    </b-row>
                </b-tab>
            </b-tabs>
        </b-card>
    </div>
</template>

<script>
import kpi from './kpi.vue';

export default {
    name: "kpis",
    components: { kpi },
    data() {
        return {
            data: [],
            mandays: [{
                id: 1,
                val: "Lun"
            },{
                id: 2,
                val: "Mar"
            },{
                id: 3,
                val: "Mer"
            },{
                id: 4,
                val: "Gio"
            },{
                id: 5,
                val: "Ven"
            },{
                id: 6,
                val: "Sab"
            },{
                id: 0,
                val: "Dom"
            }],
            fields:{
                orderData: [{
                    key: "date",
                    label: "Data",
                    sortable:true,
                    formatter: value => {
                        if (value == null) return null;
                        var d = new Date(value);
                        return [ d.getDate(), d.getMonth() + 1].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
                    },
                },{
                    key: "ordersdays",
                    label: "Piano"
                },{
                    key: "vehicles",
                    label: "Auto",
                },{
                    key: "hours",
                    label: "Ore",
                    formatter: value => {
                        if (value == null || value.length === 0) return "0 h";
                        return value.map(x => x.hour).reduce((accumulator, currentValue) => accumulator + currentValue) + " h";
                    },
                },{
                    key: "costs",
                    label: "Spese",
                    formatter: value => {
                        if (value == null || value.length === 0) return "0 €";
                        return value.map(x => x.cost).reduce((accumulator, currentValue) => accumulator + currentValue) + " €";
                    },
                },{
                    key: "show_details",
                    label: ""
                }],
                ordersdays: [{
                    key: "type",
                    label: "Tipo"
                },{
                    key: "orders_id",
                    label: "Commessa"
                },{
                    key: "company",
                    label: "Azienda"
                },{
                    key: "services",
                    label: "Servizi"
                }],
                phonecalls : [{
                    key: "date",
                    label: "Data",
                    sortable:true,
                    formatter: value => {
                        if (value == null) return null;
                        var d = new Date(value);
                        return [ d.getDate(), d.getMonth() + 1].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
                    },
                },{
                    key: "minutes",
                    label: "Minuti"
                },{
                    key: "show_details",
                    label: ""
                }],
                phonecallsDetails : [{
                    key: "time",
                    label: "time",
                    sortable:true,
                },{
                    key: "billsec",
                    label: "Durata (sec)"
                },{
                    key: "phonenumber",
                    label: "Numero"
                },{
                    key: "company",
                    label: "Azienda"
                }]
            }
        }
    },
    created() {
        this.loadData();
    },
    methods: {
        loadData() {
            this.openHttp("kpis", {}).then((result) => {
                this.data = result;
            })
        }
    }
}
</script>