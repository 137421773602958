<script>
import { Line } from 'vue-chartjs'

export default {
    name: "linechart",
    extends: Line,

    computed: {
        winHeight(){
            return this.$store.getters.getWinHeight;
        },
        winWidth(){
            return this.$store.getters.getWinH;
        }
    },
    methods: {
        load( chartdata, options ){
            this.renderChart(chartdata, options);
        },
        print() {
            const finalHeight = 1000 / this.winWidth * this.winHeight;            
            var canvasEle = document.getElementById('line-chart');
            var win = window.open('', 'Print', 'height=' + finalHeight + ',width=1000');
            win.document.write('<style>@page{size:landscape;}</style><html><head><title></title>');
            win.document.write('</head><body >');
            win.document.write("<img width=1000 height=" +  finalHeight + " src='" + canvasEle.toDataURL() + "' />");
            win.document.write('</body></html>');
            setTimeout(function(){
                win.document.close();
                win.focus()
                win.print();
            }, 200);  
        },
    }
}
</script>