<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" size="xl" :title="'Educazione - ' + data.education.id" @hidden="closeForm()" @close="closeForm()">
      <b-card no-body>
        <b-tabs card>
          <b-tab title="Scheda" active>
            <b-row>
              <b-col lg="4">
                <b-form-group label="Inizio">
                  <b-form-input v-model="data.education.fromdate" type="date"/>
                </b-form-group>
              </b-col>
              <b-col lg="4">
                <b-form-group label="Fine">
                  <b-form-input v-model="data.education.todate" type="date"/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-form-group label="Scuola">
              <b-form-input v-model="data.education.school"/>
            </b-form-group>
            <b-form-group label="Disciplina">
              <b-input-group class="mt-2" v-for="item of data.education.languages" :key="item.id" :prepend="item.lang">
                <b-form-input v-model="item.discipline"/>
              </b-input-group>
            </b-form-group>
            <b-form-group label="Aree">
              <multiselect v-model="standardsareasSupport" :options="data.standardsareas" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="data.education.standardsareas = updateCombo(standardsareasSupport)"/>
            </b-form-group>
            <b-form-group label="Titolo">
              <b-input-group class="mt-2" v-for="item of data.education.languages" :key="item.id" :prepend="item.lang">
                <b-form-input v-model="item.title"/>
              </b-input-group>
            </b-form-group>
            <b-form-group label="IAFs">
              <multiselect v-model="nacesiafcodesSupport" :options="data.nacesiafcodes" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="data.education.nacesiafcodes = updateCombo(nacesiafcodesSupport)"/>
            </b-form-group>
          </b-tab>
          <b-tab :title="'Documenti (' + data.documents.length + ')'"  v-if="data.education.id">
            <curriculumdocument ref="document" @update="loadData(data.education.users_id, data.education.id)"/>
            <b-btn variant="primary" @click="loadDocument(0)">Nuovo</b-btn>
            <b-table no-border-collapse :sticky-header="(winHeight - 380) + 'px'" responsive class="mt-3" :items="data.documents" :fields="fields.documents" @row-clicked="loadDocument($event.id)">
              <template v-slot:cell(id)="row">
                <b-btn variant="primary" size="sm" @click="$refs.document.downloadData(row.item.id)">Scarica</b-btn>
              </template>
            </b-table>
          </b-tab>
        </b-tabs>
      </b-card>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="primary" v-show="data.education.id > 0" class="mr-2" @click="loadData(data.education.users_id, data.education.id)">Aggiorna</b-btn>
          <b-btn variant="success" class="mr-2" @click="saveData">Salva</b-btn>
          <b-btn variant="danger" v-show="data.education.id > 0" @click="deleteData">Elimina</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import curriculumdocument from './curriculumdocument.vue';

export default {
  name: "curriculumeducation",
  components: { curriculumdocument },
  data() {
    return {
      show: false,
      data: null,
      filterNaces: null,
      nacesiafcodesSupport: [],
      fields: {
        documents: [{
          key: "filename",
          label: "File",
          sortable: true,
        },{
          key: "datetime",
          label: "Aggiornamento",
          formatter: value => {
            let d = new Date(value);
            return [ d.getHours(), d.getMinutes() ].map(n => n < 10 ? `0${n}` : `${n}`).join(':') + " " + [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          },
          sortable: true,
        },{
          key: "locked",
          label: "Bloccato",
          formatter: value => {
            return value === true ? "Bloccato" : "";
          },
        },{
          key: "id",
          label: "",
        }]
      }
    }
  },
  computed: {
    winHeight(){
      return this.$store.getters.getWinHeight;
    },
  },
  methods: {
    rowClassDocuments(item) {
      return item !== null && item.check === false ? 'table-danger' : '';
    },
    addRemoveNace(naceId){
      if (this.data.education.nacessupers.includes(naceId)){
        this.data.education.nacessupers.splice(this.data.education.nacessupers.indexOf(naceId),1);
      } else {
        this.data.education.nacessupers.push(naceId)
      }
    },
    loadData(usersId, id){
      this.openHttp("curricula/education", { id: id, users_id: usersId } ).then((result) => {
        this.filterNaces = null;
        this.standardsareasSupport = this.convertCombo(result.standardsareas, result.education.standardsareas);
        this.nacesiafcodesSupport = this.convertCombo(result.nacesiafcodes, result.education.nacesiafcodes);
        this.data = result;
        this.show = true;
      })
    },
    loadDocument(fileName){
      this.$refs.document.loadData("education", this.data.education.id, fileName);
    },
    saveData(){
      this.saveHttp("curricula/education/save", this.data.education).then((result) => {
        if ( result !== true ) this.closeForm(true);
      })
    },
    deleteData(){
      this.deleteHttp("curricula/education/delete", { id: this.data.education.id }).then((result) => {
        if ( result !== true ) this.closeForm(true);
      })
    },
    closeForm( reload = false ){
      this.data = null;
      this.show = false;
      if (reload === true) this.$emit("update");
    }
  }
}
</script>