<template>
  <div>
    <b-modal id="modal-signature" v-if="data !== null" v-model="show" :title="'Firma - ' + data.id" @hidden="closeForm()" @close="closeForm()">
      <b-row>
        <b-col>
          <b-form-group label="Firma qui">
            <VueSignaturePad v-if="showSignature" id="signature" width="760px" height="400px" ref="signature" :options="{ onEnd }" :customStyle="{ border: 'black 3px solid' }"/>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Scannerizza qui">
            <qrcode :value="'https://private.eurocertifications.it/#/externalsignature/' + data.token" :options="{ width: 175 }"></qrcode>
          </b-form-group>
          <b-form-group>
            Oppure <b-link :href="'/#/externalsignature/' + data.token" target="_blank">Clicca Qui</b-link>
          </b-form-group>
          <b-btn variant="info" class="mr-2" @click="openTutorial">Video Tutorial firme</b-btn>
        </b-col>
       </b-row>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="success" class="mr-2" @click="saveData">{{ translate('save') }}</b-btn>          
          <b-btn variant="warning" class="mr-2" v-show="data.signature !== null" @click="cleanData">Pulisci</b-btn>
          <b-btn variant="danger" v-show="data.signature !== null" @click="deleteData">{{ translate('delete') }}</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "checklistsignature",
  data() {
    return {
      show: false,
      data: null,
      showSignature: false,
    }
  },
  mounted() {
    window.addEventListener('resize', this.onResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    onResize(){
      if (this.showSignature === true){
        this.$refs.signature.clearSignature();
        this.launchSignature();
      }
    },
    onEnd(){
      const { isEmpty, data } = this.$refs.signature.saveSignature();
      if ( isEmpty === false ) this.data.signature = data;
    },
    launchSignature(){
      this.$refs.signature.fromDataURL(this.data.signature, {width: 760, height: 400});      
    },
    openTutorial(){
      window.open('https://youtu.be/uvkivZKqy74', '_blank');
    },
    loadData( id, auditsId ){
      this.showSignature = false;
      this.show = false;
      this.openHttp("checklists/signature", { id: id, audits_id: auditsId }).then((result) => {
        this.data = result;
        this.show = true;
        var self = this;
        setTimeout(function(){
          self.showSignature = true;
          setTimeout(function(){ 
            self.launchSignature();
          }, 300);
        }, 300);
      })
    },
    cleanData(){
      this.$refs.signature.clearSignature();
    },
    saveData(){
      this.saveHttp("checklists/signature/save", this.data).then((result) => {
        if ( result !== null ) this.closeForm();
      })
    },
    deleteData(){
      this.deleteHttp("checklists/signature/delete", { id: this.data.id, audits_id: this.data.audits_id }).then((result) => {
        if ( result !== null ) this.closeForm();
      })
    },
    closeForm(){
      this.show = false;
      this.data = null;
      this.$emit('update');
    },
  },
}
</script>

<style>
  #modal-signature > .modal-dialog {
    min-height: 600px;
    min-width: 1200px;
  }
</style>