<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" title="Servizio" size="lg" @hidden="closeForm" @close="closeForm">
      <b-form-group label="Servizio">
        <multiselect v-model="services_idSupport" :options="data.services" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="data.service.services_id = updateComboSimple(services_idSupport)" :disabled="data.services.length === 1"/>
      </b-form-group>
      <b-form-group label="Note in fattura">
        <b-form-textarea v-model="data.service.note" rows="4"/>
      </b-form-group>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="success" class="mr-2" @click="saveData">Salva</b-btn>
          <b-btn variant="danger" v-show="data.services.length === 1" @click="deleteData">Elimina</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>

export default {
  name: "orderservice",
  data() {
    return {
      show: false,
      data: null,
      services_idSupport: []
    }
  },
  methods: {
    loadData( orderId, id ){
      this.show = false;
      this.openHttp("orders/service", { orders_id: orderId, id: id }).then((result) => {
        this.services_idSupport = this.convertComboSimple(result.services, result.service.services_id);
        this.data = result;
        this.show = true;
      })
    },
    saveData(){
      this.saveHttp("orders/service/save", this.data.service).then((result) => {
        if ( result !== null ) this.closeForm();
      })
    },
    deleteData(){
      this.deleteHttp("orders/service/delete", { id: this.data.service.services_id, orders_id: this.data.service.orders_id }).then((result) => {
        if ( result !== null ) this.closeForm();
      })
    },
    closeForm(){
      this.data = null;
      this.show = false;
      this.$emit('update');      
    }
  }
}
</script>