<template>
  <div>
    <b-navbar toggleable="lg" type="dark" variant="primary">
      <b-navbar-brand to="/homepage" v-show="checkRoute(['homepage'])">Gestionale</b-navbar-brand>
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <b-nav-item-dropdown text="Aziende" v-show="checkRoute([ 'companies', 'marketingmuller', 'companiesmarketing', 'companiesmaps', 'marketing', 'companiessettings', 'webstats'])">
            <b-dropdown-item to="/companies" v-show="checkRoute([ 'companies' ])">Aziende</b-dropdown-item>
            <b-dropdown-item to="/marketingmuller" v-show="checkRoute([ 'marketingmuller' ])">Marketing Muller</b-dropdown-item>
            <b-dropdown-item to="/companiesmarketing" v-show="checkRoute([ 'companiesmarketing' ])">Marketing</b-dropdown-item>
            <b-dropdown-item to="/companiesmaps" v-show="checkRoute([ 'companiesmaps' ])">Mappa</b-dropdown-item>
            <b-dropdown-item to="/marketing" v-show="checkRoute([ 'marketing' ])">Mail Marketing</b-dropdown-item>
            <b-dropdown-item to="/webstats" v-show="checkRoute([ 'webstats' ])">Statistiche web</b-dropdown-item>
            <b-dropdown-item to="/companiessettings" v-show="checkRoute([ 'companiessettings' ])">Impostazioni aziende</b-dropdown-item>
          </b-nav-item-dropdown>
          <b-nav-item-dropdown text="Commesse" v-show="checkRoute(['ordersdays', 'quotes', 'orders', 'invoices', 'orderssettings'])">
            <b-dropdown-item to="/ordersdays" v-show="checkRoute(['ordersdays'])">Piano</b-dropdown-item>
            <b-dropdown-item to="/quotes" v-show="checkRoute(['quotes'])">Preventivo</b-dropdown-item>
            <b-dropdown-item to="/orders" v-show="checkRoute(['orders'])">Commesse</b-dropdown-item>
            <b-dropdown-item to="/invoices" v-show="checkRoute(['invoices'])">Fatture</b-dropdown-item>
            <b-dropdown-item to="/incomeinvoices" v-show="checkRoute(['incomeinvoices'])">Fatture ingresso</b-dropdown-item>
            <b-dropdown-item to="/orderssettings" v-show="checkRoute(['orderssettings'])">Impostazioni</b-dropdown-item>
          </b-nav-item-dropdown>
          <b-nav-item-dropdown text="Audits" v-show="checkRoute(['checklists', 'audits', 'contracts', 'standards'])">
            <b-dropdown-item to="/checklists" v-show="checkRoute(['checklists'])">Checklists</b-dropdown-item>
            <b-dropdown-item to="/audits" v-show="checkRoute(['audits'])">Audits</b-dropdown-item>
            <b-dropdown-item to="/contracts" v-show="checkRoute(['contracts'])">Contratti</b-dropdown-item>
            <b-dropdown-item to="/standards" v-show="checkRoute(['standards'])">Impostazioni</b-dropdown-item>
          </b-nav-item-dropdown>
          <b-nav-item-dropdown text="Personale" v-show="checkRoute(['curricula', 'qualifications'])">
            <b-dropdown-item to="/curricula" v-show="checkRoute(['curricula'])">Curricula</b-dropdown-item>
            <b-dropdown-item to="/qualifications" v-show="checkRoute(['qualifications'])">Qualifiche</b-dropdown-item>
          </b-nav-item-dropdown>
          <b-nav-item v-show="checkRoute(['mails'])" to="/mails">Mails</b-nav-item>        
          <b-nav-item-dropdown text="Interno" v-show="checkRoute(['communications', 'ordershours', 'orderscosts', 'vehicles', 'mailssettings', 'users'])">
            <b-dropdown-item to="/communications" v-show="checkRoute(['communications'])">Comunicazioni</b-dropdown-item>
            <b-dropdown-item to="/ordershours" v-show="checkRoute(['ordershours'])">Ore</b-dropdown-item>
            <b-dropdown-item to="/orderscosts" v-show="checkRoute(['orderscosts'])">Spese</b-dropdown-item>
            <b-dropdown-item to="/vehicles" v-show="checkRoute(['vehicles'])">Automezzi</b-dropdown-item>
            <b-dropdown-item to="/mailssettings" v-show="checkRoute(['mailssettings'])">Impostazioni mail</b-dropdown-item>
            <b-dropdown-item to="/users" v-show="checkRoute(['users'])">Utenti</b-dropdown-item>
          </b-nav-item-dropdown>
          <b-nav-item-dropdown text="Controllo" v-show="checkRoute(['banks', 'phonescalls', 'kpis'])">
            <b-dropdown-item to="/banks" v-show="checkRoute(['banks'])">Banche</b-dropdown-item>
            <b-dropdown-item to="/kpis" v-show="checkRoute(['kpis'])">Kpis</b-dropdown-item>
            <b-dropdown-item to="/phonecalls" v-show="checkRoute(['phonecalls'])">Telefonate</b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
        <b-navbar-nav class="ml-auto">
          <!--
          <b-button size="sm" class="my-2 my-sm-0" v-b-toggle.sidebar>Notifiche</b-button>
          -->
          <b-nav-item-dropdown text="Utente" right>
            <b-dropdown-item to="/login">Log out</b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <!--
    <b-sidebar id="sidebar" title="Notifiche" right shadow>
      <div class="px-3 py-2">
        <p>
          Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis
          in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.
        </p>
        <b-img src="https://picsum.photos/500/500/?image=54" fluid thumbnail></b-img>
      </div>
    </b-sidebar>
    -->
  </div>
</template>

<script>
export default {
  name: "navbar",
  computed: {
    routesAllowed (){
      return this.$store.getters.getRoutes;
    },
  },
  methods: {
    checkRoute(routeToCheck){
      return this.routesAllowed.map(x => x.route).filter(x => routeToCheck.indexOf(x) !== -1).length > 0;
    },
  }
}
</script>