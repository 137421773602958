<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" size="xl" :title="'Template - ' + data.template.id" scrollable @hidden="closeForm()" @close="closeForm()">
      <b-card no-body>
        <b-tabs card>
          <b-tab title="Templates" active>
            <b-row>
              <b-col lg="4">
                <b-form-group label="Template">
                  <b-form-input v-model="data.template.templatename"/>
                </b-form-group>
              </b-col>
              <b-col lg="8">
                <b-form-group label="Oggetto">
                  <b-form-input v-model="data.template.subject"/>
                </b-form-group>
              </b-col>
            </b-row>
            <customeditor v-model="data.template.html" :height="winHeight - 480" :showToolbar="true"/>
          </b-tab>
          <b-tab title="Allegati" v-if="data.template.id > 0">
            <mailssettingsattachment ref="attachment"  @update="loadData(data.template.id)"/>
            <b-btn variant="primary" size="sm" @click="$refs.attachment.loadData(data.template.id, 0)">Carica</b-btn>
            <b-table no-border-collapse :sticky-header="(winHeight - 400) + 'px'" responsive class="mt-2" :items="data.attachments" :fields="fields.attachments" @row-clicked="$refs.attachment.loadData(data.template.id, $event.id)">
              <template v-slot:cell(download)="row">
                <b-btn variant="primary" size="sm" @click="$refs.attachment.downloadData(row.item.id, 0)">Scarica</b-btn>
              </template>
            </b-table>
          </b-tab>
          <b-tab title="Abilitati">
            <b-form-checkbox-group v-model="data.template.mailsboxesusers">
              <b-table no-border-collapse :sticky-header="(winHeight - 385) + 'px'" responsive :items="data.mailsboxesusers" :fields="fields.mailsboxesusers">
                <template v-slot:cell(mailsboxes)="row">
                  <b-form-checkbox v-for="(item2, index2) of row.value" :key="index2" :value="item2.id">{{ item2.val }}</b-form-checkbox>
                </template>
              </b-table>
            </b-form-checkbox-group>
          </b-tab>
          <b-tab title="Scadenza">
            <b-row>
              <b-col lg="3">
                <b-form-group label="Scadenza">
                  <b-form-select v-model="data.template.mailstemplateyesno" :options="yesNoCombo" value-field="id" text-field="val"/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row v-if="data.template.mailstemplateyesno === true">
              <b-col lg="4">
                <b-form-group label="Nota">
                  <b-form-textarea v-model="data.template.note" rows="6"/>
                </b-form-group>
              </b-col>
              <b-col lg="4">
                <b-form-group label="Scadenza">
                  <b-form-textarea v-model="data.template.deadline" rows="6"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Utente">
                  <b-form-select v-model="data.template.users_id" :options="data.users" value-field="id" text-field="val"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Giorni">
                  <b-form-input type="number" v-model="data.template.deadlinedays"/>
                </b-form-group>
              </b-col>
              <b-col lg="12">
                <b-form-group label="Tags aggiungere">
                  <multiselect v-model="tagsAddSupport" :options="tagsAddCombo" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="tagAdd"/>
                </b-form-group>
              </b-col>
              <b-col lg="12">
                <b-form-group label="Tags rimuovere">
                  <multiselect v-model="tagsRemoveSupport" :options="tagsRemoveCombo" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="tagRemove"/>
                </b-form-group>
              </b-col>
            </b-row>
          </b-tab>
        </b-tabs>
      </b-card>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="success" class="mr-2" @click="saveData()">Salva</b-btn>
          <b-btn variant="danger" v-show="data.template.id > 0" @click="deleteData()">Elimina</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import mailssettingsattachment from './mailssettingsattachment.vue'
import customeditor from '../customeditor.vue';

export default {
  name: "mailsettingtemplate",
  components: {mailssettingsattachment, customeditor},
  data() {
    return {
      show: true,
      data: null,
      tagsAddCombo: [],
      tagsRemoveCombo: [],

      tagsAddSupport: [],
      tagsRemoveSupport: [],

      yesNoCombo: [{
        id: true,
        val: "Si"
      },{
        id: false,
        val: "No"
      }],
      fields: {
        attachments: [{
          key: "filename",
          label: "File"
        },{
          key: "download",
          label: ""
        }],
        mailsboxesusers: [{
          key: "val",
          label: "Utente"
        },{
          key: "mailsboxes",
          label: "Abilitazioni"
        }]
      }
    }
  },
  computed: {
    winHeight (){
      return this.$store.getters.getWinHeight;
    },
  },
  methods: {
    prepareCombos(){
      this.tagsAddCombo = this.data.tags.filter(x => ! this.data.template.tagsremove.includes(x.id));
      this.tagsRemoveCombo = this.data.tags.filter(x => ! this.data.template.tagsadd.includes(x.id));
      this.tagsAddSupport = this.convertCombo(this.tagsAddCombo, this.data.template.tagsadd);
      this.tagsRemoveSupport = this.convertCombo(this.tagsRemoveCombo, this.data.template.tagsremove);
    },
    tagAdd(e){
      this.data.template.tagsadd = JSON.parse(JSON.stringify(e.map(x => x.id)));
      this.data.template.tagsremove = this.data.template.tagsremove.filter(x => !this.data.template.tagsadd.includes(x));
      this.prepareCombos();
    },
    tagRemove(e){
      this.data.template.tagsremove = JSON.parse(JSON.stringify(e.map(x => x.id)));
      this.data.template.tagsadd = this.data.template.tagsadd.filter(x => !this.data.template.tagsremove.includes(x));
      this.prepareCombos();
    },
    loadData(id){
      this.openHttp("mailssettings/template", { id: id }).then((result) => {
        this.data = result;
        this.prepareCombos();
        this.show = true;
      })
    },
    saveData(){
      this.saveHttp("mailssettings/template/save", this.data.template).then((result) => {
        if ( result !== null ) this.closeForm();
      })
    },
    deleteData(){
      this.deleteHttp("mailssettings/template/delete", { id: this.data.template.id }).then((result) => {
        if ( result !== null ) this.closeForm();
      })
    },
    closeForm(){
      this.show = false;
      this.data = null;
      this.$emit('update');
    },
  },
}
</script>