<template>
  <div>
    <b-modal v-model="show" v-if="data !== null" :title="'Sito - ' + data.site.id" @hidden="closeForm()" @close="closeForm()">
      <b-form-group label="Indirizzo">
        <b-form-input v-model="data.site.address" @change="cleanLatLng"/>
      </b-form-group>
      <b-form-group label="Città">
        <multiselect v-model="cities_idSupport" :options="data.cities" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="data.site.cities_id = updateComboSimple(cities_idSupport); cleanLatLng()"/>
      </b-form-group>
      <b-form-group label="Stato">
        <b-button-group>
          <b-button :variant="data.site.active ? 'success' : 'secondary'" @click="data.site.active = true">Attivo</b-button>
          <b-button :variant="!data.site.active ? 'warning' : 'secondary'" @click="data.site.active = false"><nobr>Inattivo</nobr></b-button>
        </b-button-group>
      </b-form-group>
      <b-form-group label="Principale">
        <b-button-group>
          <b-button :variant="data.site.ismain ? 'success' : 'secondary'" @click="data.site.ismain = true">Principale</b-button>
          <b-button :variant="!data.site.ismain ? 'warning' : 'secondary'" @click="data.site.ismain = false">No</b-button>
        </b-button-group>
      </b-form-group>
      <b-form-group label="Legale">
        <b-button-group>
          <b-button :variant="data.site.isregistered ? 'danger' : 'secondary'" @click="data.site.isregistered = true">Legale</b-button>
          <b-button :variant="!data.site.isregistered ? 'warning' : 'secondary'" @click="data.site.isregistered = false">No</b-button>
        </b-button-group>
      </b-form-group>
      <b-form-group label="Operativa">
        <b-button-group>
          <b-button :variant="data.site.isoperative ? 'primary' : 'secondary'" @click="data.site.isoperative = true">Operativa</b-button>
          <b-button :variant="!data.site.isoperative ? 'warning' : 'secondary'" @click="data.site.isoperative = false">No</b-button>
        </b-button-group>
      </b-form-group>
      <b-row>
        <b-col lg="6">
          <b-form-group label="Latitudine">
            <b-form-input v-model="data.site.lat" disabled/>
          </b-form-group>
        </b-col>
        <b-col lg="6">
          <b-form-group label="Longitudine">
            <b-form-input v-model="data.site.lng" disabled/>
          </b-form-group>
        </b-col>
      </b-row>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="success" class="mr-2" @click="saveData">Salva</b-btn>
          <b-btn variant="danger" class="mr-2" v-show="data.site.id > 0" @click="deleteData">Elimina</b-btn>
          <b-btn variant="primary" @click="getLatLng" :disabled="data.site.lat !== null">Lat / Lng</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "companysite",
  data() {
    return {
      show: false,
      data: null,
      cities_idSupport: [],
      comboActive: [{
        id: true,
        val: "Attivo"
      },{
        id: false,
        val: "Non attivo"
      }]
    }
  },
  methods: {
    loadData(id, companies_id){
      this.openHttp("companies/site", { id: id, companies_id: companies_id }).then((result) => {
        this.cities_idSupport = this.convertComboSimple(result.cities, result.site.cities_id);
        this.data = result;
        this.show = true;
      })
    },
    cleanLatLng(){
      this.data.site.lat = null;
      this.data.site.lng = null;
    },
    getLatLng(){
      this.saveHttp("companies/site/lanlng", this.data.site).then((result) => {
        this.data.site.lat = result.lat;
        this.data.site.lng = result.lng;
      })
    },
    saveData(){
      this.saveHttp("companies/site/save", this.data.site).then((result) => {
        if ( result !== true ) this.closeForm();
      })
    },
    deleteData(){
      this.deleteHttp("companies/site/delete", { id: this.data.site.id }).then((result) => {
        if ( result !== true ) this.closeForm();
      })
    },
    closeForm(){
      this.show = false;
      this.data = null;
      this.$emit('update');
    }
  },
}
</script>