<template>
  <div>
    <b-card no-body>
      <b-tabs card>
        <b-tab title="Tags" active>
          <tag ref="tag" @update="loadDataTags"/>
          <b-modal v-model="tags.showFilter" title="Filtra">
            <b-form-group label="Tag">
              <b-form-input v-model="tags.tempFilter.tag"/>
            </b-form-group>
            <template v-slot:modal-footer>
              <div class="w-100">
                <b-btn variant="primary" class="mr-2" @click="filterSetTags">Filtra</b-btn>
                <b-btn variant="warning" @click="filterResetTags">Reset</b-btn>
              </div>
            </template>
          </b-modal>
          <b-btn class="mr-2" variant="primary" @click="loadDataTags">Aggiorna</b-btn>
          <b-btn class="mr-2" variant="secondary" @click="tags.showFilter = true">Filtra</b-btn>
          <b-btn variant="success" @click="$refs.tag.loadData(0)">Nuova</b-btn>
          <b-table no-border-collapse :sticky-header="(winHeight - 305) + 'px'" hover responsive show-empty mode="remote" class="mt-3" :items="tags.items.data" :fields="tags.fields" :busy="tags.isBusy" :no-local-sorting="true" :sort-by.sync="tags.search.sortby" :sort-desc.sync="tags.search.sortdesc" @sort-changed="sortingChangeTags" @row-clicked="$refs.tag.loadData($event.id)">
            <template v-slot:table-busy>
              <div class="text-center text-primary">
                <b-spinner class="align-middle"/>
              </div>
            </template>
            <template v-slot:empty>Non ci sono risultati</template>
          </b-table>
          <tablebottom :busy="tags.isBusy" :currentpage="tags.search.page" :total="tags.items.total" @updateCurrentPage="tags.search.page = $event" @loadData="loadDataTags"/>
        </b-tab>
        <b-tab title="Città">
          <city ref="city" @update="loadDataCities"/>
          <b-modal v-model="cities.showFilter" title="Filtra">
            <b-form-group label="Città">
              <b-form-input v-model="cities.tempFilter.city"/>
            </b-form-group>
            <b-form-group label="Cap">
              <b-form-input v-model="cities.tempFilter.postalcode"/>
            </b-form-group>
            <b-form-group label="Provincia">
              <b-form-input v-model="cities.tempFilter.province"/>
            </b-form-group>
            <b-form-group label="Regione">
              <b-form-input v-model="cities.tempFilter.region"/>
            </b-form-group>
            <template v-slot:modal-footer>
              <div class="w-100">
                <b-btn variant="primary" class="mr-2" @click="filterSetCities">Filtra</b-btn>
                <b-btn variant="warning" @click="filterResetCities">Reset</b-btn>
              </div>
            </template>
          </b-modal>
          <b-btn class="mr-2" variant="primary" @click="loadDataCities">Aggiorna</b-btn>
          <b-btn class="mr-2" variant="secondary" @click="cities.showFilter = true">Filtra</b-btn>
          <b-btn variant="success" @click="$refs.city.loadData(0)">Nuova</b-btn>
          <b-table no-border-collapse :sticky-header="(winHeight - 305) + 'px'" hover responsive show-empty mode="remote" class="mt-3" :items="cities.items.data" :fields="cities.fields" :busy="cities.isBusy" :no-local-sorting="true" :sort-by.sync="cities.search.sortby" :sort-desc.sync="cities.search.sortdesc" @sort-changed="sortingChangeCities" @row-clicked="$refs.city.loadData($event.id)">
            <template v-slot:table-busy>
              <div class="text-center text-primary">
                <b-spinner class="align-middle"/>
              </div>
            </template>
            <template v-slot:empty>Non ci sono risultati</template>
          </b-table>
          <tablebottom :busy="cities.isBusy" :currentpage="cities.search.page" :total="cities.items.total" @updateCurrentPage="cities.search.page = $event" @loadData="loadDataCities"/>
        </b-tab>
        <b-tab title="Province">
          <province ref="province" @update="loadDataProvinces"/>
          <b-modal v-model="provinces.showFilter" title="Filtra">
            <b-form-group label="Provincia">
              <b-form-input v-model="provinces.tempFilter.province"/>
            </b-form-group>
            <b-form-group label="Regione">
              <b-form-input v-model="provinces.tempFilter.region"/>
            </b-form-group>
            <template v-slot:modal-footer>
              <div class="w-100">
                <b-btn variant="primary" class="mr-2" @click="filterSetProvinces">Filtra</b-btn>
                <b-btn variant="warning" @click="filterResetProvinces">Reset</b-btn>
              </div>
            </template>
          </b-modal>
          <b-btn class="mr-2" variant="primary" @click="loadDataProvinces">Aggiorna</b-btn>
          <b-btn class="mr-2" variant="secondary" @click="provinces.showFilter = true">Filtra</b-btn>
          <b-btn class="mr-2" variant="success" @click="$refs.province.loadData(0)">Nuova</b-btn>
          <b-table no-border-collapse :sticky-header="(winHeight - 305) + 'px'" hover responsive show-empty mode="remote" class="mt-3" :items="provinces.items.data" :fields="provinces.fields" :busy="provinces.isBusy" :no-local-sorting="true" :sort-by.sync="provinces.search.sortby" :sort-desc.sync="provinces.search.sortdesc" @sort-changed="sortingChangeProvinces" @row-clicked="$refs.province.loadData($event.id)">
            <template v-slot:table-busy>
              <div class="text-center text-primary">
                <b-spinner class="align-middle"/>
              </div>
            </template>
            <template v-slot:empty>Non ci sono risultati</template>
          </b-table>
          <tablebottom :busy="provinces.isBusy" :currentpage="provinces.search.page" :total="provinces.items.total" @updateCurrentPage="provinces.search.page = $event" @loadData="loadDataProvinces"/>
        </b-tab>
        <b-tab title="Regioni">
          <region ref="region" @update="loadDataRegions"/>
          <b-modal v-model="regions.showFilter" title="Filtra">
            <b-form-group label="Regione">
              <b-form-input v-model="regions.tempFilter.region"/>
            </b-form-group>
            <template v-slot:modal-footer>
              <div class="w-100">
                <b-btn variant="primary" class="mr-2" @click="filterSetRegions">Filtra</b-btn>
                <b-btn variant="warning" @click="filterResetRegions">Reset</b-btn>
              </div>
            </template>
          </b-modal>
          <b-btn class="mr-2" variant="primary" @click="loadDataRegions">Aggiorna</b-btn>
          <b-btn class="mr-2" variant="secondary" @click="regions.showFilter = true">Filtra</b-btn>
          <b-btn class="mr-2" variant="success" @click="$refs.region.loadData(0)">Nuova</b-btn>
          <b-table no-border-collapse :sticky-header="(winHeight - 305) + 'px'" hover responsive show-empty mode="remote" class="mt-3" :items="regions.items.data" :fields="regions.fields" :busy="regions.isBusy" :no-local-sorting="true" :sort-by.sync="regions.search.sortby" :sort-desc.sync="regions.search.sortdesc" @sort-changed="sortingChangeRegions" @row-clicked="$refs.region.loadData($event.id)">
            <template v-slot:table-busy>
              <div class="text-center text-primary">
                <b-spinner class="align-middle"/>
              </div>
            </template>
            <template v-slot:empty>Non ci sono risultati</template>
          </b-table>
          <tablebottom :busy="regions.isBusy" :currentpage="regions.search.page" :total="regions.items.total" @updateCurrentPage="regions.search.page = $event" @loadData="loadDataRegions"/>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import tag from './tag.vue'
import city from './city.vue'
import province from './province.vue'
import region from './region.vue'

export default {
  name: "companiessettings",
  components : {
    tag, city, province, region
  },
  data() {
    return {
      tags: {
        isBusy: true,
        showFilter: false,
        search: {
          page: 1,
          filters: {
            tag: null,
          },
          sortby: 'tag',
          sortdesc: false,
        },
        tempFilter: {
          tag: null,
        },
        fields: [{
          key: 'tag',
          label: 'Tag',
          sortable: true,
        },{
          key: 'sumcompanies',
          label: 'Totale',
        }],
        items: {
          total: 0,
          data: [],
        }
      },
      cities: {
        isBusy: true,
        showFilter: false,
        search: {
          page: 1,
          filters: {
            city: null,
            postalcode: null,
            province: null,
            region: null
          },
          sortby: 'city',
          sortdesc: false,
        },
        tempFilter: {
          city: null,
          postalcode: null,
          province: null,
          region: null
        },
        fields: [{
          key: 'city',
          label: 'Città',
          sortable: true,
        },{
          key: 'postalcode',
          label: 'CAP',
          sortable: true,
        },{
          key: 'province',
          label: 'Provincia',
        },{
          key: 'region',
          label: 'Regione',
        }],
        items: {
          total: 0,
          data: [],
        }
      },
      provinces: {
        isBusy: true,
        showFilter: false,
        search: {
          page: 1,
          filters: {
            province: null,
            region: null
          },
          sortby: 'province',
          sortdesc: false,
        },
        tempFilter: {
          tag: null,
        },
        fields: [{
          key: 'province',
          label: 'Provincia',
        },{
          key: 'region',
          label: 'Regione',
        }],
        items: {
          total: 0,
          data: [],
        }
      },
      regions: {
        isBusy: true,
        showFilter: false,
        search: {
          page: 1,
          filters: {
            region: null,
          },
          sortby: 'region',
          sortdesc: false,
        },
        tempFilter: {
          region: null,
        },
        fields: [{
          key: 'region',
          label: 'Regione',
        },{
          key: 'statename',
          label: 'Stato',
        }],
        items: {
          total: 0,
          data: [],
        }
      },
    }
  },
  computed: {
    winHeight (){
      return this.$store.getters.getWinHeight;
    },
  },
  created(){
    this.loadData();
  },
  methods: {
    loadData(){
      this.loadDataTags();
      this.loadDataCities();
      this.loadDataProvinces();
      this.loadDataRegions();
    },
    loadDataTags(){
      this.tags.isBusy = true;
      this.openHttp("companiessettings/tags", this.tags.search).then((result) => {
        this.tags.items = result;
        this.tags.isBusy = false;
      })
    },
    filterSetTags(){
      this.tags.showFilter = false;
      this.tags.search.filters = this.tags.tempFilter;
      this.loadDataTags();
    },
    filterResetTags(){
      this.tags.tempFilter = {
        tag: null,
      }
      this.filterSetTags();
    },
    sortingChangeTags(ctx) {
      this.tags.search.sortby = ctx.sortBy;
      this.tags.search.sortdesc = ctx.sortDesc;
      this.loadDataTags();
    },
    loadDataCities(){
      this.cities.isBusy = true;
      this.openHttp("companiessettings/cities", this.cities.search).then((result) => {
        this.cities.items = result;
        this.cities.isBusy = false;
      })
    },
    filterSetCities(){
      this.cities.showFilter = false;
      this.cities.search.filters = this.cities.tempFilter;
      this.loadDataCities();
    },
    filterResetCities(){
      this.cities.tempFilter = {
        city: null,
        postalcode: null,
        province: null,
        region: null,
      };
      this.filterSetCities();
    },
    sortingChangeCities(ctx) {
      this.cities.search.sortby = ctx.sortBy;
      this.cities.search.sortdesc = ctx.sortDesc;
      this.loadDataCities();
    },
    loadDataProvinces(){
      this.provinces.isBusy = true;
      this.openHttp("companiessettings/provinces", this.provinces.search).then((result) => {
        this.provinces.items = result;
        this.provinces.isBusy = false;
      })
    },
    filterSetProvinces(){
      this.provinces.showFilter = false;
      this.provinces.search.filters = this.provinces.tempFilter;
      this.loadDataProvinces();
    },
    filterResetProvinces(){
      this.provinces.tempFilter = {
        province: null,
        region: null,
      }
      this.filterSetProvinces();
    },
    sortingChangeProvinces(ctx) {
      this.provinces.search.sortby = ctx.sortBy;
      this.provinces.search.sortdesc = ctx.sortDesc;
      this.loadDataProvinces();
    },
    loadDataRegions(){
      this.regions.isBusy = true;
      this.openHttp("companiessettings/regions", this.regions.search).then((result) => {
        this.regions.items = result;
        this.regions.isBusy = false;
      })
    },
    filterSetRegions(){
      this.regions.showFilter = false;
      this.regions.search.filters = this.regions.tempFilter;
      this.loadDataRegions();
    },
    filterResetRegions(){
      this.regions.tempFilter = {
        region: null,
      }
      this.filterSetRegions();
    },
    sortingChangeRegions(ctx) {
      this.regions.search.sortby = ctx.sortBy;
      this.regions.search.sortdesc = ctx.sortDesc;
      this.loadDataRegions();
    }
  }
}
</script>