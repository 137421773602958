<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" :title="'Web - ' + data.domain + ' - ' + data.date" @hidden="closeForm()" @close="closeForm()">
      {{ data }}
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "web",
  data() {
    return {
      show: true,
      data: null
    }
  },
  methods: {
    loadData( domainsId, date ){
      this.openHttp("webstats/web", { domains_id: domainsId, date: date }).then((result) => {
        this.data = result;
        this.show = true;
      })
    },
    closeForm(){
      this.show = false;
      this.data = null;
      this.$emit('update');
    }
  }
}
</script>