<template>
    <div>
        <b-modal v-if="data !== null" v-model="show" size="xl" :title="'IAF Rischi - ' + data.nacerisk.id" scrollable @hidden="closeForm" @close="closeForm">
            <b-row>
                <b-col lg="6">
                    <b-form-group label="Rischio EN">
                        <b-form-input v-model="data.nacerisk.riskwork_en"/>
                    </b-form-group>
                </b-col>
                <b-col lg="6">
                    <b-form-group label="Rischio IT">
                        <b-form-input v-model="data.nacerisk.riskwork_it"/>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-form-group label="Norme">
                <multiselect v-model="standardsSupport" :options="data.standards" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="data.nacerisk.standards = updateCombo(standardsSupport)"/>
            </b-form-group>
            <b-form-group label="IAF">
                <multiselect v-model="nacesiafcodesSupport" :options="data.nacesiafcodes" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="data.nacerisk.nacesiafcodes = updateCombo(nacesiafcodesSupport)"/>
            </b-form-group>
            <template v-slot:modal-footer>
                <div class="w-100">
                    <b-btn variant="success" class="mr-2" @click="saveData">Salva</b-btn>
                    <b-btn variant="danger" v-show="data.nacerisk.id> 0" @click="deleteData">Elimina</b-btn>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
export default {
    name: "nacerisk",
    data() {
        return {
            show: false,
            data: null,
            standardsSupport: [],
            nacesiafcodesSupport: []
        }
    },
    computed: {
        winHeight (){
            return this.$store.getters.getWinHeight;
        },
    },
    methods: {
        loadData(id){
            this.openHttp("standards/nacerisk", { id: id }).then((result) => {
                this.standardsSupport = this.convertCombo(result.standards, result.nacerisk.standards);
                this.nacesiafcodesSupport = this.convertCombo(result.nacesiafcodes, result.nacerisk.nacesiafcodes);
                this.data = result;
                this.show = true;
            })
        },
        saveData(){
            this.saveHttp("standards/nacerisk/save", this.data.nacerisk).then((result) => {
                if (result !== null) this.closeForm();
            })
        },
        deleteData(){
            this.deleteHttp("standards/nacerisk/delete", { id: this.data.nacerisk.id }).then((result) => {
                if (result !== null) this.closeForm();
            })
        },
        closeForm(){
            this.show = false;
            this.data = null;
            this.$emit('update');
        },
    },
}
</script>