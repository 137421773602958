<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" size="lg" :title="'Nota - ' + data.note.id" scrollable @hidden="closeForm()" @close="closeForm()">
      <company ref="company"/>
      <mail ref="mail"/>
      <phone ref="phone"/>
      <marketingcampaign ref="campaign"/>
      <b-card no-body>
        <b-tabs card>
          <b-tab title="Scadenza" active>
            <b-card :title="data.action.company + ' - ' + data.action.fullname" :sub-title="data.action.contact">
              <b-card-text><strong>Scadenza: </strong>{{ data.note.deadline }}</b-card-text>
              <b-link @click="$refs.campaign.loadData(data.action.marketingcampaigns_id)" class="card-link">Campagna</b-link>
              <b-link @click="$refs.company.loadData(data.action.companies_id)" class="card-link">Azienda</b-link>
              <b-link v-for="(item, index) of data.contacts.phonescompany" :key="index" @click="$refs.phone.loadData(item)" class="card-link">Aziendale: {{ item }}</b-link>
              <b-link v-for="(item, index) of data.contacts.phonesworker" :key="index" @click="$refs.phone.loadData(item)" class="card-link">Personale: {{ item }}</b-link>
              <b-link v-for="(item, index) of data.contacts.webs" :key="index" :href="'http://' + item" class="card-link" target="_blank">{{ item }}</b-link>
            </b-card>
            <b-form-group label="Note">
              <b-form-textarea v-model="data.note.note" rows="3"/>
            </b-form-group>
            <b-form-group label="Decisione" v-if="data.note.donedatetime === null">
              <b-form-radio-group v-model="data.note.marketingactionsstatuses_id" :options="decisionsFunnelCombo" value-field="id" text-field="val"/>
            </b-form-group>
            <b-row v-if="data.note.marketingactionsstatuses_id === 3">
              <b-col lg="2">
                <b-form-group label="Data Scadenza">
                  <b-form-input type="date" v-model="deadlineDate" @change="updateNextdatetime($event, deadlineTime)"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Ora Scadenza">
                  <b-form-input type="time" v-model="deadlineTime" @change="updateNextdatetime(deadlineDate, $event)"/>
                </b-form-group>
              </b-col>
              <b-col lg="4">
                <b-form-group label="Incaricato">
                  <b-form-select v-model="data.note.nextusers_id" :options="data.users" value-field="id" text-field="val"/>
                </b-form-group>
              </b-col>
              <b-col lg="12">
                <b-form-group label="Scadenza prossima">
                  <b-form-textarea v-model="data.note.nextdeadline" rows="2"/>
                </b-form-group>
              </b-col>
            </b-row>
          </b-tab>
          <b-tab :title="'Mails ' + data.mails.length">
            <b-table no-border-collapse :sticky-header="(winHeight - 420) + 'px'" responsive :tbody-tr-class="rowClass" :items="data.mails" :fields="fields.mails">
              <template v-slot:cell(mailboxesmails_id)="row">
                <b-link v-if="row.value > 0" @click="$refs.mail.loadData(row.value)">Mail</b-link>
              </template>
            </b-table>
          </b-tab>
          <b-tab :title="'Note ' + data.notes.length">
            <b-table no-border-collapse :sticky-header="(winHeight - 420) + 'px'" responsive :items="data.notes" :fields="fields.notes"/>
          </b-tab>
          <b-tab :title="'Storia ' + data.kanban.length">
            <b-table responsive :items="data.kanban" :fields="fields.kanban"/>
          </b-tab>
        </b-tabs>
      </b-card>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="primary" class="mr-2" @click="loadData(data.note.id)">Aggiorna</b-btn>
          <b-btn variant="success" class="mr-2" @click="saveData()">Salva</b-btn>
          <b-btn variant="danger" @click="deleteData()">Elimina</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import phone from '../companies/companycomponents/phone.vue';
import marketingcampaign from './marketingcampaign.vue';

export default {
  name: "marketingnote",
  components: { phone, marketingcampaign },
  data() {
    return {
      show: false,
      data: null,
      deadlineDate: null,
      deadlineTime: null,
      fields: {
        kanban: [{
          key: "datetime",
          label: "Data",
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            const today = new Date();
            var d = new Date(value);
            if ( d.getDate() == today.getDate() && d.getMonth() == today.getMonth() && d.getFullYear() == today.getFullYear()){
              return [ d.getHours(), d.getMinutes() ].map(n => n < 10 ? `0${n}` : `${n}`).join(':');
            } else {
              return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
            }
          },
        },{
          key: "status",
          label: "Persona"
        },{
          key: "fullname",
          label: "Utente"
        }],
        mails: [{
          key: 'datetime',
          label: "Data",
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            const today = new Date();
            var d = new Date(value);
            if ( d.getDate() == today.getDate() && d.getMonth() == today.getMonth() && d.getFullYear() == today.getFullYear()){
              return [ d.getHours(), d.getMinutes() ].map(n => n < 10 ? `0${n}` : `${n}`).join(':');
            } else {
              return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
            }
          },
        },{
          key: 'marketingtemplate',
          label: "Template",
        },{
          key: 'mailbox',
          label: "Casella",
        },{
          key: 'success',
          label: "Esito",
          formatter: value => {
            return value === true ? "Mandata" : (value === false ? "Fallita" : "Caricata");
          }
        },{
          key: 'mailboxesmails_id',
          label: "Mail",
        }],
        notes: [{
          key: 'datetime',
          label: "Data",
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            const today = new Date();
            var d = new Date(value);
            if ( d.getDate() == today.getDate() && d.getMonth() == today.getMonth() && d.getFullYear() == today.getFullYear()){
              return [ d.getHours(), d.getMinutes() ].map(n => n < 10 ? `0${n}` : `${n}`).join(':');
            } else {
              return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
            }
          },
        },{
          key: 'fullname',
          label: "Persona",
        },{
          key: 'deadline',
          label: "Scadenza"
        },{
          key: "note",
          label: "Note"
        },{
          key: "donedatetime",
          label: "Stato",
          formatter: value => {
            return value === null ? "Da fare" : "Completata";
          }
        }]
      },
      decisionsFunnelCombo: [{
        id: 2,
        val: "Reinvio mail"
      },{
        id: 3,
        val: "In contatto"
      },{
        id: 4,
        val: "Chiuso - successo"
      },{
        id: 5,
        val: "Chiuso - fallito"
      }],
    }
  },
  computed: {
    winHeight (){
      return this.$store.getters.getWinHeight;
    }
  },
  methods: {
    rowClass( item ){
      if ( item !== null ) return item.ping === true ? "table-success" : "";
    },
    loadData(id){
      this.openHttp("marketing/note", { id: id }).then((result) => {
        this.data = result;
        var d = new Date(this.data.note.nextdatetime);
        this.deadlineDate = [d.getFullYear(), d.getMonth() + 1, d.getDate()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
        this.deadlineTime = [d.getHours(), d.getMinutes()].map(n => n < 10 ? `0${n}` : `${n}`).join(':');
        this.show = true;
      })
    },
    updateNextdatetime(date, time){
      const dateSplit = date.split("-");
      const timeSplit = time.split(":");
      this.data.note.nextdatetime = new Date(dateSplit[0], dateSplit[1], dateSplit[2], timeSplit[0], timeSplit[1], 0);
    },
    saveData(){
      this.saveHttp("marketing/note/save", this.data.note).then((result) => {
        if (result !== null) this.closeForm();
      })
    },
    deleteData(){
      this.deleteHttp("marketing/note/delete", { id: this.data.note.id }).then((result) => {
        if (result !== null) this.closeForm();
      })
    },
    closeForm(){
      this.show = false;
      this.data = null;
      this.$emit('update');
    },
  },
}
</script>