<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" :title="'Maschera - ' + data.form.form" @hidden="closeForm()" @close="closeForm()">
      <b-table no-border-collapse :sticky-header="(winHeight - 205) + 'px'" responsive :items="data.usersforms" :fields="fields">
        <template v-slot:cell(accesslevel)="row">
          <b-form-select v-model="row.item.accesslevel" :options="levelsCombo" value-field="id" text-field="val"/>
        </template>
      </b-table>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="success" @click="saveData">Salva</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>

export default {
  name: "usersform",
  data() {
    return {
      show: false,
      data: null,
      fields: [{
        key: "user",
        label: "Utente",
      },{
        key: "accesslevel",
        label: "Livello",
      }],
      levelsCombo: [],
    }
  },
  computed: {
    winHeight (){
      return this.$store.getters.getWinHeight;
    },
  },
  methods: {
    loadData( id ){
      this.openHttp("users/form", { id: id }).then((result) => {
        this.levelsCombo = [{id: null, val: "Nessuno"}];
        for (let i = 1; i <= result.form.maxaccesslevel; i++) this.levelsCombo.push({id: i, val: i});
        this.data = result;
        this.show = true;
      })
    },
    saveData(){
      this.saveHttp("users/form/save", { usersforms: this.data.usersforms }).then((result) => {
        if ( result !== null ) this.closeForm();
      })
    },
    closeForm(){
      this.data = null;
      this.show = false;
      this.$emit('update');      
    }
  }
}
</script>