<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" size="xl" :title="'Fattura ingresso - ' + data.invoice.id" scrollable @hidden="closeForm" @close="closeForm">
      <b-row>
        <b-col lg="2">
          <b-form-group label="Numero">
            <b-form-input :value="data.invoice.refinvoice"/>
          </b-form-group>
        </b-col>
        <b-col lg="2">
          <b-form-group label="Data">
            <b-form-input type="date" :value="data.invoice.date"/>
          </b-form-group>
        </b-col>
        <b-col lg="4">
          <company ref="company"/>
          <label><a @click="$refs.company.loadData(data.invoice.companies_id)">Azienda</a></label>
          <b-form-input :value="data.invoice.company"/>
        </b-col>
        <b-col lg="4">
          <b-form-group label="Ditta">
            <b-form-input :value="data.invoice.internalcompany"/>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="2">
          <b-form-group label="Tipologia">
            <b-form-input :value="data.invoice.typedocument"/>
          </b-form-group>
        </b-col>
        <b-col lg="2">
          <b-form-group label="Importo">
            <b-form-input :value="data.invoice.total + ' ' + data.invoice.unit"/>
          </b-form-group>
        </b-col>
      </b-row>
      <b-form-group label="Dettaglio Intestazione">
        {{ data.invoice.jsoninvoice.FatturaElettronicaHeader }}
      </b-form-group>
      <b-form-group label="Dettaglio Corpo">
        {{ data.invoice.jsoninvoice.FatturaElettronicaBody }}
      </b-form-group>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="primary" @click="loadData(data.invoice.id)">Aggiorna</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "incomeinvoice",
  data() {
    return {
      show: true,
      data: null,
    }
  },
  computed: {
    winHeight (){
      return this.$store.getters.getWinHeight;
    },
  },
  methods: {
    loadData(id){
      if (this.data == null ) this.show = false;
      this.openHttp("incomeinvoices_incomeinvoice", { id: id }).then((result) => {
        this.show = true;
        this.data = result;
      })
    },
    closeForm(){
      this.show = false;
      this.data = null;
      this.$emit('update');
    },
  },
}
</script>