<template>
    <div>
        <b-modal v-if="data !== null" v-model="show" size="lg" title="Role" scrollable @hidden="closeForm" @close="closeForm">
            <b-form-group label="Role">
                <b-form-input v-model="data.role.usersrole"/>
            </b-form-group>
            <b-form-group label="Role">
                <b-form-input v-model="data.role.usersroleshort"/>
            </b-form-group>
            <b-form-group label="Roles can do">
                <multiselect v-model="sonsSupport" :options="data.roles" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="data.role.sons = updateCombo(sonsSupport)"/>
            </b-form-group>
            <template v-slot:modal-footer>
                <div class="w-100">
                    <b-btn variant="success" class="mr-2" @click="saveData">Save</b-btn>
                    <b-btn variant="danger" v-show="data.role.id> 0" @click="deleteData">Delete</b-btn>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
export default {
    name: "userrole",
    data() {
        return {
            show: false,
            data: null,
            sonsSupport: []
        }
    },
    methods: {
        loadData( id ){
            this.openHttp("standards/role", { id: id }).then((result) => {
                this.sonsSupport = this.convertCombo(result.roles, result.role.sons);
                this.data = result;
                this.show = true;
            })
        },
        saveData(){
            this.saveHttp("standards/role/save", this.data.role).then((result) => {
                if ( result !== null ) this.closeForm();
            })
        },
        deleteData(){
            this.deleteHttp("standards/role/delete", { id: this.data.role.id }).then((result) => {
                if ( result !== null ) this.closeForm();
            })
        },
        closeForm(){
            this.show = false;
            this.data = null;
            this.$emit('update');
        },
    },
}
</script>