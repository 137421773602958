<template>
    <div>
        <b-modal v-if="data !== null" v-model="show" size="lg" title="Monitoring question" scrollable @hidden="closeForm" @close="closeForm">
            <b-row>
                <b-col lg="3">
                    <b-form-group label="Order">
                        <b-form-input type="number" min="1" v-model="data.question.orderquestion"/>
                    </b-form-group>
                </b-col>
                <b-col lg="9">
                    <b-form-group label="Roles">
                        <multiselect v-model="data.question.usersrolesSupport" :options="data.usersroles" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="data.question.usersroles = updateCombo(data.question.usersrolesSupport)"/>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-form-group label="Standards">
                <multiselect v-model="data.question.standardsSupport" :options="data.standards" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="data.question.standards = updateCombo(data.question.standardsSupport)"/>
            </b-form-group>
            <b-form-group label="Question">
                <b-form-textarea v-model="data.question.question" rows="4"/>
            </b-form-group>
            <template v-slot:modal-footer>
                <div class="w-100">
                    <b-btn variant="success" class="mr-2" @click="saveData">Save</b-btn>
                    <b-btn variant="warning" class="mr-2" v-show="data.question.id> 0" @click="duplicateData">Duplicate</b-btn>
                    <b-btn variant="danger" v-show="data.question.id> 0" @click="deleteData">Delete</b-btn>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
export default {
    name: "auditsmonitoringquestion",
    data() {
        return {
            show: false,
            data: null,
        }
    },
    methods: {
        loadData(id){
            this.openHttp("monitorings/question", { id: id, certificationbodies_id: 133 }).then((result) => {
                result.question.standardsSupport = this.convertCombo(result.standards, result.question.standards);
                result.question.usersrolesSupport = this.convertCombo(result.usersroles, result.question.usersroles);
                this.data = result;
                this.show = true;
            })
        },
        saveData(){
            this.saveHttp("monitorings/question/save", this.data.question).then((result) => {
                if ( result.result == true ) this.closeForm();
            })
        },
        duplicateData(){
            this.data.question.id = 0;
        },
        deleteData(){
            this.deleteHttp("monitorings/question/delete", { id: this.data.question.id }).then((result) => {
                if ( result.result == true ) this.closeForm();
            })
        },
        closeForm(){
            this.show = false;
            this.data = null;
            this.$emit('update');
        },
    },
}
</script>