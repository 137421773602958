<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" size="xl" :title="'Step - ' + data.step.id" @hidden="closeForm()" @close="closeForm()">
      <b-row>
        <b-col lg="2">
          <b-form-group label="Data">
            <b-form-input type="date" v-model="data.step.date"/>
          </b-form-group>
        </b-col>
        <b-col lg="2">
          <b-form-group label="Preventivo">
            <b-form-input type='number' step='0.01' v-model="data.step.quotation" @change="checkVisible()"/>
          </b-form-group>
        </b-col>
        <b-col lg="7">
          <b-form-group label="Servizi">
            <multiselect v-model="servicesSupport" :options="data.services" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="data.step.services = updateCombo(servicesSupport);"/>
          </b-form-group>
        </b-col>
        <b-col lg="1">
          <b-form-group label="Visibile">
            <b-form-select v-model="data.step.isvisible" :options="visibleCombo" value-field="id" text-field="val"/>
          </b-form-group>
        </b-col>
      </b-row>
      <b-form-group label="Descrizione">
        <b-btn variant="primary" class="mb-2" size="sm" @click="updateDescription">Prepara titolo</b-btn>
        <b-form-textarea v-model="data.step.description" rows="8"/>
      </b-form-group>
      <b-alert :variant="marginPercentage < 0 ? 'danger' : ( marginPercentage > 20 ? 'success' : 'warning' )" show>Margine: {{ marginTotal }} ({{ marginPercentage }} %)</b-alert>
      <b-row>
        <b-col v-for="(item, index) of data.step.costs.filter(x => x.id !== 26)" :key="index">
          <b-form-group :label="item.val">
            <b-form-input type="number" step="0.01" v-model="item.cost" size="sm"/>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group :label="data.step.costs.find(x => x.id === 26).val">
            <b-input-group>
              <b-input-group-prepend>
                <b-btn variant="warning" size="sm" @click="loadMandays()">Calcola</b-btn>
              </b-input-group-prepend>
              <b-form-input type="number" step="0.01" size="sm" v-model="data.step.costs.find(x => x.id === 26).cost"/>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="2">
          <b-form-group label="Ore">
            <b-form-input type='number' step='0.01' v-model="data.step.hours" size="sm"/>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="2">
          <b-form-group label="Km">
            <b-form-input type='number' step='0.01' v-model="data.step.km" size="sm"/>
          </b-form-group>
        </b-col>
        <b-col lg="12">
          <b-table responsive :items="data.sites" :fields="fields.sites"/>
        </b-col>
      </b-row>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="success" class="mr-2" @click="saveData">Salva</b-btn>
          <b-btn variant="danger" v-show="data.step.id > 0" @click="deleteData">Elimina</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>

export default {
  name: "quotestep",
  data() {
    return {
      show: true,
      data: null,
      servicesSupport: [],
      dataSites: [],
      dataMandays: [],
      visibleCombo: [{
        id: true,
        val: "Si"
      },{
        id: false,
        val: "No"
      }],
      fields: {
        sites: [{
          key: "address",
          label: "Indirizzo",
        },{
          key: "city",
          label: "Città",
        },{
          key: "province",
          label: "Provincia",
        },{
          key: "ismain",
          label: "Principale",
          formatter: value => {
            if (value == null) return null;
            return value === true ? "Principale" : "";
          },
        },{
          key: 'isoperative',
          label: 'Operativa',
          formatter: value => {
            if (value == null) return null;
            return value === true ? "Operativa" : "";
          },
        },{
          key: 'distancefrommadone',
          label: "Distanza",
        },{
          key: 'durationfrommadone',
          label: "Viaggio",
        }],
      }
    }
  },
  computed: {
    marginTotal: function () {
      var totalCosts = ( this.data.step.hours * this.data.coefHours ) + ( this.data.step.km * this.data.coefKm ) + (this.data.step.costs.length > 0 ? this.data.step.costs.map(x => parseFloat(x.cost)).reduce(function(accumulator, currentValue) { return accumulator + currentValue; }) : 0);
      return this.data.step.quotation - totalCosts;
    },
    marginPercentage: function () {
      var totalCosts = ( this.data.step.hours * this.data.coefHours ) + ( this.data.step.km * this.data.coefKm ) + (this.data.step.costs.length > 0 ? this.data.step.costs.map(x => parseFloat(x.cost)).reduce(function(accumulator, currentValue) { return accumulator + currentValue; }) : 0);
      return Math.round((this.data.step.quotation > 0 ? ((this.data.step.quotation - totalCosts) / this.data.step.quotation) : 0) * 10000, 2) / 100;
    }
  },
  methods: {
    loadData( quotesId, id ){
      this.openHttp("quotes/step", { quotes_id: quotesId, id: id }).then((result) => {
        this.servicesSupport = this.convertCombo(result.services, result.step.services);
        this.data = result;
        this.show = true;
      })
    },
    loadMandays(){
      this.openHttp("quotes/step/mandays", { id: this.data.step.quotes_id, services: this.data.step.services }).then((result) => {
        this.$bvToast.toast("Standards: " + result.standards + " Mandays: " + result.mandays + " Fees: " + result.fees + " Note: " + result.notes, { title: "Calcolo Fees", variant: "info", appendToast: true });
        if (result.fees > 0) this.data.step.costs.find(x => x.id === 26).cost = result.fees;
      })
    },
    checkVisible(){
      this.data.step.quotation > 0 ? this.data.step.isvisible = true : this.data.step.isvisible = false;
    },
    updateDescription(){
      var temp = [];
      for ( let item of this.data.step.services ) {
        var serviceCandidate = this.data.services.find( x => x.id === item);
        if (temp.findIndex( x => x.description === serviceCandidate.description ) > -1){ 
          temp.find(x => x.description === serviceCandidate.description ).title.push( serviceCandidate.title );
        } else {
          temp.push({ description: serviceCandidate.description, title: [ serviceCandidate.title ] });
        }
      }
      this.data.step.description = temp.map( x => x.title.join(", ") + "\n" + x.description ).join("\n\n");
    },
    saveData(){
      this.saveHttp("quotes/step/save", this.data.step).then((result) => {
        if (result !== null) this.closeForm();
      })
    },
    deleteData(){
      this.deleteHttp("quotes/step/delete", { id: this.data.step.id, quotes_id: this.data.step.quotes_id }).then((result) => {
        if (result !== null) this.closeForm();
      })
    },
    closeForm(){
      this.dataMandays = [];
      this.show = false;
      this.data = null;
      this.$emit('update');
    },
  },
}
</script>