<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" size="xl" :title="'Domanda - ' + data.question.id" scrollable @hidden="closeForm()" @close="closeForm()">
      <b-card no-body>
        <b-tabs card>
          <b-tab title="Domanda" active>
            <b-row>
              <b-col lg="8">
                <b-form-group label="Paragrafo">
                  <b-form-select v-model="data.question.auditsquestionsparagraphs_id" :options="data.paragraphs" value-field="id" text-field="val"/>
                </b-form-group>
              </b-col>
              <b-col lg="4">
                <b-form-group label="Ordine">
                  <b-form-input v-model="data.question.orderquestion"/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-form-group label="Domanda">
              <b-input-group v-for="(item, index) in data.question.languages" :key="index" size="sm" class="mb-1" :prepend="item.lang">
                <b-form-input v-model="item.question"/>
              </b-input-group>
            </b-form-group>
            <b-form-group label="Punti">
              <multiselect v-model="standardspointsSupport" :options="data.standardspoints" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="data.question.standardspoints = updateCombo(standardspointsSupport)"/>
            </b-form-group>
            <b-form-group label="Esclusioni">
              <multiselect v-model="standardsexclusionsSupport" :options="data.standardsexclusions.filter( z => data.standardspoints.filter(x => data.question.standardspoints.includes(x.id)).map(y => y.standards_id).includes(z.standards_id) )" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="data.question.standardsexclusions = updateCombo(standardsexclusionsSupport)"/>
            </b-form-group>
            <b-form-group label="Stati (applicabili)">
              <multiselect v-model="statesSupport" :options="data.states" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="data.question.states = updateCombo(statesSupport)"/>
            </b-form-group>
            <b-row>
              <b-col lg="2">
                <b-form-group label="Non applicabile">
                  <b-form-checkbox switch v-model="data.question.notapplicable">È possibile che non l'utente non la compili</b-form-checkbox>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Multisite">
                  <b-form-checkbox switch v-model="data.question.multisite">Multi-sito</b-form-checkbox>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Evidenza">
                  <b-form-checkbox switch v-model="data.question.isevidence">Evidenza</b-form-checkbox>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Multinace">
                  <b-form-checkbox switch v-model="data.question.multinace">NACE</b-form-checkbox>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Cantiere">
                  <b-form-checkbox switch v-model="data.question.isyard">Cantiere</b-form-checkbox>
                </b-form-group>
              </b-col>
            </b-row>
          </b-tab>
          <b-tab title="Sottodomande">
            <b-btn variant="primary" size="sm" class="mb-2" @click="newsubquestion">Nuovo</b-btn>
            <b-table responsive :sticky-header="(winHeight - 390) + 'px'" :items="data.question.subquestions" :fields="fieldsSubQuestions">
              <template v-slot:cell(ordersubquestion)="row">
                <b-form-input size="sm" type="number" min="1" v-model="row.item.ordersubquestion"/>
              </template>
              <template v-slot:cell(auditssubquestionsformattypes_id)="row">
                <b-form-select size="sm" v-model="row.item.auditssubquestionsformattypes_id" :options="data.subquestionsformattypes" value-field="id" text-field="val"/>
              </template>
              <template v-slot:cell(colwidth)="row">
                <b-form-input size="sm" type="number" min="1" v-model="row.item.colwidth"/>
              </template>
              <template v-slot:cell(isdocument)="row">
                <b-form-checkbox switch v-model="row.item.isdocument"/>
              </template>
              <template v-slot:cell(hideshow)="row">
                <b-form-checkbox switch v-model="row.item.hideshow"/>
              </template>
              <template v-slot:cell(needtranslate)="row">
                <b-form-checkbox switch v-model="row.item.needtranslate"/>
              </template>
              <template v-slot:cell(languages)="row">
                <b-row>
                  <b-col v-for="(item, index) in row.item.languages" :key="index">
                    <b-input-group size="sm" :prepend="item.lang" class="mb-1">
                      <b-form-input v-model="item.question"/>
                    </b-input-group>
                  </b-col>
                </b-row>
              </template>
              <template v-slot:cell(delete)="row">
                <b-btn variant="danger" size="sm" @click="data.question.subquestions.splice(row.index, 1)">Elimina</b-btn>
              </template>
            </b-table>
          </b-tab>
          <b-tab title="Risposte preimpostate" :disabled="data.question.subquestions.findIndex(x =>  x.auditssubquestionsformattypes_id === 5) === -1">
            <b-form-group v-for="(item, index) in data.question.subquestions.filter(x =>  x.auditssubquestionsformattypes_id === 5 && x.id > 0)" :key="index" :label="'Risposte ' + item.languages.find(x => x.id === 3).question">
              <b-btn variant="primary" size="sm" @click="newAnswers(item.id)" class="mb-2">Nuovo</b-btn>
              <b-table responsive :items="data.question.answers.filter(x => x.auditssubquestions_id === item.id)" :fields="fieldsAnswers">
                <template v-slot:cell(languages)="row">
                  <b-row>
                    <b-col v-for="(item, index) in row.item.languages" :key="index">
                      <b-input-group size="sm" :prepend="item.lang">
                        <b-form-input v-model="item.answer"/>
                      </b-input-group>
                    </b-col>
                  </b-row>
                </template>
                <template v-slot:cell(id)="row">
                  <b-btn variant="danger" size="sm" @click="data.question.answers.splice(data.question.answers.findIndex(x => x.id === row.value), 1)">Elimina</b-btn>
                </template>
              </b-table>
            </b-form-group>
          </b-tab>
          <b-tab title="Report" v-if="data.question.reports.length > 0">
            <b-row>
              <b-col lg="8">
                <b-card no-body>
                  <b-tabs card v-model="report_id">
                    <b-tab v-for="(item, index) of data.question.reports" :key="index" :title="item.standard + ' ' + item.point + ' ' + item.lang">
                      <b-form-textarea v-model="item.reportjinja" rows="20"/>
                    </b-tab>
                  </b-tabs>
                </b-card>
              </b-col>
              <b-col lg="4">
                <b>Esempio testo</b>
                <div v-if="data.question.notapplicable === false && data.question.multisite === false && data.question.multinace === false && data.question.isyard === false">
                  testo apertura ...
                    <span v-for="(item2, index2) of data.question.subquestions.filter(x => x.hideshow === false)" :key="index2">
                      testo ... <span v-pre>{{</span> ev_{{ data.question.reports[report_id].languages_id }}_{{ item2.id }}<span v-pre> }}</span>
                    </span>
                    testo chiusura ... 
                </div>
                <div v-if="data.question.notapplicable === true && data.question.multisite === false && data.question.multinace === false && data.question.isyard === false">
                  <span v-pre>{%</span> if notapplicable_{{ data.question.id }} == True <span v-pre>%}</span>
                    <b>Mettere Frase Non Applicabile</b>
                    <span v-pre>{%</span> else <span v-pre>%}</span>
                    testo apertura ...
                    <span v-for="(item2, index2) of data.question.subquestions.filter(x => x.hideshow === false)" :key="index2">
                      testo ... <span v-pre>{{</span> ev_{{ data.question.reports[report_id].languages_id }}_{{ item2.id }}<span v-pre> }}</span>
                    </span>
                    testo chiusura ... 
                    <span v-pre>{%</span> endif <span v-pre>%}</span>
                </div>
                <div v-if="data.question.notapplicable === false && data.question.multisite === true && data.question.multinace === false && data.question.isyard === false">
                  testo apertura ...
                  <span v-pre>{% for site in sites %}</span>
                    sito/site: <span v-pre>{{ site.site }}</span>
                    testo apertura ...
                    <span v-for="(item2, index2) of data.question.subquestions.filter(x => x.hideshow === false)" :key="index2">
                      testo ... <span v-pre>{{</span> site.ev_{{ data.question.reports[report_id].languages_id }}_{{ item2.id }}<span v-pre> }}</span>
                    </span>
                    testo chiusura ...
                  <span v-pre>{% endfor %}</span>
                  testo chiusura ... 
                </div>
                <div v-if="data.question.notapplicable === true && data.question.multisite === true && data.question.multinace === false && data.question.isyard === false">
                  testo apertura ...
                  <span v-pre>{% for site in sites %}</span>
                    sito/site: <span v-pre>{{ site.site }}</span>
                    <span v-pre>{%</span> if site.notapplicable_{{ data.question.id }} == True <span v-pre>%}</span>
                    <b>Mettere Frase Non Applicabile</b>
                    <span v-pre>{%</span> else <span v-pre>%}</span>
                    testo apertura ...
                    <span v-for="(item2, index2) of data.question.subquestions.filter(x => x.hideshow === false)" :key="index2">
                      testo ... <span v-pre>{{</span> site.ev_{{ data.question.reports[report_id].languages_id }}_{{ item2.id }}<span v-pre> }}</span>
                    </span>
                    testo chiusura ...
                    <span v-pre>{%</span> endif <span v-pre>%}</span>
                  <span v-pre>{% endfor %}</span>
                  testo chiusura ... 
                </div>
                <hr>
                <div v-for="(item, index) of data.question.subquestions.filter(x => x.hideshow === false)" :key="index">
                    {{ item.id }}) {{ item.languages.filter(x => x.question != null).map(x => x.question).join("/") }}
                </div>
              </b-col>
            </b-row>
          </b-tab>
        </b-tabs>
      </b-card>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="success" class="mr-2" @click="saveData">Salva</b-btn>
          <b-btn variant="warning" class="mr-2" v-show="data.question.id > 0" @click="duplicateData">Duplica</b-btn>
          <b-btn variant="danger" v-show="data.question.id > 0" @click="deleteData">Elimina</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "question",
  data() {
    return {
      show: false,
      data: null,
      statesSupport: [],
      standardspointsSupport: [],
      standardsexclusionsSupport: [],
      fieldsSubQuestions: [{
        key: "id",
        label: "Id",
        sortable: true,
        thStyle: {
            width: '5%'
        },
      },{
        key: 'ordersubquestion',
        label: "Ordine",
        sortable: true,
        thStyle: {
            width: '5%'
        },
      },{
        key: 'auditssubquestionsformattypes_id',
        label: 'Formato',
        thStyle: {
            width: '10%'
        },
      },{
        key: 'colwidth',
        label: 'Larghezza',
        thStyle: {
            width: '5%'
        },
      },{
        key: 'isdocument',
        label: 'Documento',
        thStyle: {
            width: '5%'
        },
      },{
        key: 'hideshow',
        label: 'Nascondi',
        thStyle: {
            width: '5%'
        },
      },{
        key: 'needtranslate',
        label: 'Traduci',
        thStyle: {
            width: '5%'
        },
      },{
        key: 'languages',
        label: 'Domanda',
        thStyle: {
            width: '55%'
        },
      },{
        key: "delete",
        label: "",
        thStyle: {
            width: '5%'
        },
      }],
      fieldsAnswers: [{
        key: 'answerkey',
        label: 'Numero',
      },{
        key: "languages",
        label: "Risposta",
      },{
        key: "id",
        label: "",
      }],
      report_id: 0
    }
  },
  computed: {
    winHeight (){
      return this.$store.getters.getWinHeight;
    }
  },
  methods: {
    loadData(id){
      this.openHttp("standards/question", { id: id } ).then((result) => {
        this.report_id = 0;
        this.statesSupport = this.convertCombo(result.states, result.question.states);
        this.standardspointsSupport = this.convertCombo(result.standardspoints, result.question.standardspoints);
        this.standardsexclusionsSupport = this.convertCombo(result.standardsexclusions, result.question.standardsexclusions);
        this.data = result;
        this.show = true;
      })
    },
    newsubquestion(){
      let ordersubquestion = 1;
      for (let item of this.data.question.subquestions ) if ( item.ordersubquestion >= ordersubquestion ) ordersubquestion = (item.ordersubquestion*1) + 1;
      this.data.question.subquestions.push({
        id: 0,
        ordersubquestion: ordersubquestion, 
        auditssubquestionsformattypes_id: 1,
        colwidth: 4,
        rowheight: 1,
        hideshow: false,
        isdocument: false,
        neednace: false,
        needsite: false,
        needyard: false,
        needtranslate: true,
        languages: this.data.languages.map( function(value) {
          return {
            id: value.id,
            lang: value.lang,
            question: null,
          }
        })
      });
    },
    newAnswers(subquestionsId){
      let maxkey = 1;
      let newId = -1;
      for ( let item of this.data.question.answers.filter(x => x.auditssubquestions_id === subquestionsId ) ){
        if (item.answerkey >= maxkey) maxkey = item.answerkey + 1;
        if (item.id < newId) newId = item.id - 1;
      }
      this.data.question.answers.push({
        id: newId,
        answerkey: maxkey,
        auditssubquestions_id: subquestionsId,
        languages: this.data.languages.map( function(value) {
          return {
            id: value.id,
            lang: value.lang,
            answer: null,
          }
        })
      });
    },
    duplicateData(){
      this.data.question.id = 0;
      this.data.question.orderquestion = this.data.question.orderquestion + 1;
      for (let item of this.data.question.subquestions){
        item.id = 0
      }
    },
    saveData(){
      this.saveHttp("standards/question/save", this.data.question).then((result) => {
        if ( result !== null ) this.closeForm();
      })
    },
    deleteData(){
      this.deleteHttp("standards/question/delete", { id: this.data.question.id }).then((result) => {
        if ( result !== null ) this.closeForm();
      })
    },
    closeForm(){
      this.show = false;
      this.data = null;
      this.$emit("update");
    },
  },
}
</script>