<template>
    <div>
        <company ref="company" @update="loadData"/>
        <b-modal v-model="showFilter" title="Filtra">
            <b-form-group label="Telefono?">
                <b-form-select v-model="tempFilter.phone" :options="comboWithWithout" value-field="id" text-field="val"/>
            </b-form-group>
            <b-form-group label="Mail?">
                <b-form-select v-model="tempFilter.mail" :options="comboWithWithout" value-field="id" text-field="val"/>
            </b-form-group>
            <b-form-group label="Colore">
                <b-form-select v-model="tempFilter.colors_id" :options="addNullCombo(filtersMenu.colors)" value-field="id" text-field="val"/>
            </b-form-group>
            <b-form-group label="Tipologia">
                <multiselect v-model="tempFilter.companiestypesSupport" :options="filtersMenu.companiestypes" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="tempFilter.companiestypes = updateCombo(tempFilter.companiestypesSupport)"/>
            </b-form-group>
            <b-form-group label="Nace">
                <multiselect v-model="tempFilter.nacesSupport" :options="filtersMenu.naces" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="tempFilter.naces = updateCombo(tempFilter.nacesSupport)"/>
            </b-form-group>
            <b-form-group label="Tags">
                <multiselect v-model="tempFilter.tagsSupport" :options="filtersMenu.tags" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="tempFilter.tags = updateCombo(tempFilter.tagsSupport)"/>
            </b-form-group>
            <b-form-group label="Segnalatore">
                <b-form-select v-model="tempFilter.suppliers_id" :options="addNullCombo(filtersMenu.suppliers)" value-field="id" text-field="val"/>
            </b-form-group>
            <template v-slot:modal-footer>
                <div class="w-100">
                    <b-btn variant="primary" class="mr-2" @click="filterSet">Filtra</b-btn>
                    <b-btn variant="warning" @click="filterReset">Reset</b-btn>
                </div>
            </template>
        </b-modal>
        <b-btn class="mr-2" variant="primary" @click="loadData">Carica</b-btn>
        <b-btn class="mr-2" variant="secondary" @click="filterOpen">Filtra</b-btn>
        <b-btn class="mr-2" variant="light" @click="filterOpen">Totale: {{ total }}</b-btn>
        <b-btn variant="info" v-b-modal.modal-key>Legenda</b-btn>
        <b-modal id="modal-key" title="Legenda" size="lg" hide-footer>
            <b-row>
                <b-col>
                    <b-table-simple responsive>
                        <b-thead>
                            <b-tr>
                                <b-th>Simbolo</b-th>
                                <b-th>Categoria</b-th>
                            </b-tr>
                        </b-thead>
                        <b-tbody>
                            <b-tr>
                                <b-td>
                                    <b-img :src="require('@/assets/1_1.png')" fluid alt="Responsive image"></b-img>
                                </b-td>
                                <b-td>Cliente - Perso, Cliente - Non interessato, Cliente - Attivo, Cliente - Potenziale, Clienti che hanno protestato</b-td>
                            </b-tr>
                            <b-tr>
                                <b-td>
                                    <b-img :src="require('@/assets/2_1.png')" fluid alt="Responsive image"></b-img>
                                </b-td>
                                <b-td>Collaboratore - Attivo, Collaboratore - Non Attivo, Ditta interna</b-td>
                            </b-tr>
                            <b-tr>
                                <b-td>
                                    <b-img :src="require('@/assets/3_1.png')" fluid alt="Responsive image"></b-img>
                                </b-td>
                                <b-td>Cons. cont POTENZIALE, Cons. cont EX O CHIUSO, Cons. cont NON INTERESSATO, Consulente Bulgaro, Consulente - Non remunerato, Consulente - Remunerato</b-td>
                            </b-tr>
                            <b-tr>
                                <b-td>
                                    <b-img :src="require('@/assets/4_1.png')" fluid alt="Responsive image"></b-img>
                                </b-td>
                                <b-td>Fornitore - Non attivo, Fornitore - Attivo</b-td>
                            </b-tr>
                            <b-tr>
                                <b-td>
                                    <b-img :src="require('@/assets/5_1.png')" fluid alt="Responsive image"></b-img>
                                </b-td>
                                <b-td>Concorrenti, TUV ITALIA - non contattare 2, Consulente - NON Contattare</b-td>
                            </b-tr>
                            <b-tr>
                                <b-td>
                                    <b-img :src="require('@/assets/6_1.png')" fluid alt="Responsive image"></b-img>
                                </b-td>
                                <b-td>Consulenti RAQ</b-td>
                            </b-tr>
                            <b-tr>
                                <b-td>
                                    <b-img :src="require('@/assets/7_1.png')" fluid alt="Responsive image"></b-img>
                                </b-td>
                                <b-td>Commercialista o altra tipologia di consulente</b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                </b-col>
                <b-col>
                    <b-table no-border-collapse responsive :items="colors" :fields="colorsFields"/>
                </b-col>
            </b-row>
        </b-modal>
        <div class="mt-3">
            <gmap-map ref="mymap" :center="location" :zoom="zoom" style="width: 100%" v-bind:style="{ height: (winHeight - 160) + 'px' }" @bounds_changed="bounds_changed_map($event)">
                <gmap-marker v-for="(item, key) in coordinates" :key="key" :position="getPosition(item)" :clickable="true" @click="loadItem(item.id)" @mouseover="showByIndex = key;" @mouseout="showByIndex = null" :icon="require('@/assets/' + ( item.id === lastId ? '0' : item.icon ) + '.png')">
                    <gmap-info-window :opened="showByIndex === key">
                        {{item.company}}<br>{{item.companiestype}}
                    </gmap-info-window>
                </gmap-marker>
            </gmap-map>
        </div>
    </div>
</template>

<script>
export default {
    name: "companiesmaps",
    data() {
        return {
            showByIndex: null,
            showFilter: false,
            lastId: null,
            location: {
                lat: 45.6512,
                lng: 9.5512,
            },
            zoom: 14,
            comboWithWithout: [{
                id: 0,
                val: "Con e senza"
            },{
                id: 1,
                val: "SOLO Con"
            },{
                id: 2,
                val: "SOLO senza"
            }],
            colors: [],
            colorsFields: [{
                key: "val",
                label: "Colore",
                sortable: true,
            }],
            search: {
                filters: {
                    lat_p1: 45.6247947627811,
                    lng_p1: 9.484037486267072,
                    lat_p2: 45.67759279420247,
                    lng_p2: 9.618362513732892,
                    phone: 0,
                    mail: 0,
                    companiestypes: [],
                    companiestypesSupport: [],
                    naces: [],
                    nacesSupport: [],
                    tags: [],
                    tagsSupport: [],
                    colors_id: null,
                },
            },
            tempFilter: {
                lat_p1: 45.6247947627811,
                lng_p1: 9.484037486267072,
                lat_p2: 45.67759279420247,
                lng_p2: 9.618362513732892,
                phone: 0,
                mail: 0,
                companiestypes: [],
                companiestypesSupport: [],
                suppliers_id: null,
                naces: [],
                nacesSupport: [],
                tags: [],
                tagsSupport: [],
                colors_id: null,
            },
            filtersMenu: {
                companiestypes:[],
                suppliers:[],
                naces:[],
                tags:[],
                colors: [],
            },
            markers: [],
            total: 0,
            coordinates: [],
            infoPosition: null,
            infoContent: {
                company: null,
                companiestype: null,
            },
            infoOpened: true,
            infoCurrentKey: null,
            infoOptions: {
                pixelOffset: {
                    width: 0,
                    height: -35
                }
            },
        }
    },
    computed: {
        winHeight(){
            return this.$store.getters.getWinHeight;
        },
    },
    created(){
        this.loadData();
    },
    methods: {        
        bounds_changed_map(event){
            if (event !== undefined){
                this.search.filters.lat_p2 = event.Ab.j;
                this.search.filters.lng_p2 = event.Ua.j;
                this.search.filters.lat_p1 = event.Ab.h;
                this.search.filters.lng_p1 = event.Ua.h;
            }
        },
        getPosition(marker) {
            return {
                lat: parseFloat(marker.lat),
                lng: parseFloat(marker.lng)
            }
        },
        toggleInfo(marker, key) {
            this.infoPosition = this.getPosition(marker);
            this.infoContent = marker;
            if (this.infoCurrentKey == key) {
                this.infoOpened = !this.infoOpened;
            } else {
                this.infoOpened = true;
                this.infoCurrentKey = key;
            }
        },
        loadData(){
            this.openHttp("companiesmaps", this.search).then((result) => {
                this.total = result.total;
                this.colors = result.colors;
                this.coordinates = result.data;
            })
        },
        filterOpen(){
            this.openHttp("companies/filters", null).then((result) => {
                result.companiestypesSupport = this.convertCombo(result.companiestypes, this.tempFilter.companiestypes);
                result.nacesSupport = this.convertCombo(result.naces, this.tempFilter.naces);
                result.tagsSupport = this.convertCombo(result.tags, this.tempFilter.tags);
                this.filtersMenu = result;
                this.showFilter = true;
            })
        },
        filterSet(){
            this.showFilter = false;
            this.search.filters = this.tempFilter;
            this.loadData();
        },
        filterReset(){
            this.tempFilter = {
                lat_p1: this.tempFilter.lat_p1,
                lng_p1: this.tempFilter.lng_p1,
                lat_p2: this.tempFilter.lat_p2,
                lng_p2: this.tempFilter.lng_p2,
                phone: 0,
                mail: 0,
                companiestypes: [],
                companiestypesSupport: [],
                suppliers_id: null,
                naces: [],
                nacesSupport: [],
                tags: [],
                tagsSupport: [],                
                colors_id: null,
            };
            this.filterSet();
        },
        loadItem(id){
            this.lastId = id;
            this.$refs.company.loadData(id);
        },
    }
}
</script>

<style scoped>
    #myMap {
        width: 100%;
   }
</style>