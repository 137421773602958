<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" :title="translate('worker')" @hidden="closeForm()" @close="closeForm()">
      <b-form-group :label="translate('title')">
        <b-form-select v-model="data.worker.titles_id" :options="data.titles" value-field="id" text-field="val"/>
      </b-form-group>
      <b-form-group :label="translate('firstname')">
        <b-form-input v-model="data.worker.firstname"/>
      </b-form-group>
      <b-form-group :label="translate('lastname')">
        <b-form-input v-model="data.worker.lastname"/>
      </b-form-group>
      <b-form-group :label="translate('status')">
        <b-button-group>
          <b-btn :variant="data.worker.active === true ? 'success' : ''" @click="data.worker.active = true">{{ translate('active') }}</b-btn>
          <b-btn :variant="data.worker.active === true ? '' : 'danger'" @click="data.worker.active = false">{{ translate('not active') }}</b-btn>
        </b-button-group>
      </b-form-group>
      <b-form-group :label="translate('type')">
        <b-button-group>
          <b-btn :variant="data.worker.internal === true ? 'primary' : ''" @click="data.worker.internal = true">{{ translate('internal') }}</b-btn>
          <b-btn :variant="data.worker.internal === true ? '' : 'primary'" @click="data.worker.internal = false">{{ translate('external') }}</b-btn>
        </b-button-group>
      </b-form-group>
      <b-form-group :label="translate('job')">
        <b-form-input v-model="data.worker.job"/>
      </b-form-group>
      <b-form-group :label="translate('areas')">
        <multiselect v-model="rolestypesSupport" :options="data.workersrolestypes" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="data.worker.rolestypes = updateCombo(rolestypesSupport)"/>
      </b-form-group>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="success" class="mr-2" @click="saveData">{{ translate('save') }}</b-btn>
        </div>
      </template>           
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "checklistworker",
  data() {
    return {
      rolestypesSupport: [],
      show: false,
      data: null
    }
  },
  methods: {
    loadData( id, companiesId, auditsId ){
      this.openHttp("checklists/worker", { id: id, companies_id: companiesId, audits_id: auditsId }).then((result) => {
        this.rolestypesSupport = this.convertCombo(result.workersrolestypes, result.worker.rolestypes);        
        this.data = result;
        this.show = true;
      })
    },
    saveData(){
      this.saveHttp("checklists/worker/save", this.data.worker).then((result) => {
        if ( result !== null ) this.closeForm();
      })
    },
    closeForm(){
      this.show = false;
      this.data = null;
      this.$emit('update');
    },
  },
}
</script>