<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" size="xl" :title="'Legge - ' + data.law.id" scrollable @hidden="closeForm()" @close="closeForm()">
      <b-row>
        <b-col lg="7">
          <b-form-group label="Legge">
            <b-form-input v-model="data.law.law"/>
          </b-form-group>
        </b-col>
        <b-col lg="2">
          <b-form-group label="Da">
            <b-form-input type="date" v-model="data.law.datefrom"/>
          </b-form-group>
        </b-col>
        <b-col lg="2">
          <b-form-group label="A">
            <b-form-input type="date" v-model="data.law.dateto"/>
          </b-form-group>
        </b-col>
        <b-col lg="1">
          <b-form-group label="Report?">
            <b-form-select v-model="data.law.inreport" :options="inreportCombo" value-field="id" text-field="val"/>
          </b-form-group>
        </b-col>
      </b-row>
      <b-form-group label="Note">
        <b-form-textarea v-model="data.law.note" rows="5"/>
      </b-form-group>
      <b-form-group label="Links per controllo">
        <b-btn variant="primary" size="sm" @click="newlink">Nuovo</b-btn>
        <b-input-group v-for="(item, index) in data.law.links" :key="index" class="mt-2">
          <b-form-input v-model="item.link"/>
          <b-input-group-append>
            <b-btn variant="primary" :href="item.link" target="_blank">Apri</b-btn>                
            <b-btn variant="danger" @click="data.law.links.splice(index, 1)">Elimina</b-btn>                
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
      <b-form-group label="Norme">
        <multiselect v-model="standardsSupport" :options="data.standards" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="data.law.standards = updateCombo(standardsSupport)"/>
      </b-form-group>
      <b-form-group label="Stati">
        <multiselect v-model="statesSupport" :options="data.states" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="data.law.states = updateCombo(statesSupport)"/>
      </b-form-group>
      <b-form-group label="IAF">
        <multiselect v-model="nacesiafcodesSupport" :options="data.nacesiafcodes" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="data.law.nacesiafcodes = updateCombo(nacesiafcodesSupport)"/>
      </b-form-group>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="success" class="mr-2" @click="saveData">Salva</b-btn>
          <b-btn variant="warning" v-if="data.law.id > 0" class="mr-2" @click="data.law.id = 0">Duplica</b-btn>
          <b-btn variant="danger" v-if="data.law.id > 0" @click="deleteData">Elimina</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "law",
  data() {
    return {
      inreportCombo: [{
        id: true,
        val: "Si",
      },{
        id: false,
        val: "No",
      },],
      show: false,
      data: null,
      standardsSupport: [],
      statesSupport: [],
      nacesclustersSupport: []
    }
  },
  methods: {
    loadData(id){
      this.openHttp("standards/law", { id: id }).then((result) => {
        this.standardsSupport = this.convertCombo(result.standards, result.law.standards);
        this.statesSupport = this.convertCombo(result.states, result.law.states);
        this.nacesiafcodesSupport = this.convertCombo(result.nacesiafcodes, result.law.nacesiafcodes);
        this.data = result;
        this.show = true;
      })
    },
    newlink(){
      this.data.law.links.push( { link: "" } );
    },
    saveData(){
      this.saveHttp("standards/law/save", this.data.law).then((result) => {
        if ( result !== null ) this.closeForm();
      })
    },
    deleteData(){
      this.deleteHttp("standards/law/delete", { id: this.data.law.id }).then((result) => {
        if ( result !== null ) this.closeForm();
      })
    },
    closeForm(){
      this.show = false;
      this.data = null;
      this.$emit("update");
    },
  },
}
</script>
