<template>
  <div>
    <b-modal v-if="data != null" v-model="show" size="lg" title="Monitoring" scrollable hide-footer>
      <b-table-simple responsive>
        <b-thead head-variant="light">
          <b-tr>
            <b-th style="width: 25%">Parameter</b-th>
            <b-th>Value</b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr>
            <b-td>Audit</b-td>
            <b-td>{{ data.monitoring.auditid }}</b-td>
          </b-tr>
          <b-tr>
            <b-td>Monitored</b-td>
            <b-td>{{ data.monitoring.monitored }}</b-td>
          </b-tr>
          <b-tr>
            <b-td>Monitoring</b-td>
            <b-td>{{ data.monitoring.monitoring }}</b-td>
          </b-tr>
          <b-tr>
            <b-td>Standards</b-td>
            <b-td>{{ data.monitoring.standards }}</b-td>
          </b-tr>
          <b-tr>
            <b-td>Role</b-td>
            <b-td>{{ data.monitoring.role }}</b-td>
          </b-tr>
          <b-tr>
            <b-td>Dates</b-td>
            <b-td>{{ data.monitoring.dates }}</b-td>
          </b-tr>
          <b-tr>
            <b-td>Evaluations</b-td>
            <b-td>
              <b-table responsive :fields="fields" :items="data.evaluations"/>
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </b-modal>
  </div>
</template>

<script>

export default {
  name: "auditsmonitoringanswers",
  data() {
    return {
      show: false,
      data: null,
      fields: [{
        key: "question",
        label: "Question"
      },{
        key: "evaluation",
        label: "Evaluation",
        formatter: value => {
          return value === null ? 'Not evaluated' : ( value === true ? "Positive" : "Negative" );
        }
      },{
        key: "note",
        label: "Note"
      }]
    }
  },
  methods: {
    loadData(id){
      this.openHttp("monitorings/monitoring", { id: id }).then((result) => {
        this.data = result;
        this.show = true;
      })
    }
  }
}
</script>
