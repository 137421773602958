<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" :title="'Nota - ' +  data.history.id" @hidden="closeForm()" @close="closeForm()">
      <b-form-group label="Data">
        <b-form-input type="date" v-model="data.history.date"/>
      </b-form-group>
      <b-form-group label="Collaboratore">
        <b-form-select v-model="data.history.users_id" :options="data.users" value-field="id" text-field="val"/>
      </b-form-group>
      <b-form-group label="Evento">
        <b-form-select v-model="data.history.quotesstatus_id" :options="data.status" value-field="id" text-field="val"/>
      </b-form-group>
      <b-form-group label="Nota">
        <b-form-textarea v-model="data.history.note" rows="5"/>
      </b-form-group>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="success" class="mr-2" @click="saveData">Salva</b-btn>
          <b-btn variant="danger" v-show="data.history.id > 0" @click="deleteData">Elimina</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "quotehistory",
  data() {
    return {
      show: true,
      data: null,
    }
  },
  methods: {
    loadData(quotesId, id){
      this.openHttp("quotes/history", { quotes_id: quotesId, id: id }).then((result) => {
        this.data = result;
        this.show = true;
      })
    },
    saveData(){
      this.saveHttp("quotes/history/save", this.data.history).then((result) => {
        if ( result !== null ) this.closeForm();
      })
    },
    deleteData(){
      this.deleteHttp("quotes/history/delete", { id: this.data.history.id }).then((result) => {
        if ( result !== null ) this.closeForm();
      })
    },
    closeForm(){
      this.show = false;
      this.data = null;
      this.$emit('update');
    },
  },
}
</script>