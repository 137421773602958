<template>
    <div>
        <b-modal v-model="show" v-if="data !== null" title="Marketing" size="lg" @hidden="closeForm()" @close="closeForm()">
            <company ref="company" @update="loadData"/>
            <b-row>
                <b-col lg="6">
                    <b-form-group>
                        <company ref="company" @update="loadData(data.contract.id)"/>
                        <label>Azienda</label> - <b-link @click="$refs.company.loadData( data.marketing.companies_id )"> Apri</b-link>
                        <b-form-select v-model="data.marketing.companies_id" :options="data.companies" value-field="id" text-field="val"/>
                    </b-form-group>
                </b-col>
                <b-col lg="6">
                    <b-form-group label="Riferimento">
                        <b-form-select v-model="data.marketing.workers_id" :options="data.workers" value-field="id" text-field="val"/>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col lg="4">
                    <b-form-group label="Data Scadenza">
                        <b-form-input type="date" v-model="data.marketing.date_expiry"/>
                    </b-form-group>
                </b-col>
                <b-col lg="4">
                    <b-form-group label="Progressivo">
                        <b-form-input type="number" v-model="data.marketing.progressive"/>
                    </b-form-group>
                </b-col>
                <b-col lg="4">
                    <b-form-group label="Data Rivalutazione">
                        <b-form-input type="date" v-model="data.marketing.date_revaluation"/>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-form-group label="Note">
                <b-form-textarea v-model="data.marketing.note" rows="4"/>
            </b-form-group>
            <template v-slot:modal-footer>
                <div class="w-100">
                    <b-btn variant="success" class="mr-2" @click="saveData">Salva</b-btn>
                    <b-btn variant="danger" @click="deleteData">Elimina</b-btn>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
export default {
    name: "companymarketing",
    data() {
        return {
            show: false,
            data: null
        }
    },
    methods: {
        loadData(id){
            this.openHttp("companiesmarketing_marketing", { id: id }).then((result) => {
                this.data = result;
                this.show = true;
            })
        },
        saveData(){
            this.saveHttp("companiesmarketing_marketing_save", this.data.marketing, "Azienda salvata!" ).then((result) => {
                if ( result.result == true ) this.closeForm();
            })
        },
        deleteData(){
            this.deleteHttp("companiesmarketing_marketing_delete", { id: this.data.marketing.id }, "Azienda eliminata!" ).then((result) => {
                if ( result.result == true ) this.closeForm();
            })
        },
        closeForm(){
            this.show = false;
            this.data = null;
            this.$emit('update');
        }
    }
}
</script>