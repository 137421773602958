<template>
    <div>
        <b-modal v-if="data !== null" v-model="show" size="lg" :title="'Accreditamento - ' + data.accredation.id" scrollable @hidden="closeForm" @close="closeForm">
            <b-row>
                <b-col lg="4">
                    <b-form-group label="Ente">
                        <b-form-select v-model="data.accredation.certificationbodies_id" :options="addNullCombo(data.certificationbodies, 'Nesuno')" value-field="id" text-field="val" :disabled="data.accredation.id > 0"/>
                    </b-form-group>
                </b-col>
                <b-col lg="4">
                    <b-form-group label="Norma">
                        <b-form-select v-model="data.accredation.standards_id" :options="data.standards" value-field="id" text-field="val" :disabled="data.accredation.id > 0"/>
                    </b-form-group>
                </b-col>
                <b-col lg="4">
                    <b-form-group label="Accreditamento">
                        <b-form-select v-model="data.accredation.accreditationbodies_id" :options="data.accreditationbodies" value-field="id" text-field="val"/>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-form-group label="NACE">
                <b-table responsive :fields="fields" :items="data.naces.filter(x => x.naces.filter(y => y.standards.includes(data.accredation.standards_id) ).length > 0)" :sticky-header="(winHeight - 450) + 'px'">
                    <template v-slot:cell(naces)="row">
                        <b-btn v-for="(item3, index3) of row.item.naces.filter(x => x.standards.includes(data.accredation.standards_id))" :key="index3" class="mr-1" size="sm" :variant="data.accredation.naces.includes(item3.id) ? 'success' : 'secondary'" @click="editNace(item3.id)">
                        {{ item3.val }}
                        </b-btn>
                    </template>
                </b-table>
            </b-form-group>
            <template v-slot:modal-footer>
                <div class="w-100">
                    <b-btn variant="success" class="mr-2" @click="saveData">Salva</b-btn>
                    <b-btn variant="warning" v-show="data.accredation.id> 0" class="mr-2" @click="data.accredation.id = 0">Duplicate</b-btn>
                    <b-btn variant="danger" v-show="data.accredation.id> 0" @click="deleteData">Elimina</b-btn>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
export default {
    name: "accreditation",
    data() {
        return {
            show: false,
            data: null,
            fields: [{
                key: 'ea',
                label: 'EA',
                sortable: true,
            },{
                key: 'nace',
                label: 'NACE',
                sortable: true,
            },{
                key: 'naces',
                label: 'Codici'
            }],
        }
    },
    computed: {
        winHeight (){
            return this.$store.getters.getWinHeight;
        },
    },
    methods: {
        editNace(naces_id){
            if ( this.data.accredation.naces.includes(naces_id) ){
                this.data.accredation.naces.splice(this.data.accredation.naces.findIndex(x => x === naces_id), 1);
            } else {
                this.data.accredation.naces.push(naces_id);
            }
        },
        loadData( id ){
            this.openHttp("standards/accreditation", { id: id }).then((result) => {
                console.log(result);
                this.data = result;
                this.show = true;
            })
        },
        saveData(){
            this.saveHttp("standards/accreditation/save", this.data.accredation).then((result) => {
                if ( result !== null ) this.closeForm();
            })
        },
        deleteData(){
            this.deleteHttp("standards/accreditation/delete", { id: this.data.accredation.id }).then((result) => {
                if ( result !== null ) this.closeForm();
            })
        },
        closeForm(){
            this.show = false;
            this.data = null;
            this.$emit('update');
        },
    },
}
</script>