<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" size="xl" :title="'Template - ' + data.template.id" @hidden="closeForm()" @close="closeForm()">
      <b-card no-body>
        <b-tabs card>
          <b-tab title="Mail" active>
            <b-form-group label="Template">
              <b-form-input v-model="data.template.marketingtemplate"/>
            </b-form-group>
            <b-form-group label="Note">
              <b-form-textarea v-model="data.template.note"/>
            </b-form-group>
            <b-form-group label="Oggetto">
              <b-form-input v-model="data.template.subject"/>
            </b-form-group>
            <b-form-group label="Html">
              <customeditor v-model="data.template.html" :height="winHeight - 780" :showToolbar="true"/>
            </b-form-group>
            <b-form-group label="Allegati">
              <marketingtemplateattachment ref="attachment" @update="loadData(data.template.id)"/>
              <b-button size="sm" variant="success" @click="$refs.attachment.loadData(data.template.id, 0)" v-show="data.template.id > 0">Nuovo</b-button>
              <b-dropdown right :text="item.filename" size="sm" v-for="(item, index) of data.attachments" :key="index" class="ml-1">
                <b-dropdown-item @click="$refs.attachment.downloadData(item.id)">Scarica</b-dropdown-item>
                <b-dropdown-item @click="$refs.attachment.loadData(data.template.id, item.id)">Modifica</b-dropdown-item>
              </b-dropdown>
            </b-form-group>
          </b-tab>
          <b-tab title="Scadenze">
            <b-row>
              <b-col lg="2">
                <b-form-group label="Utente scadenza">
                  <b-form-select v-model="data.template.deadlineaddusers_id" :options="data.users" value-field="id" text-field="val"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Giorni da aggiungere scadenza">
                  <b-form-input v-model="data.template.deadlineadddays"/>
                </b-form-group>
              </b-col>
              <b-col lg="8">
                <b-form-group label="Testo scadenza">
                  <b-form-textarea v-model="data.template.deadlineaddnote" rows="4"/>
                </b-form-group>
              </b-col>
            </b-row>
          </b-tab>
        </b-tabs>
      </b-card>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="success" class="mr-2" @click="saveData()">Salva</b-btn>
          <b-btn variant="warning" class="mr-2" v-show="data.template.id > 0" @click="data.template.id = 0">Duplica</b-btn>
          <b-btn variant="danger" class="mr-2" v-show="data.template.id > 0" @click="deleteData()">Elimina</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import customeditor from '../customeditor.vue';
import marketingtemplateattachment from './marketingtemplateattachment.vue';

export default {
  name: "marketingtemplate",
  components: {
    customeditor, marketingtemplateattachment
  },
  data() {
    return {
      show: false,
      data: null
    }
  },
  computed: {
    winHeight (){
      return this.$store.getters.getWinHeight;
    },
  },
  methods: {
    loadData(id){
      this.openHttp("marketing/template", { id: id }).then((result) => {
        this.data = result;
        this.show = true;
      })
    },
    saveData(){
      this.saveHttp("marketing/template/save", this.data.template).then((result) => {
        if ( result !== null ) this.closeForm();
      })
    },
    deleteData(){
      this.deleteHttp("marketing/template/delete", { id: this.data.template.id }).then((result) => {
        if ( result !== null ) this.closeForm();
      })
    },
    closeForm(){
      this.show = false;
      this.data = null;
      this.$emit('update');
    }
  }
}
</script>