<template>
    <div>
        <b-modal v-if="data !== null" v-model="show" :title="'Regione - ' + data.region.id" @hidden="closeForm()" @close="closeForm()">
            <b-form-group label="Regione">
                <b-form-input v-model="data.region.region"/>
            </b-form-group>
            <b-form-group label="Regione">
                <b-form-select v-model="data.region.states_id" :options="data.states" value-field="id" text-field="val"/>
            </b-form-group>
            <template v-slot:modal-footer>
                <div class="w-100">
                    <b-btn variant="success" class="mr-2" @click="saveData">Salva</b-btn>
                    <b-btn variant="danger" v-show="data.region.id > 0" @click="deleteData">Elimina</b-btn>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>

export default {
    name: "region",
    data() {
        return {
            show: false,
            data: null,
        }
    },
    methods: {
        loadData(id){
            this.openHttp("companiessettings/region", { id: id }).then((result) => {
                console.log(result);
                this.data = result;
                this.show = true;
            })
        },
        saveData(){
            this.saveHttp("companiessettings/region/save", this.data.region).then((result) => {
                if ( result !== null ) this.closeForm();
            })
        },
        deleteData(){
            this.deleteHttp("companiessettings/region/delete", { id: this.data.region.id }).then((result) => {
                if ( result !== null ) this.closeForm();
            })
        },
        closeForm(){
            this.show = false;
            this.data = null;
            this.$emit('update');
        }
    },
}
</script>